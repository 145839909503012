import store from "@store";

export default {
  path: "/account",
  component: () =>
    import(/* webpackChunkName: "authenticated" */ "@skeleton/loggedIn/index"),
  beforeEnter: (to, from, next) => {
    store.commit("ui/routing", true);
    next();
  },
  meta: {
    requiresAuth: true,
    title: "My account",
    breadcrumb: {
      label: "My account"
    }
  },
  children: [
    {
      path: "",
      name: "account",
      redirect: "profile",
      components: {
        default: () => import("@views/account")
      },
      children: [
        {
          path: "profile",
          name: "profile",
          component: () => import("@views/account/profile"),
          meta: {
            title: "My profile",
            breadcrumb: {
              label: "profile"
            }
          }
        },
        {
          path: "billing",
          name: "billing",
          component: () => import("@views/account/billing"),
          meta: {
            title: "My billing",
            breadcrumb: {
              label: "billing"
            }
          }
        },
        {
          path: "activity",
          name: "activity",
          component: () => import("@views/account/activity"),
          meta: {
            title: "My activity",
            breadcrumb: {
              label: "activity"
            }
          }
        },
        {
          path: "*",
          component: () => import("@views/error"),
          props: { code: 404 }
        }
      ]
    }
  ]
};
