<template>
  <router-link
    :to="route"
    class="list-card has-border-bottom"
    @click.native="$emit('click')"
  >
    <avatar v-if="avatar" :name="avatar.name" :avatar="avatar.url" :size="35" />
    <div>
      <span class="fxd-corner-angle" :style="{ borderBottomColor: color }" />
      <p class="has-overflow-ellipsis-right">
        <slot name="title">
          <strong>{{ title }}</strong>
        </slot>
      </p>
      <slot name="subtitle-dark">
        <p v-if="subtitleDark" class="is-size-7 has-overflow-ellipsis-right">
          {{ subtitleDark }}
        </p>
      </slot>
      <slot name="subtitle-grey">
        <p
          v-if="subtitleGrey"
          class="is-size-7 has-text-grey has-overflow-ellipsis-right"
        >
          {{ subtitleGrey }}
        </p>
      </slot>

      <status-label v-if="label" :status="label" class="has-margin-top-50" />
      <p v-if="timestamp" class="date-created">
        <small>{{ $moment(timestamp).from() }}</small>
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ListCard",
  props: {
    avatar: {
      type: Object,
      required: false,
      default: () => {
        return null;
      }
    },
    title: {
      type: String,
      required: true
    },
    titleIcon: {
      type: Object,
      required: false,
      default: null
    },
    subtitleGreySecond: {
      type: String,
      required: false,
      default: null
    },
    subtitleDark: {
      type: String,
      required: false,
      default: null
    },
    subtitleGrey: {
      type: String,
      required: false,
      default: null
    },
    color: {
      type: String,
      required: false,
      default: ""
    },
    label: {
      type: [String, Number],
      required: true
    },
    timestamp: {
      type: Date,
      required: true
    },
    route: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.list-card {
  display: block;
  padding: 1em 1.5em;
  color: $text;
  background: $white;
  position: relative;
  overflow-x: hidden;
  overflow-y: visible;
  &:hover,
  &.focused {
    cursor: pointer;
    background: $light;
  }
  &.router-link-active {
    box-shadow: inset 6px 0 0 0 $primary;
    background: $light;
  }
  .fxd-corner-angle {
    display: block;
    position: absolute;
    font-size: 12px;
    right: -1em;
    top: 0;
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-bottom: 1em solid transparent;
    width: 0;
    height: 0;
    transform: rotate(45deg);
    transform-origin: top;
  }
  picture {
    position: absolute;
    margin: 0.25rem 0;
  }
  picture + div {
    margin-left: 3rem;
  }
  p.date-created {
    display: block;
    position: absolute;
    bottom: 1em;
    right: 1.5em;
    color: $grey-light;
  }
}
</style>
