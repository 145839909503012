<template>
  <div
    class="vessel"
    :class="{
      'is-active': isActive,
      'is-before-active': isBeforeActive,
      'is-after-active': isAfterActive,
      'is-clickable': ['a', 'router-link'].includes(type)
    }"
  >
    <transition name="slow-fade">
      <div v-if="isOpen" class="vessel-body">
        <slot name="body" />
      </div>
    </transition>

    <component
      :is="type"
      :to="$attrs.to"
      class="vessel-header"
      @click="handleClick"
    >
      <div class="vessel-media">
        <slot name="media" />
      </div>
      <div class="vessel-top-left-label">
        <slot name="topLeft" />
      </div>
      <div class="vessel-middle-left-label">
        <slot name="middleLeft" />
      </div>
      <div class="vessel-bottom-left-label">
        <slot name="bottomLeft" />
      </div>
      <div class="vessel-top-right-label">
        <slot name="topRight" />
      </div>
      <div class="vessel-middle-right-label">
        <slot name="middleRight" />
      </div>
      <div class="vessel-bottom-right-label">
        <slot name="bottomRight" />
      </div>
      <div class="vessel-action">
        <slot name="action" :isOpen="isOpen" />
      </div>
    </component>

    <transition name="slow-fade">
      <div v-if="isOpen" v-show="hasFooter" class="vessel-footer">
        <slot name="footer" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Vessel",
  props: {
    type: {
      type: String,
      required: false,
      default: "a"
    }
  },
  computed: {
    inFleet() {
      return this.$_.has(this.$parent, "vessels");
    },
    index() {
      return this.$_.findIndex(this.$parent.vessels, this);
    },
    activeIndex() {
      return this.$_.get(this.$parent, "activeIndex");
    },
    isActive() {
      return this.$_.get(this.$parent, "activeVessel") === this;
    },
    isBeforeActive() {
      return this.index === this.activeIndex - 1;
    },
    isAfterActive() {
      return this.index === this.activeIndex + 1;
    },
    isOpen() {
      return this.hasBody ? (this.inFleet ? this.isActive : true) : false;
    },
    hasBody() {
      return !!this.$slots.body;
    },
    hasFooter() {
      return !!this.$slots.footer;
    }
  },
  created() {
    if (this.inFleet) {
      this.$parent.vessels.push(this);
    }
  },
  methods: {
    handleClick() {
      if (this.inFleet) {
        this.$parent.vesselClick(this);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.vessel {
  display: grid;
  grid-template-areas: "header" "body" "footer";
  border-style: solid;
  border-color: $border;
  border-width: 1px;
  border-radius: $radius-large;
  background-color: $white;
  transition-property: background-color;
  transition-duration: 0.16s;
  &.is-clickable:not(.is-active):hover {
    background-color: $light;
  }

  & + .vessel,
  &::v-deep + .vessels {
    margin-top: 0.75rem;
  }

  .vessel-body {
    grid-area: body;
    background: $white;
    padding: 1.25rem;
    border-radius: 0 0 $radius-large $radius-large;
  }

  .vessel-body ~ .vessel-header {
    background-color: $light;
    border-bottom: 1px solid $border;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .vessel-header {
    grid-area: header;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr auto;
    grid-column-gap: 1rem;
    align-items: center;
    border-radius: $radius-large;
    padding: 1rem 1.25rem;
    color: $dark;
    .vessel-media {
      grid-column: -4;
      &:not(:empty) {
        width: 3rem;
        height: 3rem;
      }
    }
    .vessel-media,
    .vessel-action {
      &:empty {
        grid-row: 1;
        grid-column: 1;
      }
    }
    @include mobile {
      .vessel-top-left-label,
      .vessel-middle-left-label,
      .vessel-bottom-left-label,
      .vessel-top-right-label,
      .vessel-middle-right-label,
      .vessel-bottom-right-label {
        grid-column: 1;
        &:empty {
          grid-row: 1;
        }
      }
      .vessel-media,
      .vessel-action {
        grid-row: 1 / span 6;
      }
      .vessel-action {
        grid-column: 2;
      }
    }
    @include tablet {
      .vessel-top-left-label {
        grid-row: 1;
        grid-column: 1;
      }
      .vessel-middle-left-label {
        grid-row: 1 / span 2;
        grid-column: 1;
      }
      .vessel-bottom-left-label {
        grid-row: 2;
        grid-column: 1;
      }
      .vessel-top-right-label {
        grid-row: 1;
        grid-column: 2;
        text-align: right;
      }
      .vessel-middle-right-label {
        grid-row: 1 / span 2;
        grid-column: 2;
        text-align: right;
      }
      .vessel-bottom-right-label {
        grid-row: 2;
        grid-column: 2;
        text-align: right;
      }
      .vessel-media,
      .vessel-action {
        grid-row: 1 / span 2;
      }
      .vessel-action {
        grid-column: 3;
      }
    }
  }

  .vessel-footer {
    grid-area: footer;
    padding: 1rem 1.25rem;
    background: $white;
    border-radius: 0 0 $radius-large $radius-large;
    border-top: 1px solid $border;
  }
}

.slow-fade-enter-active {
  transition: all 0.4s ease-out;
  transition-delay: 0.2s;
}
.slow-fade-leave-active {
  transition: all 0 ease-out;
}
.slow-fade-enter,
.slow-fade-leave-to {
  opacity: 0;
}
</style>
