import Vue from "vue";
import VueRouter from "vue-router";
import store from "@store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",

  routes: [
    require("./auth").login,
    require("./auth").register,
    require("./auth").logout,
    require("./auth").resetpassword,
    require("./auth").onboarding,
    require("./magic").default,
    require("./print").default,
    require("./sites").default,
    require("./tasks").default,
    require("./account").default,
    require("./affiliate").default,
    require("./admin").default,
    require("./reseller").default,
    require("./support").default,
    require("./reports").default,
    {
      path: "/",
      redirect: {
        name: "sites"
      }
    },
    {
      path: "*",
      component: () =>
        import(
          /* webpackChunkName: "authenticated" */
          "@skeleton/loggedIn/index"
        ),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "",
          name: "error",
          props: true,
          components: {
            default: () => import("@views/error")
          }
        }
      ]
    }
  ],
  scrollBehavior(_to, _from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (!store.getters["auth/isAuthenticated"]()) {
      return next({ name: "login", query: { redirect: to.fullPath } });
    }
  }
  if (to.matched.some(route => route.meta.blockForWhitelabel)) {
    if (store.getters["ui/isWhitelabeled"]()) {
      return next({ path: "/" });
    }
  }
  if (to.matched.some(route => route.meta.agentOnly)) {
    if (!store.getters["user/isAgent"]()) {
      return next({ path: "/" });
    }
  }
  if (to.matched.some(route => route.meta.adminOnly)) {
    if (!store.getters["user/isAdmin"]()) {
      if (store.getters["user/isAgent"]()) return next({ path: "/admin" });
      return next({ path: "/" });
    }
  }
  next();
});

router.afterEach(() => {
  if (store.state.ui.routing) store.commit("ui/routing", false);
  if (store.state.ui.menu) store.commit("ui/menu", false);
});

export default router;
