<template>
  <div class="has-padding-150">
    <div class="has-text-grey has-text-centered">
      <b-icon
        v-if="icon.length"
        :icon="icon"
        :pack="iconPack"
        size="is-large"
        class="has-text-grey-light has-margin-bottom-75"
      />

      <p>
        <strong>{{ title }}</strong>
      </p>

      <slot name="message">
        <p v-if="message.length">{{ message }}</p>
      </slot>

      <a
        v-if="cta.label"
        ref="noResultsCta"
        :class="cta.class"
        href="javascript:void(0);"
        role="button"
        class="button is-rounded is-outlined has-margin-top-100"
        @click="$emit('click')"
      >
        <b-icon v-if="cta.icon" :icon="cta.icon" size="is-small" />
        <span>{{ cta.label }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoResults",
  props: {
    icon: {
      default: "",
      type: String,
      required: false
    },
    iconPack: {
      default: "fas",
      type: String
    },
    title: {
      default: "",
      type: String,
      required: true
    },
    message: {
      default: "",
      type: String,
      required: false
    },
    cta: {
      type: Object,
      default: () => {
        return {
          label: "",
          class: "",
          autofocus: false
        };
      }
    }
  },
  mounted() {
    const autofocus = this.$_.get(this.cta, "autofocus", false);
    if (this.cta.label && autofocus) {
      this.$refs.noResultsCta.focus();
    }
  }
};
</script>
