<template>
  <div class="note">
    <b-message type="is-warning">
      <p class="note-message has-break-word" style="white-space: pre-line; ">
        <status-label v-if="isRestricted" status="staff-only" />
        <urlify :text="message" tag="span" />
      </p>

      <p v-if="authorDetails.length" class="is-size-7 has-margin-top-25">
        <strong>{{ authorDetails }}</strong>
      </p>

      <p v-if="isAuthor" class="is-size-7 has-margin-top-25">
        <a class="has-text-dark" @click="$emit('update')">Edit</a> |
        <a class="has-text-danger" @click="$emit('delete')">Delete</a>
      </p>
    </b-message>
  </div>
</template>

<script>
export default {
  name: "Note",
  props: {
    timestamp: {
      required: false,
      type: Object,
      default: null
    },
    message: {
      required: true,
      type: String
    },
    authorName: {
      required: false,
      type: String,
      default: ""
    },
    isAuthor: {
      required: false,
      type: Boolean,
      default: false
    },
    isRestricted: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    datePosted() {
      return this.timestamp ? this.timestamp.toDate() : new Date();
    },
    authorDetails() {
      let details = "";
      if (this.authorName) details += this.authorName;
      if (this.authorName && this.timestamp) details += ", ";
      if (this.timestamp) details += this.$moment(this.datePosted).from();
      return details;
    }
  }
};
</script>

<style lang="scss" scoped>
.note + .note {
  margin-top: 1em;
}
.note-message {
  white-space: pre-line;
}
</style>
