const defaultState = () => {
  return {
    cards: {},
    credits: {},
    contracts: {},
    currency: "gbp"
  };
};

const state = defaultState();

const getters = Object.assign(
  require("./cards").getters,
  require("./credit").getters,
  require("./service").getters,
  require("./contracts").getters
);

const actions = Object.assign(
  require("./cards").actions,
  require("./credit").actions,
  require("./service").actions,
  require("./contracts").actions,
  {
    reset: ({ commit, dispatch }) => {
      return new Promise(resolve => {
        dispatch(
          "unobserveAll",
          { containing: "billing" },
          { root: true }
        ).then(() => {
          commit("resetState");
          resolve();
        });
      });
    }
  }
);

const mutations = Object.assign(
  require("./cards").mutations,
  require("./credit").mutations,
  require("./service").mutations,
  require("./contracts").mutations,
  {
    resetState: state => {
      Object.assign(state, defaultState());
    }
  }
);

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
