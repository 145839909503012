<template>
  <div
    v-show="visible"
    :class="{ 'is-centered': centered }"
    class="custom-loader"
  >
    <span :class="{ 'has-logo': logo }" :style="{ fontSize: `${size}em` }" />
  </div>
</template>

<script>
export default {
  props: {
    size: { default: 3, type: Number },

    logo: { default: false, type: Boolean },

    delay: { default: 0, type: Number },

    centered: { default: false, type: Boolean }
  },

  data() {
    return {
      visible: false
    };
  },

  created() {
    setTimeout(() => {
      this.visible = true;
    }, this.delay);
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";

.custom-loader {
  text-align: center;
  margin: auto;

  &.is-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > span {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin: 1.5rem;

    &.has-logo {
      background-image: url("/assets/images/interface/logos/upmind—logomark+exclusion—colour-on-transparent.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 50%;
    }

    &:after {
      content: "";
      display: block;
      width: 1em;
      height: 1em;
      margin: 1px;
      border-radius: 50%;
      border: 5px solid rgba(0, 0, 0, 0.15);
      border-top-color: $primary;
      border-top-color: var(--primary-color);
      animation: loading 0.666s linear infinite;
      box-sizing: border-box;
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
