export const MIME_ICON = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "/assets/images/interface/file-upload_word.png",
  "application/msword": "/assets/images/interface/file-upload_word.png",
  "application/pdf": "/assets/images/interface/file-upload_pdf.png",
  "text/plain": "/assets/images/interface/file-upload_txt.png",
  "application/x-zip-compressed":
    "/assets/images/interface/file-upload_archive.png",
  "application/zip": "/assets/images/interface/file-upload_archive.png",
  default: "/assets/images/interface/file-upload_default.png"
};

export function getIcon(type) {
  return MIME_ICON[type] ? MIME_ICON[type] : MIME_ICON["default"];
}
