export default {
  path: "/support",
  component: () =>
    import(/* webpackChunkName: "authenticated" */ "@skeleton/loggedIn/index"),
  meta: {
    requiresAuth: true,
    breadcrumb: {
      label: "Support"
    }
  },

  children: [
    {
      path: "",
      name: "support",
      redirect: "tickets",
      components: {
        default: { template: "<p>Support</p>" }
      },
      children: [
        {
          path: "*",
          component: () => import("@views/error"),
          props: { code: 404 }
        }
      ]
    }
  ]
};
