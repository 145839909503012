import Vue from "vue";
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable
} from "firebase/functions";

const useEmulator = JSON.parse(process.env.VUE_APP_FIREBASE_USE_EMULATOR);

const $firebase = initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID
});
const $auth = getAuth($firebase);
const $firestore = getFirestore($firebase);

if (useEmulator) {
  connectFirestoreEmulator($firestore, "localhost", 8082);
  connectAuthEmulator($auth, "http://localhost:9099", {
    disableWarnings: true
  });
}

const $functions = (region = process.env.VUE_APP_FUNCTIONS_REGION) => {
  const functions = getFunctions($firebase, region);
  if (useEmulator) connectFunctionsEmulator(functions, "localhost", 5001);
  return {
    httpsCallable: (name, options) => httpsCallable(functions, name, options)
  };
};

const $storage = (bucket = process.env.VUE_APP_FIREBASE_STORAGE_BUCKET) => {
  const storage = getStorage($firebase, bucket);
  if (useEmulator) connectStorageEmulator(storage, "localhost", 9199);
  return storage;
};

Vue.prototype.$firebase = $firebase;
Vue.prototype.$firestore = $firestore;
Vue.prototype.$functions = $functions;
Vue.prototype.$storage = $storage;

export { $auth, $firebase, $firestore, $functions, $storage };
