import Vue from "vue";
import { $functions, $firestore, $storage } from "@/firebase";
import { Toast } from "buefy/dist/components/toast";
import { ModalProgrammatic } from "buefy/dist/components/modal";
import _ from "lodash";
import {
  doc,
  collection,
  writeBatch,
  updateDoc,
  serverTimestamp,
  setDoc,
  deleteDoc,
  getDoc
} from "firebase/firestore";
import { ref as storageRef } from "firebase/storage";

const defaultState = () => {
  return {
    statuses: [
      { value: "1-05-new", label: "New" },
      { value: "1-04-malware-and-security", label: "Malware & Security" },
      { value: "1-04-client-replied", label: "Client Replied" },
      { value: "1-03-website-tweaks", label: "Website Tweaks" },
      { value: "1-03-in-progress", label: "In Progress" },
      { value: "1-02-on-hold", label: "On Hold" },
      { value: "1-01-waiting-for-reply", label: "Waiting for Reply" },
      { value: "1-00-scheduled", label: "Scheduled" },
      { value: "1-00-queued", label: "Queued" },
      { value: "0-01-complete", label: "Complete" },
      { value: "0-01-cancelled", label: "Cancelled" },
      { value: "0-00-deleted", label: "Deleted" }
    ],
    observed: {}
  };
};

const state = defaultState();

const getters = {
  task: state => taskId => {
    return state.observed[taskId] || null;
  },
  "task/status": () => status => {
    const regex = /^([0-9]{1})-([0-9]{1,3})-([a-z-]+)/i;
    return _.get(regex.exec(status), 3, status);
  }
};

const actions = {
  getTaskType: (context, { taskTypeRef }) => {
    return new Promise((resolve, reject) => {
      getDoc(taskTypeRef)
        .then(taskType => {
          if (!taskType.exists()) return reject();
          resolve(taskType);
        })
        .catch(error => reject(error));
    });
  },

  observeTask: ({ commit, dispatch }, { taskId }) => {
    const taskRef = doc($firestore, `tasks/${taskId}`);
    return dispatch(
      "observe",
      {
        key: `tasks/${taskId}`,
        ref: taskRef,
        onSnapshot: task => {
          if (!task.exists()) {
            dispatch("binTask", { taskId: taskId });
          }
          commit("setTask", { taskId: task.id, taskData: task.data() });
        },
        onError: () => {
          dispatch("binTask", { taskId: taskId });
        }
      },
      { root: true }
    );
  },

  binTask: ({ commit, dispatch }, { taskId }) => {
    return dispatch(`unobserve`, `tasks/${taskId}`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit(`unsetTask`, { taskId });
      }
    });
  },

  addTask: (
    context,
    { siteId, taskTypePath, details, subject, dateScheduled }
  ) => {
    return new Promise((resolve, reject) => {
      const addTask = $functions().httpsCallable("onCall-task-add");

      addTask({
        siteId,
        taskTypePath,
        details,
        subject,
        dateScheduled
      })
        .then(result => {
          resolve(result.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  openAddTaskModal: (context, props) => {
    return new Promise(resolve => {
      const modal = ModalProgrammatic.open({
        parent: window.$rootVue,
        component: () => import("@shared/tasks/_newTaskModal"),
        hasModalCard: true,
        width: 640,
        props,
        canCancel: [],
        events: {
          close: () => {
            return resolve();
          },
          success: () => {
            modal.close();
            return resolve();
          }
        }
      });
    });
  },

  updateTask: (context, { taskId, status }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-task-status")({ taskId, status })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },

  closeTask: (context, { taskId, status, rating = 0.0, feedback = "" }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-task-close")({
          taskId,
          status,
          rating,
          feedback
        })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },

  reopenTask: (context, { taskId }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-task-reopen")({ taskId })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },

  updateType: (context, { taskId, taskTypePath }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-task-updateTaskType")({ taskId, taskTypePath })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },

  updateSchedule: (context, { taskId, dateScheduled }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-task-updateSchedule")({ taskId, dateScheduled })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },

  updatePriority: (context, { taskId, priority }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-task-updatePriority")({ taskId, priority })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },

  takeTask: () => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-task-assign")()
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },

  updateSubject: (context, { taskId, subject }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-task-updateSubject")({ taskId, subject })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },

  muteUnmuteAlerts: (context, { taskId, muteAlerts, userId = null }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-task-muteUnmuteAlerts")({
          taskId,
          muteAlerts,
          userId
        })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },

  addPost: async ({ dispatch, rootGetters }, { taskId, payload }) => {
    try {
      const postRef = doc(collection($firestore, `tasks/${taskId}/posts`));
      const batch = writeBatch($firestore);
      batch.set(postRef, payload);
      if (rootGetters["user/isAgent"]()) {
        await dispatch(
          "agents/updateStat",
          { batch, statName: "replies" },
          { root: true }
        );
      }
      const snapshot = await batch.commit();
      return Promise.resolve(snapshot);
    } catch (error) {
      Toast.open({
        message: error.message,
        position: "is-bottom",
        type: "is-danger"
      });
      return Promise.reject(error);
    }
  },

  updatePost: (context, { taskId, postId, payload }) => {
    const postRef = doc($firestore, `tasks/${taskId}/posts/${postId}`);
    const batch = writeBatch($firestore);

    return new Promise((resolve, reject) => {
      batch.update(postRef, payload);

      batch
        .commit()
        .then(async () => {
          const post = await getDoc(postRef);
          return resolve(post);
        })
        .catch(error => {
          Toast.open({
            message: error.message,
            position: "is-bottom",
            type: "is-danger"
          });
          return reject(error);
        });
    });
  },
  deletePost: (context, { taskId, postId }) => {
    const postRef = doc($firestore, `tasks/${taskId}/posts/${postId}`);
    return updateDoc(postRef, {
      isHidden: true,
      isDeleted: true,
      dateDeleted: serverTimestamp()
    });
  },

  addFilePost: (
    { dispatch, rootState },
    { taskId, payload, file, progressCb, resolveOnUploadComplete }
  ) => {
    return new Promise((resolve, reject) => {
      const postRef = doc(collection($firestore, `tasks/${taskId}/posts`));
      const fileRef = storageRef(
        $storage(),
        `files/${postRef.id}_${file.name}`
      );

      // Append file info to the post
      payload = _.merge(payload, {
        isFile: true,
        file: {
          bucket: fileRef.bucket,
          path: fileRef.fullPath,
          contentType: file.type,
          size: file.size
        }
      });

      const promises = [
        setDoc(postRef, payload), // Add post
        dispatch(
          "file/uploadFile", // Upload file
          {
            fileRef,
            file,
            progressCb,
            metadata: {
              contentType: file.type,
              customMetadata: {
                authorId: rootState.auth.currentUser.uid,
                taskId: taskId,
                postId: postRef.id
              }
            }
          },
          { root: true }
        )
      ];

      const onError = error => {
        deleteDoc(postRef); // Delete post on failure
        Toast.open({
          message:
            error.code === "storage/canceled"
              ? `File upload canceled`
              : `Your message could not be saved`,
          position: "is-bottom",
          type: error.code === "storage/canceled" ? "is-warning" : "is-danger"
        });
      };

      Promise.all(promises)
        // eslint-disable-next-line no-unused-vars
        .then(async ([postResult, fileResult]) => {
          fileResult.uploadTask.on(
            "state_changed",
            // eslint-disable-next-line no-unused-vars
            snapshot => ({}),
            error => onError(error),
            () => {
              if (resolveOnUploadComplete) resolve({ postRef, fileResult });
            }
          );
          if (!resolveOnUploadComplete) resolve({ postRef, fileResult });
        })
        .catch(error => {
          reject(error);
          onError(error);
        });
    });
  },
  uploadFiles: (
    { dispatch },
    {
      taskId,
      userId,
      files = [],
      isHidden = false,
      isPrivate = false,
      isInternal = false,
      resolveOnUploadComplete = false
    }
  ) => {
    return Promise.all(
      _.map(files, file => {
        return dispatch("addFilePost", {
          taskId,
          file,
          resolveOnUploadComplete,
          payload: {
            body: "FILE",
            isPending: true,
            isHidden,
            isPrivate,
            isInternal,
            authorId: userId,
            dateCreated: serverTimestamp()
          }
        }).then(async ({ postRef }) => {
          if (isHidden) return;
          return await getDoc(postRef);
        });
      })
    );
  },

  updateAgents: (context, { agentIds, alphaAgentId, taskId }) => {
    return new Promise((resolve, reject) => {
      const updateAgents = $functions().httpsCallable(
        "onCall-task-updateAgents"
      );

      return updateAgents({
        agentIds,
        alphaAgentId,
        taskId
      })
        .then(result => {
          return resolve(result.data);
        })
        .catch(error => {
          return reject(error);
        });
    });
  },
  updateParticipants: (context, { taskId, users }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-task-updateParticipants")({
          taskId,
          users
        })
        .then(result => {
          return resolve(result.data);
        })
        .catch(error => {
          return reject(error);
        });
    });
  },
  reset: async ({ dispatch, commit }) => {
    try {
      await dispatch("unobserveAll", { containing: "tasks" }, { root: true });
      commit("resetState");
    } catch (error) {
      console.error(error);
    }
  }
};

const mutations = {
  setTask: (state, { taskId, taskData }) => {
    Vue.set(
      state.observed,
      taskId,
      _.mergeWith(
        {},
        state.observed[taskId],
        taskData,
        { _id: taskId },
        (objValue, srcValue) => {
          if (_.isArray(objValue)) {
            return srcValue;
          }
        }
      )
    );
  },
  unsetTask: (state, { taskId }) => {
    if (_.has(state.observed, taskId)) {
      Vue.delete(state.observed, taskId);
    }
  },
  resetState: state => {
    Object.assign(state, defaultState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
