<template>
  <div
    class="static-field"
    :class="{ 'has-action': !!action }"
    @click="onClick"
  >
    <p class="static-field-label">{{ label }}</p>
    <div class="static-field-text">
      <p v-if="value">{{ value }}</p>
      <slot v-else />
    </div>
    <a v-if="action" class="static-field-action">
      <span>{{ action }}</span>
      <b-icon icon="chevron-right" size="is-small" />
    </a>
  </div>
</template>

<script>
export default {
  name: "StaticField",
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false,
      default: null
    },
    action: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    onClick() {
      if (this.action) {
        this.$emit("click");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.static-field {
  padding: 0.75rem 0.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1rem;
  align-items: center;
  transition: padding ease-out 0.2s;
  .static-field-label {
    grid-row: 1;
    grid-column: span 2;
    font-size: 11px;
    text-transform: uppercase;
    color: $grey-light;
  }
  .static-field-text {
    grid-row: 2;
    grid-column: 1;
    overflow: hidden;
    transition: opacity ease-out 0.2s;
    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  a.static-field-action {
    grid-row: 2;
    grid-column: 2;
    color: $text;
    & > :first-child {
      display: none;
      margin-right: 0.15rem;
    }
  }
}
.static-field:hover {
  padding-right: 0;
  a.static-field-action {
    color: $dark;
    :first-child {
      display: inline;
    }
  }
}
.static-field.has-action {
  cursor: pointer;
}
.static-field + .static-field {
  border-top: 1px solid $border;
}
</style>
