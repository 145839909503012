import Vue from "vue";
import { $firestore } from "@/firebase";
import _ from "lodash";
import { doc } from "firebase/firestore";

const getters = {
  credit: state => userId => {
    return state.credits[userId] || {};
  }
};

const actions = {
  observeCredit: ({ dispatch, commit }, { userId }) => {
    const creditRef = doc($firestore, `credits`, userId);
    return dispatch(
      "observe",
      {
        key: `billing/${userId}/credit`,
        ref: creditRef,
        onSnapshot: credit => {
          commit("setCredit", {
            userId,
            creditData: credit.data() || {}
          });
        }
      },
      { root: true }
    );
  },
  unobserveCredit: ({ commit, dispatch }, { userId }) => {
    return dispatch(`unobserve`, `billing/${userId}/credit`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit(`unsetCredit`, { userId });
      }
    });
  }
};

const mutations = {
  setCredit: (state, { userId, creditData }) => {
    creditData = _.isEmpty(creditData)
      ? creditData
      : _.merge({}, creditData, { _id: userId });
    Vue.set(state.credits, userId, creditData);
  },
  unsetCredit: (state, { userId }) => {
    if (state.credits[userId]) {
      Vue.delete(state.credits, userId);
    }
  }
};

export { getters, actions, mutations };
