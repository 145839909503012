import { getCookie, setCookie } from "@services/cookies";

const CW_COOKIE = "cw_conversation";

const state = {
  isLoaded: false,
  isEnabled: false
};

const getters = {
  hasConversation: () => () => {
    return !!getCookie(CW_COOKIE);
  }
};

const actions = {
  load: ({ state, getters, dispatch, commit }) => {
    if (state.isLoaded) return; // Exit if already loaded
    const d = document;
    const w = window;
    // Load script
    function l() {
      const BASE_URL = "https://stablechat.mysecurecloudhost.com";
      let g = d.createElement("script");
      let s = d.getElementsByTagName("script")[0];
      g.src = BASE_URL + "/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g, s);
      g.onload = function() {
        commit("isLoaded", true);
        if (getters["hasConversation"]()) dispatch("open");
      };
    }
    // If document ready, load chat and exit
    if (d.readyState == "complete") return l();
    // Otherwise, attach onload listener
    if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  },
  enable: ({ state, commit, rootGetters }) => {
    return new Promise((resolve, reject) => {
      if (!state.isLoaded) return reject();
      if (state.isEnabled) return resolve();
      // Set callback
      window.addEventListener("chatwoot:ready", () => {
        // Shorten cookie lifespan from 1 year default
        const cookieVal = getCookie(CW_COOKIE);
        setCookie(CW_COOKIE, cookieVal, 3600); // Expires after 1 hour
        // Pass user details
        window.$chatwoot.setUser(rootGetters["auth/userId"](), {
          email: rootGetters["user/email"](),
          name: rootGetters["user/name"](),
          avatar_url: rootGetters["user/avatarUrl"]()
        });
        // Pass account details
        window.$chatwoot.setCustomAttributes({
          userId: rootGetters["auth/userId"]()
        });
        // Set chat label
        window.$chatwoot.setLabel("in-app-enquiry");
        commit("isEnabled", true);
        resolve();
      });
      // Run chatwoot
      window.chatwootSDK.run({
        websiteToken: "PUWJN5gCMKbQCtVp9Ku27qUE",
        baseUrl: "https://stablechat.mysecurecloudhost.com"
      });
    });
  },
  open: async ({ dispatch }) => {
    try {
      await dispatch("enable");
      window.$chatwoot.toggle();
    } catch (error) {
      // Chat not loaded
    }
  }
};

const mutations = {
  isLoaded: (state, value) => {
    state.isLoaded = value;
  },
  isEnabled: (state, value) => {
    state.isEnabled = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
