import Vue from "vue";
import { $firestore } from "@/firebase";
import _ from "lodash";
import { doc } from "firebase/firestore";

const getters = {
  product: state => productId => {
    return state.observed[productId];
  }
};

const actions = {
  observeProduct: ({ commit, dispatch }, { productId }) => {
    const ref = doc($firestore, `products`, productId);
    return dispatch(
      "observe",
      {
        key: `products/${productId}`,
        ref,
        onSnapshot: product => {
          if (product.metadata.fromCache) return;
          if (!product.exists()) {
            dispatch("unobserveProduct", { productId: productId });
            return;
          }
          commit("setProduct", {
            productId: productId,
            productData: product.data()
          });
        },
        onError: () => {
          dispatch("unobserveProduct", { productId: productId });
        }
      },
      { root: true }
    );
  },
  unobserveProduct: ({ commit, dispatch }, { productId }) => {
    return dispatch(`unobserve`, `products/${productId}`, { root: true }).then(
      remove => {
        if (remove) {
          commit("unsetProduct", { productId: productId });
        }
      }
    );
  }
};

const mutations = {
  setProduct: (state, { productId, productData }) => {
    Vue.set(
      state.observed,
      productId,
      _.merge(
        {
          _id: productId
        },
        productData
      )
    );
  },
  unsetProduct: (state, { productId }) => {
    if (state.observed[productId]) {
      Vue.delete(state.observed, productId);
    }
  }
};

export { getters, actions, mutations };
