import Vue from "vue";
import { $firestore, $functions } from "@/firebase";
import _ from "lodash";
import { ConfirmProgrammatic } from "@components/confirm";
import { query, where, collection } from "firebase/firestore";

const getters = {
  siteDelegates: state => ({ siteId }) => {
    return state.delegates.observed[siteId] || null;
  }
};

const actions = {
  observeDelegates: ({ commit, dispatch }, { siteId }) => {
    const delegatesRef = query(
      collection($firestore, `roles`),
      where(`siteId`, `==`, siteId),
      where(`role`, `==`, `delegate`)
    );

    return dispatch(
      "observe",
      {
        key: `sites/${siteId}/delegates`,
        ref: delegatesRef,
        onSnapshot: snapshot => {
          // React to server changes
          _.each(snapshot.docChanges(), change => {
            const delegate = change.doc;
            if (change.type === "removed") {
              commit("unsetDelegate", {
                siteId,
                delegateId: delegate.id
              });
            } else {
              commit("setDelegates", {
                siteId,
                delegates: {
                  [delegate.id]: _.merge({ _id: delegate.id }, delegate.data())
                }
              });
            }
          });
        }
      },
      { root: true }
    );
  },
  unobserveDelegates: ({ commit, dispatch }, { siteId }) => {
    return dispatch(`unobserve`, `sites/${siteId}/delegates`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit("unsetDelegates", { siteId });
      }
    });
  },
  // eslint-disable-next-line no-empty-pattern
  addDelegate: ({}, { siteId, email }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("site-delegate-add")({ siteId, email })
        .then(result => {
          return resolve(result.data);
        })
        .catch(error => {
          return reject(error);
        });
    });
  },
  // eslint-disable-next-line no-empty-pattern
  removeDelegate: ({}, { userId, siteId, email }) => {
    return new Promise((resolve, reject) => {
      const confirm = ConfirmProgrammatic.open({
        props: {
          autoClose: false,
          action: `Remove delegate`,
          message: `Are you sure you want to revoke site access for ${email}?`
        },
        canCancel: ["escape", "outside"],
        events: {
          confirmed: () => {
            $functions()
              .httpsCallable("site-delegate-remove")({ userId, siteId })
              .then(result => {
                resolve(result.data);
              })
              .catch(error => {
                reject(error);
              })
              .finally(() => {
                confirm.close();
              });
          }
        }
      });
    });
  }
};

const mutations = {
  setDelegates: (state, { siteId, delegates }) => {
    Vue.set(
      state.delegates.observed,
      siteId,
      _.merge({}, state.delegates.observed[siteId], delegates)
    );
  },
  unsetDelegate: (state, { siteId, delegateId }) => {
    if (state.delegates.observed[siteId][delegateId]) {
      Vue.delete(state.delegates.observed[siteId], delegateId);
    }
  },
  unsetDelegates: (state, { siteId }) => {
    if (state.delegates.observed[siteId]) {
      Vue.delete(state.delegates.observed, siteId);
    }
  }
};

export { getters, actions, mutations };
