import store from "@store";

export default {
  path: "/affiliate-program",
  component: () =>
    import(/* webpackChunkName: "authenticated" */ "@skeleton/loggedIn/index"),
  beforeEnter: (to, from, next) => {
    store.commit("ui/routing", true);
    if (store.getters["user/isResellerUser"]()) {
      return next({ name: "sites" });
    }
    next();
  },
  meta: {
    requiresAuth: true,
    breadcrumb: {
      label: "Affiliate program"
    }
  },
  children: [
    {
      path: "",
      name: "affiliateProgram",
      component: () => import("@views/affiliate"),
      meta: {
        title: "Affiliate program"
      }
    }
  ]
};
