import { query, where, orderBy } from "firebase/firestore";
export default {
  components: {
    "data-filter": () => import("@components/data-filter")
  },
  data() {
    return {
      table: {
        limit: 10,
        loading: false,
        complete: true,
        sorting: false,
        sort: { field: "dateCreated", direction: "desc" },
        data: [],
        results: [],
        page: 1,
        excluded: [],
        sortable: true
      },
      activeFilters: []
    };
  },
  computed: {
    cursor() {
      if (this.table.sorting || !this.table.results.length) {
        return null;
      }
      return this.$_.last(this.table.results);
    },
    limit() {
      if (this.table.sorting) {
        return this.table.limit * this.table.page;
      }
      return this.table.limit;
    },
    showDataFilter() {
      if (this.activeFilters.length) {
        return true;
      }
      return this.table.data.length > 0;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    formatResults() {
      return this.$_.map(this.table.results, result =>
        this.$_.merge({}, result.data(), { _id: result.id })
      );
    },

    onSort(field, direction) {
      this.table.sorting = true;
      this.table.sort = { field: field, direction: direction };
      this.getData();
    },

    filtersChanged(filters) {
      this.activeFilters = filters;
      this.table.results = [];
      this.getData();
    },

    getData(loadMore = false) {
      this.table.loading = true;
      if (loadMore) {
        this.table.page++;
      }

      this.$store
        .dispatch("pagination/getPaginated", {
          ref: this.getFirestoreRef(),
          limit: this.limit,
          cursor: loadMore ? this.cursor : null
        })
        .then(({ results, complete }) => {
          this.table.excluded.forEach(i =>
            this.$_.remove(results, doc => doc.id === i)
          );
          this.table.results = loadMore
            ? this.table.results.concat(results)
            : results;
          this.table.complete = complete;
        })
        .catch(error => {
          this.$toast.open({
            message: error.message,
            position: "is-bottom",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.table.data = this.formatResults();
          this.table.sorting = false;
          this.table.loading = false;
          this.afterDataLoad();
        });
    },
    buildFirestoreRef(ref) {
      let skipSort = false,
        sortKey = "";
      this.activeFilters.forEach(filter => {
        if (filter.type === "date") {
          const date = this.$moment(filter.value);

          switch (filter.operator) {
            case "equals":
              ref = query(
                ref,
                where(filter.key, ">=", date.startOf("day").toDate()),
                where(filter.key, "<=", date.endOf("day").toDate())
              );
              break;
            case "after":
              ref = query(ref, where(filter.key, ">=", date.toDate()));
              break;
            case "before":
              ref = query(ref, where(filter.key, "<=", date.toDate()));
              break;
          }

          ref = query(ref, orderBy(filter.key, this.table.sort.direction));
          sortKey = filter.key;
          return;
        }
        if (this.table.sort.field === filter.key) {
          skipSort = true;
        }

        ref = query(ref, where(filter.key, "==", filter.value));
      });

      if (
        this.table.sortable &&
        !skipSort &&
        sortKey !== this.table.sort.field
      ) {
        ref = query(
          ref,
          orderBy(this.table.sort.field, this.table.sort.direction)
        );
      }

      return ref;
    },
    afterDataLoad() {}
  }
};
