import Vue from "vue";
import { $firestore } from "@/firebase";
import { Toast } from "buefy/dist/components/toast";
import _ from "lodash";
import {
  collection,
  doc,
  query,
  orderBy,
  addDoc,
  deleteDoc,
  setDoc
} from "firebase/firestore";

const getters = {
  "user/notes": state => userId => {
    return _.get(state, `notes.observed.${userId}`, {});
  }
};
const actions = {
  observeNotes: ({ commit, dispatch }, { userId }) => {
    const notesRef = query(
      collection($firestore, `users/${userId}/notes`),
      orderBy(`dateCreated`, `asc`)
    );

    return dispatch(
      "observe",
      {
        key: `users/${userId}/notes`,
        ref: notesRef,
        onSnapshot: snapshot => {
          _.each(snapshot.docChanges(), change => {
            const note = change.doc;
            if (change.type === "removed") {
              commit("unsetNote", {
                userId,
                noteId: note.id
              });
            } else {
              commit("setNotes", {
                userId: userId,
                notes: {
                  [note.id]: _.merge({ _id: note.id }, note.data())
                }
              });
            }
          });
        }
      },
      { root: true }
    );
  },
  unobserveNotes: ({ commit, dispatch }, { userId }) => {
    return dispatch(`unobserve`, `users/${userId}/notes`, { root: true }).then(
      unsubscribed => {
        if (unsubscribed) {
          commit("unsetNotes", { userId });
        }
      }
    );
  },
  // eslint-disable-next-line no-empty-pattern
  addNote: ({}, { userId, payload }) => {
    return new Promise((resolve, reject) => {
      const notesRef = collection($firestore, `users/${userId}/notes`);
      addDoc(notesRef, payload)
        .then(snapshot => {
          Toast.open({
            message: "Note added",
            position: "is-bottom",
            queue: false
          });
          resolve(snapshot);
        })
        .catch(error => {
          Toast.open({
            message: "Error adding note",
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-empty-pattern
  updateNote: ({}, { userId, noteId, payload }) => {
    return new Promise((resolve, reject) => {
      const noteRef = doc($firestore, `users/${userId}/notes`, noteId);
      setDoc(noteRef, payload, { merge: true })
        .then(snapshot => {
          Toast.open({
            message: "Note updated",
            position: "is-bottom",
            queue: false
          });
          resolve(snapshot);
        })
        .catch(error => {
          Toast.open({
            message: "Error updating note",
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-empty-pattern
  deleteNote: ({}, { userId, noteId }) => {
    return new Promise((resolve, reject) => {
      const noteRef = doc($firestore, `users/${userId}/notes`, noteId);
      deleteDoc(noteRef)
        .then(() => {
          Toast.open({
            message: "Note deleted",
            position: "is-bottom",
            queue: false
          });
          resolve();
        })
        .catch(error => {
          Toast.open({
            message: "Error deleting note", // "There was a problem while deleting your note!",
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
          reject(error);
        });
    });
  }
};
const mutations = {
  setNotes: (state, { userId, notes }) => {
    Vue.set(
      state.notes.observed,
      userId,
      _.merge({}, state.notes.observed[userId], notes)
    );
  },
  unsetNote: (state, { userId, noteId }) => {
    if (state.notes.observed[userId][noteId]) {
      Vue.delete(state.notes.observed[userId], noteId);
    }
  },
  unsetNotes: (state, { userId }) => {
    if (state.notes.observed[userId]) {
      Vue.delete(state.notes.observed, userId);
    }
  }
};

export { getters, actions, mutations };
