import Vue from "vue";
import { $firestore } from "@/firebase";
import _ from "lodash";
import { doc } from "firebase/firestore";

const defaultState = () => {
  return {
    observed: {}
  };
};

const state = defaultState();

const getters = {
  transaction: state => transactionId => {
    return state.observed[transactionId];
  }
};

const actions = {
  observeTransaction: ({ dispatch, commit }, { transactionId }) => {
    const transactionRef = doc($firestore, "transactions", transactionId);
    return dispatch(
      "observe",
      {
        key: `transactions/${transactionId}`,
        ref: transactionRef,
        onSnapshot: transaction => {
          commit("setTransaction", {
            transactionId,
            transactionData: transaction.data() || {}
          });
        }
      },
      { root: true }
    );
  },
  unobserveTransaction: ({ commit, dispatch }, { transactionId }) => {
    return dispatch(`unobserve`, `transactions/${transactionId}`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit(`unsetTransaction`, { transactionId });
      }
    });
  },
  reset: async ({ dispatch, commit }) => {
    try {
      await dispatch(
        "unobserveAll",
        { containing: "transactions" },
        { root: true }
      );
      commit("resetState");
    } catch (error) {
      console.error(error);
    }
  }
};

const mutations = {
  setTransaction: (state, { transactionId, transactionData }) => {
    transactionData = _.isEmpty(transactionData)
      ? transactionData
      : _.merge({}, transactionData, { _id: transactionId });
    Vue.set(state.observed, transactionId, transactionData);
  },
  unsetTransaction: (state, { transactionId }) => {
    if (state.observed[transactionId]) {
      Vue.delete(state.observed, transactionId);
    }
  },
  resetState: state => {
    Object.assign(state, defaultState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
