import Vue from "vue";
import { $functions, $firestore } from "@/firebase";
import _ from "lodash";
import { doc, getDoc } from "firebase/firestore";

const defaultState = () => {
  return {
    observed: {}
  };
};

const state = defaultState();

const getters = {
  promotion: state => promotionId => {
    return state.observed[promotionId];
  }
};

const actions = {
  observePromotion: ({ commit, dispatch }, { promotionId }) => {
    const ref = doc($firestore, `promotions`, promotionId);
    return dispatch(
      "observe",
      {
        key: `promotions/${promotionId}`,
        ref,
        onSnapshot: promotion => {
          if (promotion.metadata.fromCache) return;
          if (!promotion.exists()) {
            dispatch("unobservePromotion", { promotionId: promotionId });
            return;
          }
          commit("setPromotion", {
            promotionId: promotionId,
            promotionData: promotion.data()
          });
        },
        onError: () => {
          dispatch("unobservePromotion", { promotionId: promotionId });
        }
      },
      { root: true }
    );
  },
  unobservePromotion: ({ commit, dispatch }, { promotionId }) => {
    return dispatch(`unobserve`, `promotions/${promotionId}`, {
      root: true
    }).then(remove => {
      if (remove) {
        commit("unsetPromotion", { promotionId: promotionId });
      }
    });
  },
  reset: async ({ dispatch, commit }) => {
    try {
      await dispatch(
        "unobserveAll",
        { containing: "promotions" },
        { root: true }
      );
      commit("resetState");
    } catch (error) {
      console.error(error);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  getDetails: async ({}, { couponId }) => {
    let useLimit, useCount, dateExpires;
    try {
      // Get coupon
      const couponRef = doc($firestore, `coupons/${couponId}`);
      const coupon = await getDoc(couponRef);
      // Check coupon usage & expiry
      useLimit = coupon.data().useLimit;
      useCount = coupon.data().useCount;
      dateExpires = coupon.data().dateExpires;
      if (useLimit && useCount >= useLimit) return "";
      if (dateExpires && dateExpires.toDate() < new Date()) return "";
      // Get promotion
      const promotion = await getDoc(coupon.data().promotionRef);
      // Check promotions usage & expiry
      useLimit = promotion.data().useLimit;
      useCount = promotion.data().useCount;
      dateExpires = promotion.data().dateExpires;
      if (useLimit && useCount >= useLimit) return "";
      if (dateExpires && dateExpires.toDate() < new Date()) return "";
      // Return promotion details
      return promotion.data().description || "";
    } catch (error) {
      // Invalid coupon or no linked promotion
      return "";
    }
  },
  // eslint-disable-next-line no-empty-pattern
  redeemCoupon: ({}, { couponId }) => {
    return $functions().httpsCallable("coupon-redeem")({ couponId });
  }
};

const mutations = {
  setPromotion: (state, { promotionId, promotionData }) => {
    Vue.set(
      state.observed,
      promotionId,
      _.merge(
        {
          _id: promotionId
        },
        promotionData
      )
    );
  },
  unsetPromotion: (state, { promotionId }) => {
    if (state.observed[promotionId]) {
      Vue.delete(state.observed, promotionId);
    }
  },
  resetState: state => {
    Object.assign(state, defaultState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
