<template>
  <div class="selector">
    <div
      v-for="(item, index) in items"
      :key="`item-${index}`"
      :class="type"
      class="selector-item"
      @click="onClick(item)"
    >
      <component
        :is="multiselect ? 'b-checkbox' : 'b-radio'"
        :name="name"
        :native-value="item"
        :type="type"
        :value="value"
        :disabled="item.disabled"
        class="selector-input"
        @click.native.stop.prevent="onClick(item)"
      />
      <p
        class="selector-left-label"
        :class="{ 'has-text-grey': item.disabled }"
      >
        <slot :item="item" name="left-label">
          {{ item.leftLabel }}
        </slot>
      </p>
      <p
        v-if="
          !!$scopedSlots['right-label'] ||
            !!$slots['right-label'] ||
            !!item.rightLabel
        "
        class="selector-right-label"
      >
        <slot :item="item" name="right-label">
          {{ item.rightLabel }}
        </slot>
      </p>
      <p
        v-if="!!$scopedSlots['desc'] || !!$slots['desc'] || !!item.desc"
        class="selector-desc"
      >
        <slot :item="item" name="desc">
          {{ item.desc }}
        </slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Selector",
  props: {
    value: {
      type: Object,
      required: false,
      default: null
    },
    items: {
      type: Array,
      required: true
    },
    multiselect: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      this.$emit("input", this.prepareValue(item));
    },

    prepareValue(item) {
      if (this.multiselect) {
        return this.value.includes(item)
          ? this.$_.without(this.value, item)
          : this.$_.union(this.value, [item]);
      }
      return this.value === item ? null : item;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";

.selector {
  padding: 0.25rem 1.25rem;
  background-color: $light;
  border: 1px solid $border;
  border-radius: $radius;

  .selector-item {
    padding: 0.75rem 0;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-row-gap: 0.25rem;
    cursor: pointer;

    .selector-input {
      grid-column: 1;
    }

    &:hover .selector-input ::v-deep .check {
      &:not(.is-danger) {
        border-color: $primary;
      }

      &.is-danger {
        border-color: $danger;
      }
    }

    .selector-left-label {
      grid-column: 2;
    }

    .selector-desc:not(:empty) {
      font-size: $size-7;
      grid-column: 2;
      grid-row: 2;
      color: $grey-dark;
    }

    .selector-right-label:not(:empty) {
      grid-column: 3;
      font-weight: $weight-semibold;
    }
  }

  .selector-item + .selector-item {
    border-top: 1px solid $border;
  }
}
</style>
