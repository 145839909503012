<template>
  <b-collapse class="card">
    <div slot="trigger" slot-scope="props" class="card-header">
      <p class="card-header-title">
        <strong v-if="title">{{ title }}</strong>
      </p>
      <a class="card-header-icon has-text-grey-light">
        <b-icon :icon="props.open ? 'minus' : 'plus'" size="is-small" />
      </a>
    </div>
    <div :class="{ 'is-paddingless': isPaddingless }" class="card-content">
      <slot />
    </div>
  </b-collapse>
</template>

<script>
export default {
  name: "Collapse",
  props: {
    title: {
      type: String,
      required: false,
      default: ""
    },
    isPaddingless: {
      type: Boolean,
      default: false
    }
  }
};
</script>
