<template>
  <div class="ribbon">
    <div
      :class="{
        'has-container-padding': padding === 'default',
        'is-paddingless': padding === 'none'
      }"
      class="container"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Ribbon",

  props: {
    padding: {
      type: String,

      default: "default",

      validator: value => {
        return ["none", "default", "bulma-default"].indexOf(value) !== -1;
      }
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";

.ribbon .container.has-container-padding {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @include desktop {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}
</style>
