import { $functions } from "@/firebase";

const getters = {};

const actions = {
  // eslint-disable-next-line no-empty-pattern
  "backups/api": ({}, { type, siteId, payload = {} }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-site-getBackups")({
          type,
          siteId,
          payload
        })
        .then(result => {
          return resolve(result.data);
        })
        .catch(error => {
          console.error(error.message);
          return reject(error);
        });
    });
  },
  // eslint-disable-next-line no-empty-pattern
  "backups/downloadBackup": ({}, { siteId, fqdn, name, backupId }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-site-downloadDBSnapshot")({
          siteId,
          fqdn,
          name,
          backupId
        })
        .then(result => {
          return resolve(result.data);
        })
        .catch(error => {
          console.error(error.message);
          return reject(error);
        });
    });
  }
};

const mutations = {};

export { getters, actions, mutations };
