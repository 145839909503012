<template>
  <picture :style="{ fontSize: `${size}px` }" :title="name" @click="onClick">
    <img v-if="avatar.length" :alt="name" :src="avatar" />

    <span v-else-if="name">{{ initials }}</span>

    <span v-else>
      <b-icon icon="user-alt" size="is-small" />
    </span>

    <div v-if="uploadable">
      <div v-if="!loading" class="upload-bg" />
      <b-icon loading icon="camera" size="is-small" class="upload-icon" />
      <div v-if="loading" class="upload-loading" />
    </div>
  </picture>
</template>

<script>
export default {
  props: {
    name: { type: String, default: "" },
    avatar: { type: String, default: "" },
    size: { type: Number, default: 40 },
    uploadable: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    cssClass: { type: String, default: "" }
  },

  computed: {
    initials() {
      const name = this.name
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .trim()
        .split(" ");

      if (name.length > 1) {
        let first = this.$_.first(this.$_.first(name));
        let last = this.$_.first(this.$_.last(name));
        return `${first + last}`.toUpperCase();
      } else {
        return name[0].substring(0, 2).toUpperCase();
      }
    }
  },
  methods: {
    onClick() {
      if (this.loading) return;
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";

picture {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 1px solid $white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  background: $white;
  user-select: none;
  cursor: pointer;

  & > span {
    font-family: "Quicksand", sans-serif;
    font-size: 0.4em;
    font-weight: 500;
    line-height: 1;
    background: $dark;
    box-shadow: 0 0 0 1.5em $dark;
    color: $dark-invert;
  }

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 1;
  }
  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;

    & > .upload-bg,
    & > .upload-icon {
      position: absolute;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    & > .upload-bg {
      z-index: 3;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        background-color: $dark;
        opacity: 0.5;

        + .upload-icon {
          opacity: 1;
          z-index: 4;
          font-size: 40%;
          pointer-events: none;
        }
      }
    }
    & > .upload-icon {
      font-size: 30%;
      color: $primary;
      z-index: 0;
      transition: font-size 0.1s ease-in-out;
    }
    & > .upload-loading {
      position: absolute;
      top: 25%;
      left: 25%;
      border: 5px solid rgba(0, 0, 0, 0.15);
      border-top: 6px solid $primary;
      border-radius: 50%;
      width: 50%;
      height: 50%;
      animation: spin 0.666s linear infinite;
      z-index: 5;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
