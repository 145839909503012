<template>
  <form @submit.prevent="validateBeforeSubmit">
    <modal-card :cancel-text="'Close'" title="Select date and time">
      <div class="field-columns">
        <div class="column is-12">
          <b-field :label="`Date*`" expanded>
            <b-datepicker
              v-model="datePicked"
              :disabled="isProcessing"
              :max-date="maxDate"
              :min-date="minDate"
              placeholder="Type or select a date"
              editable
              expanded
              icon="calendar-alt"
              inline
            ></b-datepicker>
          </b-field>
        </div>
        <div class="column is-12">
          <b-field :label="`Time*`" expanded>
            <b-timepicker
              v-model="timePicked"
              :disabled="isProcessing"
              :increment-minutes="incrementMinutes"
              :max-time="maxTime"
              :min-time="minTime"
              placeholder="Type or select a date"
              editable
              expanded
              icon="clock"
              position="is-top-right"
            ></b-timepicker>
          </b-field>
        </div>
      </div>

      <button
        slot="footer"
        :class="{ 'is-loading': isProcessing }"
        :disabled="formIsDisabled"
        class="button is-success has-margin-top-75"
      >
        Confirm
      </button>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "TimestampModal",
  props: {
    timestamp: { type: String, required: true },
    min: { type: String, required: true },
    max: { type: String, required: true },
    format: { type: String, default: "DD-MM-YYYY HH:mm" },
    incrementMinutes: { type: Number, default: 1 }
  },
  data() {
    return {
      datePicked: null,
      timePicked: null,
      isProcessing: false
    };
  },
  computed: {
    minDate() {
      return this.min
        ? new Date(
            this.$moment(this.min, this.format)
              .subtract(1, "d")
              .add(1, "minute")
          )
        : null;
    },
    maxDate() {
      return this.max
        ? new Date(this.$moment(this.max, this.format).subtract(1, "minute"))
        : null;
    },
    minTime() {
      return !!this.min &&
        !!this.datePicked &&
        this.datePicked.getTime() ===
          new Date(
            this.$moment(this.min, this.format).set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0
            })
          ).getTime()
        ? this.minDate
        : null;
    },
    maxTime() {
      return !!this.max &&
        !!this.datePicked &&
        this.datePicked.getTime() ===
          new Date(
            this.$moment(this.max, this.format).set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0
            })
          ).getTime()
        ? this.maxDate
        : null;
    },
    pickedDateTime() {
      if (!!this.datePicked && !!this.timePicked) {
        const newDate = this.datePicked;
        newDate.setHours(this.timePicked.getHours());
        newDate.setMinutes(this.timePicked.getMinutes());
        return this.$moment(newDate).format(this.format);
      }

      return null;
    },
    isValid() {
      const moment = this.$moment(this.pickedDateTime, this.format, true);
      const minDate = this.$moment(this.min, this.format);
      const maxDate = this.$moment(this.max, this.format);

      if (!moment.isValid()) return false;

      if (this.min && moment.isSameOrBefore(minDate)) return false;

      if (this.max && moment.isSameOrAfter(maxDate)) return false;

      return true;
    },
    formIsDisabled() {
      return !this.isValid;
    }
  },
  created() {
    this.datePicked = this.timestamp
      ? new Date(
          this.$moment(this.timestamp, this.format).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          })
        )
      : new Date(
          this.$moment().set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          })
        );

    this.timePicked = this.timestamp
      ? new Date(this.$moment(this.timestamp, this.format))
      : new Date(
          this.$moment().set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          })
        );
  },
  methods: {
    validateBeforeSubmit() {
      if (this.isValid) {
        this.isProcessing = true;
        this.$emit("success", this.pickedDateTime);
        this.isProcessing = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep.datepicker .dropdown-menu,
::v-deep.datepicker .datepicker-table {
  width: 100%;
}
</style>
