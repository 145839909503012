import Vue from "vue";
import { $firestore, $functions } from "@/firebase";
import moment from "moment";
import _ from "lodash";
import {
  query,
  collection,
  where,
  orderBy,
  doc,
  getDocs,
  limit
} from "firebase/firestore";

const getters = {
  contract: state => siteId => {
    return state.contracts[siteId] || null;
  },
  "contract/daysRemaining": () => contract => {
    const startDate = moment(new Date());
    const endDate = _.get(contract, "dateNextDue")
      ? moment(new Date(contract.dateNextDue._seconds * 1000))
      : startDate;
    return endDate.diff(startDate, "days");
  },
  "contract/termLength": () => contract => {
    const termLength = _.get(contract, "product.term.length", 0);
    return termLength === 1 ? `month` : `${termLength} months`;
  },
  "contract/dueDate": () => contract => {
    if (!contract) return "";
    const dateNextDue = new Date(contract.dateNextDue._seconds * 1000);
    return moment(dateNextDue).format("MMMM Do, YYYY");
  },
  "contract/status": () => contract => {
    return (
      {
        "-1": "lapsed",
        "0": "cancelled",
        "1": "active",
        "2": "overdue"
      }[_.get(contract, "status")] || "none"
    );
  }
};

const actions = {
  observeContracts: ({ dispatch, commit }, { userId }) => {
    const contractsRef = query(
      collection($firestore, "contracts"),
      where(`authorId`, `==`, userId),
      orderBy(`status`, `desc`),
      orderBy(`dateNextDue`, `asc`)
    );

    return dispatch(
      "observe",
      {
        key: `billing/${userId}/contracts`,
        ref: contractsRef,
        onSnapshot: snapshot => {
          _.each(snapshot.docChanges(), change => {
            const contract = change.doc;
            if (change.type === "removed") {
              commit("unsetContract", {
                siteId: contract.id
              });
            } else {
              commit("setContract", {
                siteId: contract.id,
                contractData: contract.data()
              });
            }
          });
        }
      },
      { root: true }
    );
  },

  unobserveContracts: ({ commit, dispatch }, { userId }) => {
    return dispatch(`unobserve`, `billing/${userId}/contracts`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit(`setContracts`);
      }
    });
  },

  getSiteSubscription: (context, siteId) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-site-getSubscription")({
          siteId
        })
        .then(result => resolve(result.data.data))
        .catch(error => reject(error.message));
    });
  },

  observeContract: ({ dispatch, commit }, { siteId }) => {
    const contractRef = doc($firestore, `contracts`, siteId);
    return dispatch(
      "observe",
      {
        key: `billing/contracts/${siteId}`,
        ref: contractRef,
        onSnapshot: contract => {
          commit("setContract", {
            siteId,
            contractData: contract.data() || {}
          });
        }
      },
      { root: true }
    ).catch(() => {
      dispatch("unobserveContract", { siteId });
    });
  },

  unobserveContract: ({ commit, dispatch }, { siteId }) => {
    return dispatch(`unobserve`, `billing/contracts/${siteId}`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit(`unsetContract`, { siteId });
      }
    });
  },

  findSubscription: async (context, { siteId }) => {
    const siteRef = doc(collection($firestore, `sites`), siteId);
    const contractsRef = collection($firestore, `contracts`);
    const queryRef = query(
      contractsRef,
      where("site.ref", "==", siteRef),
      where("provision.type", "==", "subscription"),
      orderBy("status", "desc"),
      limit(1)
    );

    try {
      const { empty, docs } = await getDocs(queryRef);
      if (empty) return Promise.resolve();
      return Promise.resolve(docs[0]);
    } catch (error) {
      return Promise.reject(error.message);
    }
  },

  updateContract: (
    context,
    { contractId, price, dateNextDue, dateQuotaResets, status }
  ) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-contract-update")({
          contractId,
          price,
          dateNextDue:
            dateNextDue && moment.isDate(dateNextDue)
              ? moment(dateNextDue).unix()
              : null,
          dateQuotaResets:
            dateQuotaResets && moment.isDate(dateQuotaResets)
              ? moment(dateQuotaResets).unix()
              : null,
          status
        })
        .then(result => {
          return resolve(result.data.message);
        })
        .catch(error => {
          return reject(error.message);
        });
    });
  },
  transferContract: (context, { contractId, oldSiteId, newSiteId }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-contract-transfer")({
          contractId,
          oldSiteId,
          newSiteId
        })
        .then(result => {
          return resolve(result.data.message);
        })
        .catch(error => {
          return reject(error.message);
        });
    });
  },
  createContract: (context, { siteId, payload }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-contract-create")({
          siteId,
          payload
        })
        .then(result => {
          return resolve(result.data);
        })
        .catch(error => {
          return reject(error.message);
        });
    });
  },
  changeContractPlan: (context, { contractId, productId, termId }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-contract-changePlan")({
          contractId,
          productId,
          termId
        })
        .then(result => {
          return resolve(result.data.message);
        })
        .catch(error => {
          return reject(error.message);
        });
    });
  },
  getUpgradeOptions: (context, { contractId }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-contract-getUpgradeOptions")({
          contractId
        })
        .then(result => {
          return resolve(result.data);
        })
        .catch(error => {
          return reject(error.message);
        });
    });
  },
  calculateContractUpgrade: (
    context,
    { productId, productTermId, paymentMethodId, contractId }
  ) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable(`onCall-contract-calculateUpgrade`)({
          contractId,
          paymentMethodId,
          productId,
          productTermId
        })
        .then(result => resolve(result.data))
        .catch(error => reject(error));
    });
  },
  upgradeContract: (
    context,
    { contractId, productId, productTermId, paymentMethodId }
  ) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-contract-upgrade")({
          contractId,
          productId,
          productTermId,
          paymentMethodId
        })
        .then(result => resolve(result.data))
        .catch(error => reject(error.message));
    });
  }
};

const mutations = {
  setContracts: (state, payload = {}) => {
    Vue.set(state, `contracts`, payload);
  },

  setContract: (state, { siteId, contractData }) => {
    contractData = _.isEmpty(contractData)
      ? contractData
      : _.merge({}, contractData, { _id: siteId });
    Vue.set(state.contracts, siteId, contractData);
  },

  unsetContract: (state, { siteId }) => {
    if (state.contracts[siteId]) {
      Vue.delete(state.contracts, siteId);
    }
  }
};

export { getters, actions, mutations };
