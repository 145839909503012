<template>
  <b-input
    v-model="displayValue"
    v-bind="$attrs"
    type="text"
    :disabled="disabled"
    @blur="isInFocus = false"
    @focus="isInFocus = true"
  />
</template>

<script>
export default {
  name: "CurrencyInput",
  props: {
    value: {
      type: Number,
      required: true
    },
    prefix: {
      type: String,
      default: ""
    },
    suffix: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isInFocus: false
    };
  },
  computed: {
    displayValue: {
      get() {
        if (this.isInFocus) {
          return this.value !== undefined ? this.value.toString() : "";
        } else {
          return (
            this.prefix +
            (this.value === ""
              ? `${this.prefix !== "" ? " " : ""}--${
                  this.suffix !== "" ? " " : ""
                }`
              : this.value !== undefined
              ? this.value
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
              : "") +
            this.suffix
          );
        }
      },
      set(value) {
        value = parseFloat(value.replace(/[^\d.]/g, ""));
        this.$emit("input", isNaN(value) ? "" : value);
      }
    }
  }
};
</script>
