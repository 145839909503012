import Vue from "vue";
import Confirm from "./confirm";
import { ModalProgrammatic } from "buefy/src/components/modal";

const ConfirmProgrammatic = {
  open(params) {
    const defaultParam = {
      component: () => import("./confirmModal"),
      parent: window.$rootVue,
      width: 500,
      hasModalCard: true
    };

    const propsData = Object.assign(defaultParam, params);
    return ModalProgrammatic.open(propsData);
  }
};

const ConfirmPlugin = {
  install(Vue) {
    Vue.prototype["$confirm"] = ConfirmProgrammatic; // register component programmatic
  }
};

Vue.use(ConfirmPlugin);

export default Confirm;

export { ConfirmProgrammatic };
