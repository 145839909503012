<template>
  <span :style="{ fontSize: `${rems}rem` }" class="pulse" />
</template>

<script>
export default {
  name: "Pulse",
  props: {
    rems: {
      type: Number,
      required: false,
      default: 0.625
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
span.pulse {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: $primary;
  box-shadow: 0 0 0 rgba($primary, 0.5);
  animation: pulse 1.2s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($primary, 0.5);
    //box-shadow: 0 0 0 0 rgba(var(--primary-color), 0.5);
  }
  70% {
    box-shadow: 0 0 0 0.75em rgba($primary, 0);
    //box-shadow: 0 0 0 0.75em rgba(var(--primary-color), 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($primary, 0);
    //box-shadow: 0 0 0 0 rgba(var(--primary-color), 0);
  }
}
</style>
