<template>
  <ul :class="{ 'has-border': !$_.isEmpty(files) }">
    <div
      class="columns is-multiline is-marginless is-scrollable file-list"
      style="max-height:200px"
    >
      <div
        v-for="(file, index) in files"
        :key="index"
        class="column is-12 file"
        :class="{ invalid: !file.valid.valid }"
      >
        <div class="image-wrapper has-margin-right-75">
          <img v-if="file.valid.valid" :src="thumbnail(file)" />
          <b-icon
            v-if="!file.valid.valid"
            pack="fas"
            icon="exclamation-triangle"
            size="is-small"
            class="has-text-warning image-invalid-icon"
          />
        </div>

        <div
          class="file-info is-flex has-items-aligned-center"
          :class="{ 'has-text-grey': !file.valid.valid }"
        >
          <p class="is-size-7 has-overflow-ellipsis-right">
            {{ file.file.name }}
          </p>
          <status-label
            v-if="!file.valid.valid"
            class="has-margin-left-50 has-margin-right-50"
            :default-type="'is-warning'"
            :status="file.valid.message"
          />
        </div>

        <section class="options flex-row-end is-flex has-items-aligned-center">
          <p v-if="file.file.size > 0" class="timestamp has-white-space-nowrap">
            {{ humanFileSize(file.file.size) }}
          </p>

          <b-icon
            pack="fas"
            icon="trash-alt"
            size="is-small"
            class="has-text-danger has-cursor-pointer"
            @click.native.stop="$emit('remove', { file, index })"
          />
        </section>
      </div>
    </div>
  </ul>
</template>

<script>
export default {
  name: "FileListVertical",
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  inject: ["humanFileSize", "thumbnail"]
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.has-border {
  border: 1px solid $border;
  border-radius: 5px;
}
.file-list {
  .file {
    display: flex;
    padding: 0.625em;
    border-radius: 0.3em;
    align-items: center;
    max-height: 2.75em;
    color: $black;

    &.invalid {
      .image-wrapper {
        position: relative;
        &::before {
          content: " ";
          width: 2em;
          height: 2em;
          opacity: 0.7;
          border-radius: 0.3em;
          top: 0px;
          left: 0px;
          position: absolute;
          background-color: black;
        }
        .image-invalid-icon {
          position: absolute;
          top: 8px;
          left: 8px;
        }
      }
    }
    .file-info {
      &.invalid > :not(.error-message) {
        text-decoration: line-through;
      }
      min-width: 0;
    }

    .timestamp {
      margin-right: 1.25em;
      text-transform: uppercase;
      font-size: 0.9em;
      color: $grey-light;
    }

    .error-message {
      color: red;
      font-size: 0.75em;
    }

    &:hover {
      background-color: $light;
    }

    .image-wrapper {
      position: relative;
      height: 2em;
      width: 2em;
      min-height: 2em;
      min-width: 2em;

      img {
        border-radius: 5px;
        max-height: 2em;
        object-fit: cover;

        width: 256px;
        height: 256px;
      }
    }
  }
  & > :not(:first-child):not(:only-child) {
    &.file:hover {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
  & > :not(:last-child) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 1px solid $grey-lighter;
  }
}
</style>
