<template>
  <a
    :key="color"
    :style="{ backgroundColor: color }"
    :class="{ 'is-selected': selected }"
    class="color-dot"
  />
</template>

<script>
export default {
  name: "ColorDot",
  props: {
    color: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
a.color-dot {
  display: block;
  font-size: 1rem;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  margin: 0.15em;
  &:not(.is-selected):hover {
    transform: scale(1.2);
  }
  &:not(.is-selected):not(:hover) {
    opacity: 0.25;
  }
}
</style>
