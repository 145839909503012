<template>
  <div class="modal-card">
    <slot name="header">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-white has-text-centered">
          {{ title }}
        </p>
      </header>
    </slot>

    <slot name="subheader" />

    <section class="modal-card-body">
      <slot />
    </section>
    <footer
      v-if="hasFooter"
      class="modal-card-foot has-content-justified-between"
    >
      <button
        v-if="$parent.$parent.canCancel"
        :disabled="processing"
        type="button"
        class="button is-light"
        tabindex="-1"
        @click="$parent.$parent.close()"
      >
        {{ cancelText }}
      </button>
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  name: "ModalCard",
  props: {
    title: {
      type: String,
      required: true
    },
    processing: {
      type: Boolean,
      required: false,
      default: false
    },
    cancelText: {
      type: String,
      required: false,
      default: "Cancel"
    },
    hasFooter: {
      type: Boolean,
      required: false,
      default: true
    }
  }
};
</script>
