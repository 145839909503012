import Vue from "vue";
import { $firestore, $functions, $storage } from "@/firebase";
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { ref as storageRef } from "firebase/storage";
import { Toast } from "buefy/dist/components/toast";
import _ from "lodash";

const getters = {
  "site/report": state => ({ siteId, reportId }) => {
    return _.get(state.reports.observed, `${siteId}.${reportId}`, null);
  }
};

const actions = {
  getUptimeReport: (context, { siteId, fromCache, period = "day", query }) => {
    return new Promise((resolve, reject) => {
      const monitoringReportRef = doc(
        $firestore,
        `sites/${siteId}/cache/monitoringReport-${period}`
      );

      getDoc(monitoringReportRef).then(document => {
        let twoHoursAgo = new Date();
        twoHoursAgo.setHours(twoHoursAgo.getHours() - 2);
        if (
          fromCache &&
          document.exists() &&
          document.data().dateCached &&
          document.data().dateCached.toDate() > twoHoursAgo
        ) {
          const doc = document.data();
          return resolve({ ...doc.data, dateCached: doc.dateCached });
        }

        return $functions("europe-west2")
          .httpsCallable("onCall-site-getMonitor")({
            siteId,
            query,
            period
          })
          .then(result => resolve(result.data.data))
          .catch(error => reject(error.message));
      });
    });
  },
  observeReport: ({ commit, dispatch }, { siteId, reportId }) => {
    return new Promise((resolve, reject) => {
      const reportRef = doc($firestore, `sites/${siteId}/reports`, reportId);
      return dispatch(
        "observe",
        {
          key: `sites/${siteId}/reports/${reportId}`,
          ref: reportRef,
          onSnapshot: report => {
            commit("setReport", {
              siteId,
              reportId,
              reportData: report.data() || null
            });
            resolve(report.exists());
          },
          onError: error => {
            reject(error);
          }
        },
        { root: true }
      );
    });
  },
  unobserveReport: ({ commit, dispatch }, { siteId, reportId }) => {
    return dispatch(`unobserve`, `sites/${siteId}/reports/${reportId}`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit(`unsetReport`, { siteId, reportId });
      }
    });
  },
  generateMonthlyReport: (context, { siteId }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-site-generateMonthlyReport")({ siteId })
        .then(result => resolve(result.data))
        .catch(error => reject(error.message));
    });
  },
  "report/uploadFile": (
    { dispatch },
    { siteId, reportId, id, file, resolveOnUploadComplete = true }
  ) => {
    return new Promise((resolve, reject) => {
      const reportRef = doc($firestore, `sites/${siteId}/reports/${reportId}`);
      const fileRef = storageRef(
        $storage(`${process.env.VUE_APP_FIREBASE_PROJECT_ID}-report`),
        `${siteId}/${reportId}/${id}`
      );

      // Append file info to the post
      const payload = {
        name: file.name,
        bucket: fileRef.bucket,
        path: fileRef.fullPath,
        contentType: file.type,
        size: file.size
      };

      const promises = [
        updateDoc(reportRef, {
          files: arrayUnion(payload)
        }), // Add post
        dispatch(
          "file/uploadFile", // Upload file
          {
            fileRef,
            file
          },
          { root: true }
        )
      ];

      const onError = function(error) {
        Toast.open({
          message:
            error.code === "storage/canceled"
              ? `File upload canceled`
              : `Your message could not be saved`,
          position: "is-bottom",
          type: error.code === "storage/canceled" ? "is-warning" : "is-danger"
        });
      };

      Promise.all(promises)
        // eslint-disable-next-line no-unused-vars
        .then(([reportResult, fileResult]) => {
          fileResult.uploadTask.on(
            "state_changed",
            // eslint-disable-next-line no-unused-vars
            snapshot => {},
            error => onError(error),
            () => {
              if (resolveOnUploadComplete) resolve({ reportRef, fileResult });
            }
          );
          if (!resolveOnUploadComplete) resolve({ reportRef, fileResult });
        })
        .catch(error => {
          reject(error);
          onError(error);
        });
    });
  }
};

const mutations = {
  setReport: (state, { siteId, reportId, reportData = null }) => {
    let siteData = _.clone(state.reports.observed[siteId] || {});
    _.set(siteData, `${reportId}`, reportData);
    Vue.set(state.reports.observed, siteId, siteData);
  },
  unsetReport: (state, { siteId, reportId }) => {
    if (!state.reports.observed[siteId]) return;
    if (_.has(state.reports.observed[siteId], reportId)) {
      Vue.delete(state.reports.observed[siteId], reportId);
    }
  }
};

export { getters, actions, mutations };
