<template>
  <div class="has-text-centered">
    <a
      :class="{ 'is-loading': loading }"
      :disabled="loading"
      class="button is-medium is-rounded has-margin-bottom-150 has-margin-top-150"
      @click="$emit('loadMore')"
    >
      <span class="is-size-6">
        <b-icon :icon="icon" size="is-small" />
        <span v-text="label" />
      </span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    icon: { default: "sync-alt", type: String },
    label: { default: "Load more", type: String },
    loading: { default: false, type: Boolean }
  }
};
</script>
