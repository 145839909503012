import store from "@store";

export default {
  path: "/print",
  component: () => import("@skeleton/print/index"),
  beforeEnter: (to, from, next) => {
    store.commit("ui/routing", true);
    next();
  },
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: "invoice/:invoiceId",
      component: () => import("@shared/invoices/_invoice"),
      props: true,
      meta: {
        title: "Print invoice"
      }
    },
    {
      path: "*",
      component: () => import("@views/error"),
      props: { code: 404 }
    }
  ]
};
