import _ from "lodash";

const defaultState = () => {
  return {
    observed: {},
    terms: {
      observed: {}
    }
  };
};

const state = defaultState();

const getters = Object.assign(
  require("./core").getters,
  require("./terms").getters
);

const actions = Object.assign(
  require("./core").actions,
  require("./terms").actions,
  {
    reset: async ({ dispatch, commit }) => {
      try {
        await dispatch(
          "unobserveAll",
          { containing: "products" },
          { root: true }
        );
        _.each(state.alerts.observed, alert => alert.snackbar.close());
        commit("resetState");
      } catch (error) {
        console.error(error);
      }
    }
  }
);

const mutations = Object.assign(
  require("./core").mutations,
  require("./terms").mutations,
  {
    resetState: state => {
      Object.assign(state, defaultState());
    }
  }
);

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
