<template>
  <p>{{ format ? $moment(date).format(format) : $moment(date).from() }}</p>
</template>

<script>
export default {
  props: {
    date: {
      type: Date,
      required: true
    },
    format: {
      type: String,
      required: false,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
p {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.375rem;
  opacity: 0.75;
  // color: $grey;
}
</style>
