<template>
  <div class="floating-button-container">
    <a
      class="floating-fallback button is-success has-margin-top-100"
      @click="$emit('click')"
    >
      <span>{{ label }}</span>
    </a>

    <a :title="label" class="floating-button" @click="$emit('click')">
      <b-icon icon="plus" />
    </a>
  </div>
</template>

<script>
export default {
  name: "FloatingButton",
  props: {
    label: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
@include touch {
  .floating-button {
    display: none !important;
  }
}
@include desktop {
  .floating-add-container {
    position: relative;
    padding-top: 1.5rem;
  }
  .floating-fallback {
    display: none !important;
  }
  .floating-button {
    position: absolute;
    bottom: -1.5em;
    right: 1.5em;
    margin-top: 1px;
    width: 3.5rem;
    height: 3.5rem;
    background: $primary;
    border-radius: 50%;
    display: flex;
    transition: transform linear 0.05s;
    box-shadow: 0 4px 8px 0 rgba($dark, 0.2);
    span {
      margin: auto;
      color: $primary-invert;
    }
    &:hover {
      transform: scale(1.15) rotate(360deg);
    }
    &:active {
      background: darken($primary, 5);
      transform: scale(1.1);
    }
  }
}
</style>
