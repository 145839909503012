<template>
  <div>
    <b-field v-if="filterable" grouped class="has-margin-bottom-100">
      <b-input
        v-model="filter"
        expanded
        :disabled="disabled"
        :placeholder="placeholder"
      />
      <b-select v-model="view" :disabled="disabled">
        <option value>All ({{ allIds.length }})</option>
        <option value="selected">Selected ({{ selectedIds.length }})</option>
        <option value="unselected"
          >Unselected ({{ unselectedIds.length }})</option
        >
      </b-select>
    </b-field>

    <p
      v-if="!filteredData.length"
      class="has-padding-100 has-text-grey-light has-text-centered"
    >
      There are no results
      {{ view || filter ? "matching your filter(s)" : "to show" }}.
    </p>

    <apart
      v-for="{
        label,
        id,
        status,
        isDisabled = false,
        isDisabledWhy = null
      } in filteredData"
      :key="`row-${id}`"
      class="has-cursor-pointer"
      :class="{ 'has-text-grey-light': isDisabled }"
      @click.native="toggle(id, isDisabled, !!!selected[id])"
    >
      <p>
        {{ label }}
        <status-label v-if="status" :status="status" />
      </p>
      <p v-if="isDisabled" class="is-size-7">{{ isDisabledWhy }}</p>
      <b-switch
        v-else
        :value="!!selected[id]"
        :v-model="selected[id]"
        :disabled="disabled"
        @input="toggle(id, isDisabled, $event)"
        @click.native.stop
      />
    </apart>
  </div>
</template>

<script>
export default {
  name: "SwitchSelector",
  props: {
    data: {
      type: Array,
      required: true
    },
    selected: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    placeholder: {
      type: String,
      required: false,
      default: "Filter"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    filterable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      filter: "",
      view: ""
    };
  },
  computed: {
    allIds() {
      return this.$_(this.data)
        .map("id")
        .value();
    },
    selectedIds() {
      return this.$_(this.selected)
        .pickBy(selected => !!selected)
        .keys()
        .value();
    },
    unselectedIds() {
      return this.$_(this.data)
        .filter(row => !this.$_.get(this.selected, row.id))
        .map("id")
        .value();
    },
    filteredData() {
      return this.$_(this.data)
        .filter(row => {
          if (this.view === "selected")
            return this.$_.get(this.selected, row.id);
          if (this.view === "unselected")
            return !this.$_.get(this.selected, row.id);
          return true;
        })
        .filter(row => row.label.includes(this.filter))
        .orderBy(["label"], ["asc"])
        .value();
    }
  },
  methods: {
    toggle(id, isDisabled, $event) {
      if (this.disabled || isDisabled) return;
      this.$set(this.selected, id, $event);
      this.$emit("select", { id, selected: $event });
    }
  }
};
</script>
