<template>
  <div class="schedule-wrapper">
    <b-field>
      <b-input
        :value="!!date ? $moment(date).format('Do MMM, YYYY') : ''"
        expanded
        placeholder="Select date"
        readonly
        :disabled="disabled"
        @click.native="showDatePickerModal"
      />
      <b-select
        v-model="timeSelected"
        placeholder="Select time"
        :disabled="disabled"
      >
        <option v-for="(time, index) in times" :key="index">{{ time }}</option>
      </b-select>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "SchedulePicker",
  props: {
    value: {
      type: Date,
      required: false,
      default: null
    },
    allowOnlyDate: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      date: null,
      dateSelected: this.value,
      timeSelected: null
    };
  },
  computed: {
    times() {
      return this.$_(this.$_.range(0, 24))
        .map(i => {
          return i < 10 ? [`0${i}:00`, `0${i}:30`] : [`${i}:00`, `${i}:30`];
        })
        .flatten()
        .value();
    },
    isValid() {
      if (this.allowOnlyDate && this.$_.isDate(this.date)) return true;

      return !!this.timeSelected && this.$_.isDate(this.date);
    },
    formattedDate() {
      return this.isValid
        ? this.$moment(
            this.$moment(this.date).format("YYYY-MM-DD ") + this.timeSelected,
            "YYYY-MM-DD HH:mm"
          ).toDate()
        : null;
    }
  },
  watch: {
    value(newVal) {
      if (this.$moment(newVal).isSame(this.dateSelected)) return;
      this.dateSelected = newVal;
      this.parseDateAndSet(this.dateSelected);
    },
    formattedDate(value) {
      this.$emit("input", value);
    }
  },
  mounted() {
    this.parseDateAndSet(this.dateSelected);
  },
  methods: {
    showDatePickerModal() {
      const modal = this.$modal.open({
        component: () => import("@src/components/datePickerModal"),
        parent: this,
        width: 380,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          value: this.date,
          inline: true,
          editable: true,
          mobileNative: false,
          minDate: new Date()
        },
        events: {
          input: value => {
            this.date = value;
            modal.close();
          }
        }
      });
    },
    parseDateAndSet(newVal) {
      if (!newVal && !this.$_.isDate(newVal)) return;
      this.date = new Date(newVal);
      const time = this.$moment(newVal).format("HH:mm");
      if (this.times.includes(time)) {
        this.timeSelected = time;
      }
    }
  }
};
</script>
