/* eslint-disable no-unused-vars */

/**
 * @typedef PaginationResult
 * @ @property {Array<DocumentSnapshot>} results
 * @ @property {Boolean} complete
 */

import {
  query,
  startAfter,
  limit as queryLimit,
  getDocs,
  DocumentSnapshot,
  CollectionReference
} from "firebase/firestore";

const actions = {
  /**
   * @param {*} ctx
   * @param {Object} payload
   * @param {CollectionReference} payload.ref
   * @param {DocumentSnapshot | null} payload.cursor
   * @param {Number} payload.limit
   * @returns {Promise<PaginationResult>}
   */
  getPaginated: (ctx, { ref, cursor = null, limit = 10 }) => {
    return new Promise((resolve, reject) => {
      ref = cursor ? query(ref, startAfter(cursor)) : ref;
      getDocs(query(ref, queryLimit(limit + 1)))
        .then(snapshot => {
          return resolve({
            results: snapshot.docs.splice(0, limit),
            complete: snapshot.size < limit + 1
          });
        })
        .catch(reject);
    });
  }
};

export default {
  namespaced: true,
  actions
};
