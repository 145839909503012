<template>
  <form @submit.prevent="onSubmit">
    <div class="modal-card modal-datepicker">
      <header class="modal-card-head has-background-primary">
        <p class="modal-card-title has-text-white has-text-centered">
          {{ title }}
        </p>
      </header>

      <section class="modal-card-body">
        <slot>
          <b-input
            ref="input"
            v-model="innerValue"
            v-bind="$attrs"
            v-on="listeners"
            @input="$emit('input', $event)"
          />
        </slot>
      </section>

      <footer class="modal-card-foot has-content-justified-between">
        <button
          :disabled="processing"
          type="button"
          class="button is-light"
          tabindex="-1"
          @click="$emit('close')"
        >
          {{ cancelText }}
        </button>

        <button
          :class="{ 'is-loading': processing }"
          :disabled="!valid || processing"
          type="submit"
          class="button is-success"
        >
          {{ submitText }}
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: "SingleInputModal",
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    },
    title: {
      type: String,
      required: false,
      default: "Input"
    },
    valid: {
      type: Boolean,
      required: false,
      default: true
    },
    cancelText: {
      type: String,
      required: false,
      default: "Cancel"
    },
    submitText: {
      type: String,
      required: false,
      default: "Submit"
    }
  },
  data() {
    return {
      processing: false,
      innerValue: this.value
    };
  },
  computed: {
    listeners() {
      const { ...listeners } = this.$listeners;
      return listeners;
    }
  },
  watch: {
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.input && this.$refs.input.$el.children[0].focus();
    });
  },
  methods: {
    onSubmit() {
      this.processing = true;
      this.$emit("submit", this.innerValue);
    }
  }
};
</script>
