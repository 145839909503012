import store from "@store";

export default {
  path: "/tasks",
  component: () =>
    import(/* webpackChunkName: "authenticated" */ "@skeleton/loggedIn/index"),
  beforeEnter: (to, from, next) => {
    store.commit("ui/routing", true);
    next();
  },
  meta: {
    requiresAuth: true,
    breadcrumb: {
      label: "My tasks"
    }
  },
  children: [
    {
      path: "",
      components: {
        default: () => import("@views/tasks")
      },
      children: [
        {
          path: "",
          name: "tasks",
          redirect: "open"
        },
        {
          path: "open",
          meta: {
            title: "My tasks"
          },
          component: () => import("@views/tasks/open")
        },
        {
          path: "closed",
          component: () => import("@views/tasks/closed")
        },
        {
          path: ":taskId",
          component: () => import("@views/tasks/task"),
          beforeEnter: (to, from, next) => {
            store.commit("ui/routing", true);
            next();
          },
          meta: {
            key: params => params.taskId,
            title: {
              param: "taskId",
              getter: "tasks/task",
              format: task => `Task ${task.reference} | ${task.fqdn}`
            },
            breadcrumb: {
              param: "taskId",
              getter: "tasks/task",
              label: task => `${task.reference}`
            }
          }
        }
      ]
    }
  ]
};
