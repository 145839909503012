import store from "@store";

export default {
  path: "/magic",
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: "/tasks/magic/:taskId",
      beforeEnter: (to, from, next) => {
        if (store.getters["user/isAdmin"]())
          return next({ path: `/admin/tasks/all/${to.params.taskId}` });
        if (store.getters["user/isAgent"]())
          return next({ path: `/admin/tasks/my/${to.params.taskId}` });
        // Default route
        return next({ path: `/tasks/${to.params.taskId}` });
      }
    },
    {
      path: "/sites/magic/:siteId",
      beforeEnter: (to, from, next) => {
        if (store.getters["user/isAdmin"]())
          return next({ path: `/admin/sites/all/${to.params.siteId}` });
        if (store.getters["user/isAgent"]())
          return next({ path: `/admin/sites/my/${to.params.siteId}` });
        // Default route
        return next({ path: `/sites/${to.params.siteId}` });
      }
    }
  ]
};
