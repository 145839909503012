import store from "@store";

export default {
  path: "/reseller",
  component: () =>
    import(/* webpackChunkName: "authenticated" */ "@skeleton/loggedIn/index"),
  beforeEnter: (to, from, next) => {
    store.commit("ui/routing", true);
    if (!store.getters["user/isReseller"]()) {
      return next({ name: "sites" });
    }
    next();
  },
  meta: {
    requiresAuth: true,
    breadcrumb: {
      label: "Reseller"
    }
  },
  children: [
    {
      path: "",
      name: "reseller",
      redirect: "controls",
      components: {
        default: () => import("@views/reseller")
      },
      children: [
        {
          path: "controls",
          name: "controls",
          component: () => import("@views/reseller/controls"),
          meta: {
            title: "Reseller controls",
            breadcrumb: {
              label: "Controls"
            }
          }
        },
        {
          path: "clients",
          redirect: "controls"
        },
        {
          path: "clients/:userId",
          name: "resellerClient",
          component: () => import("@views/reseller/client"),
          beforeEnter: (to, from, next) => {
            store.commit("ui/routing", true);
            next();
          }
        }
      ]
    }
  ]
};
