<template>
  <div class="data-sorter is-stretched">
    <div class="field has-addons">
      <div class="control is-expanded">
        <span class="select is-fullwidth">
          <b-select v-model="sort.field" expanded @input="changeField">
            <option
              v-for="sorter in sorters"
              :key="sorter.field"
              :value="sorter.field"
              >{{ sorter.label }}</option
            >
          </b-select>
        </span>
      </div>

      <div class="control">
        <button class="button is-primary" @click="changeDirection">
          <span class="icon is-small is-desc">
            <i v-if="sort.direction == 'asc'" class="fas fa-arrow-up" />
            <i v-else class="fas fa-arrow-down" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataSorter",
  props: {
    sorters: {
      type: Array,
      required: true
    },
    activeSort: {
      type: Object,
      required: true,
      validators: sort => {
        return this.$_.has(sort, ["field", "direction"]);
      }
    }
  },
  data() {
    return {
      sort: {
        field: this.activeSort.field,
        direction: this.activeSort.direction
      }
    };
  },
  methods: {
    changeField() {
      this.$emit("onSort", this.sort.field, this.sort.direction);
    },
    changeDirection() {
      this.sort.direction = this.sort.direction === "asc" ? "desc" : "asc";
      this.$emit("onSort", this.sort.field, this.sort.direction);
    }
  }
};
</script>
