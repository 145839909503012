import { $functions } from "@/firebase";

const actions = {
  add: (context, { event = "manual-entry", metadata = {} }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-utils-logEvent")({
          event,
          metadata
        })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  }
};

export default {
  namespaced: true,
  actions
};
