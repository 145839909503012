<template>
  <div class="contextualise">
    <div class="contextualise-wrapper">
      <div class="contextualise-text">
        <p>
          <strong>{{ label }}</strong>
        </p>
        <p class="is-size-7 has-text-grey">
          <slot name="desc" />
        </p>
      </div>
      <div class="contextualise-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contextualise",
  props: {
    label: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.contextualise {
  & + .contextualise {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid $border;
  }
  .contextualise-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -0.5rem -1rem;
    .contextualise-text,
    .contextualise-body {
      padding: 0.5rem 1rem;
    }
    .contextualise-text {
      width: 21rem;
      flex-grow: 1;
    }
    .contextualise-body {
      width: 21rem;
      flex-grow: 99;
    }
  }
}
</style>
