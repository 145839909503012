import Vue from "vue";
import _ from "lodash";
import { ModalProgrammatic } from "buefy/dist/components/modal";
import { sha256 } from "js-sha256";
import { $functions, $auth } from "@/firebase";
import { serverTimestamp } from "firebase/firestore";
import {
  EmailAuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  createUserWithEmailAndPassword,
  linkWithCredential,
  linkWithPopup,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  unlink,
  updatePassword
} from "firebase/auth";

const defaultState = () => {
  return {
    currentUser: {},
    claims: {},
    providers: {
      password: {
        label: "Password",
        provider: () => {
          const provider = new EmailAuthProvider();
          // Add scope if applicable
          return provider;
        },
        linked: false,
        color: "#00C6CE",
        icon: {
          code: "lock",
          pack: "fas"
        }
      },
      "google.com": {
        label: "Google",
        provider: () => {
          const provider = new GoogleAuthProvider();
          provider.addScope("https://www.googleapis.com/auth/userinfo.email");
          provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
          return provider;
        },
        linked: false,
        color: "#db4437",
        icon: {
          code: "google",
          pack: "fab"
        }
      },
      "facebook.com": {
        label: "Facebook",
        provider: () => {
          const provider = new FacebookAuthProvider();
          // Add scope if applicable
          return provider;
        },
        linked: false,
        color: "#3b5998",
        icon: {
          code: "facebook",
          pack: "fab"
        }
      },
      "github.com": {
        label: "GitHub",
        provider: () => {
          const provider = new GithubAuthProvider();
          provider.addScope("user:email");
          return provider;
        },
        linked: false,
        color: "#333333",
        icon: {
          code: "github",
          pack: "fab"
        }
      },
      "twitter.com": {
        label: "Twitter",
        provider: () => {
          const provider = new TwitterAuthProvider();
          // Add scope if applicable
          return provider;
        },
        linked: false,
        color: "#1da1f2",
        icon: {
          code: "twitter",
          pack: "fab"
        }
      }
    }
  };
};

const state = defaultState();

const getters = {
  OAuthProviders: state => () => {
    return _.filter(state.providers, p => {
      return p.provider().providerId !== "password";
    });
  },
  linkedProviders: state => () => {
    return _.filter(state.providers, provider => {
      return provider.linked;
    });
  },
  linkedOAuthProviders: state => () => {
    return _.filter(state.providers, p => {
      return p.linked && p.provider().providerId !== "password";
    });
  },
  userId: state => () => {
    return state.currentUser.uid || null;
  },
  isCurrentUser: state => userId => {
    return userId === state.currentUser.uid;
  },
  isAuthenticated: state => () => {
    return !!state.currentUser.uid;
  }
};

const actions = {
  init: ({ commit }, { currentUser, claims }) => {
    return new Promise(resolve => {
      commit(
        "setClaims",
        _.pick(claims, ["email", "isReseller", "isResellerUser", "user_id"])
      );
      commit("currentUser", { currentUser });
      _.forEach(currentUser.providerData, provider => {
        commit("linkProvider", { providerId: provider.providerId });
      });
      resolve();
    });
  },
  register: async ({ dispatch }, { email, password }) => {
    try {
      await createUserWithEmailAndPassword($auth, email, password);
      dispatch("affiliate/connect", null, { root: true });
      Vue.gtm.trackEvent({
        event: "app.event",
        category: "Fixed App",
        action: "Account created",
        label: `Method: email/password`
      });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.message);
    }
  },
  registerResellerUser: (context, { resellerId, email }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("reseller-auth-registerUser")({ resellerId, email })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },
  changeOneTimePassword: (context, { oldPassword, newPassword, token }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("reseller-changeOneTimePassword")({
          oldPassword,
          newPassword: sha256(newPassword),
          token
        })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },
  loginWithProvider: ({ dispatch }, { provider }) => {
    return new Promise((resolve, reject) => {
      signInWithPopup($auth, provider)
        .then(result => {
          const user = result.user.metadata;
          const isNewAccount = user.creationTime === user.lastSignInTime;
          if (isNewAccount) dispatch("affiliate/connect", null, { root: true });
          Vue.gtm.trackEvent({
            event: "app.event",
            category: "Fixed App",
            action: isNewAccount ? "Account created" : "User logged in",
            label: `Method: ${provider.providerId}`
          });
          dispatch("updateLastSignInTime", { userId: result.user.uid });
          $functions().httpsCallable("onCall-utils-logEvent")({
            event: "logged-in",
            metadata: { method: provider.providerId }
          });
          resolve({ message: "Welcome back" });
        })
        .catch(error => reject(error));
    });
  },
  loginWithPassword: ({ dispatch }, { email, password }) => {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword($auth, email, password)
        .then(result => {
          dispatch("updateLastSignInTime", { userId: result.user.uid });
          $functions().httpsCallable("onCall-utils-logEvent")({
            event: "logged-in",
            metadata: { method: "password" }
          });
          Vue.gtm.trackEvent({
            event: "app.event",
            category: "Fixed App",
            action: "User logged in",
            label: `Method: email/password`
          });
          resolve({ message: "Welcome back" });
        })
        .catch(error => reject(error));
    });
  },
  loginWithCustomToken: (context, { token }) => {
    return new Promise((resolve, reject) => {
      signInWithCustomToken($auth, token)
        .then(() => {
          $functions().httpsCallable("onCall-utils-logEvent")({
            event: "logged-in",
            metadata: { method: "token" }
          });
          resolve({ message: "Welcome back" });
        })
        .catch(error => reject(error));
    });
  },
  loginReseller: ({ dispatch }, { email, password, resellerId }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("reseller-auth-login")({
          username: email,
          password: sha256(password),
          resellerId
        })
        .then(result => {
          if (result.data) {
            if (_.isArray(result.data)) return resolve(result.data);

            if (result.data.token) {
              dispatch("loginWithCustomToken", {
                token: result.data.token
              })
                .then(result => resolve(result))
                .catch(error => reject(error));
            }
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateLastSignInTime: ({ dispatch }, { userId }) => {
    return dispatch(
      "user/updateProfile",
      {
        userId,
        payload: {
          dateOfLastSignIn: serverTimestamp()
        }
      },
      { root: true }
    );
  },
  linkProvider: ({ state, commit }, { providerId }) => {
    return new Promise((resolve, reject) => {
      const p = state.providers[providerId];
      return linkWithPopup(state.currentUser, p.provider())
        .then(() => {
          commit("linkProvider", { providerId });
          resolve({ message: `${p.label} account linked` });
          $functions().httpsCallable("onCall-utils-logEvent")({
            event: "linked-auth-provider",
            metadata: { provider: providerId }
          });
        })
        .catch(error => reject(error));
    });
  },
  unlinkProvider: ({ state, commit, getters }, { providerId }) => {
    return new Promise((resolve, reject) => {
      const p = state.providers[providerId];
      if (getters.linkedProviders().length < 2) {
        return reject({ message: "You can't unlink your only login method" });
      }
      unlink(state.currentUser, providerId)
        .then(() => {
          commit("unlinkProvider", { providerId });
          resolve({ message: `${p.label} account unlinked` });
          $functions().httpsCallable("onCall-utils-logEvent")({
            event: "unlinked-auth-provider",
            metadata: { provider: providerId }
          });
        })
        .catch(error => reject(error));
    });
  },
  changeEmail: async ({ dispatch }, { email }) => {
    try {
      await dispatch("reauthenticateUser");
      const result = await $functions().httpsCallable(
        "onCall-user-changeEmail"
      )({ email });
      const token = _.get(result.data, "data.token");
      if (token) {
        await dispatch("loginWithCustomToken", { token });
      }
      return Promise.resolve(result.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  adminChangeEmail: async (context, { userId, email }) => {
    try {
      const result = await $functions().httpsCallable(
        "onCall-account-changeEmail"
      )({ userId, email });
      return Promise.resolve(result.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  setPassword: ({ dispatch }, { password }) => {
    return new Promise((resolve, reject) => {
      dispatch("linkCredential", { password })
        .then(result => resolve(result))
        .catch(error => {
          if (error.code === "auth/requires-recent-login") {
            dispatch("reauthenticateUser")
              .then(() => {
                dispatch("linkCredential", { password })
                  .then(result => resolve(result))
                  .catch(error => reject(error));
              })
              .catch(error => reject(error));
          } else {
            reject(error);
          }
        });
    });
  },
  linkCredential: ({ state }, { password }) => {
    return new Promise((resolve, reject) => {
      const credential = EmailAuthProvider.credential(
        state.currentUser.email,
        password
      );
      linkWithCredential(state.currentUser, credential)
        .then(() => {
          resolve({ message: "Password set" });
          $functions().httpsCallable("onCall-utils-logEvent")({
            event: "set-password"
          });
        })
        .catch(error => reject(error));
    });
  },
  changePassword: ({ dispatch }, { password }) => {
    return new Promise((resolve, reject) => {
      dispatch("updatePassword", { password })
        .then(result => resolve(result))
        .catch(error => {
          if (error.code === "auth/requires-recent-login") {
            dispatch("reauthenticateUser")
              .then(() => {
                dispatch("updatePassword", { password })
                  .then(result => resolve(result))
                  .catch(error => reject(error));
              })
              .catch(error => reject(error));
          } else {
            reject(error);
          }
        });
    });
  },
  updatePassword: ({ state }, { password }) => {
    return new Promise((resolve, reject) => {
      updatePassword(state.currentUser, password)
        .then(() => {
          resolve({ message: "Password changed" });
          $functions().httpsCallable("onCall-utils-logEvent")({
            event: "changed-password"
          });
        })
        .catch(error => reject(error));
    });
  },
  reauthenticateUser: ({ state }) => {
    return new Promise((resolve, reject) => {
      if (!state.currentUser) return reject();
      const modal = ModalProgrammatic.open({
        parent: window.$rootVue,
        component: () => import("@views/auth/login/_reauthenticateModal"),
        canCancel: false,
        hasModalCard: true,
        width: 432,
        events: {
          cancel: () => {
            modal.close();
            return reject();
          },
          authenticated: () => {
            modal.close();
            return resolve();
          }
        }
      });
    });
  },
  reauthenticateWithCredential: ({ state }, { password }) => {
    const credential = EmailAuthProvider.credential(
      state.currentUser.email,
      password
    );
    return reauthenticateWithCredential(state.currentUser, credential);
  },
  reauthenticateWithPopup: ({ state }, { provider }) => {
    return reauthenticateWithPopup(state.currentUser, provider);
  },
  sendPasswordResetEmail: (context, { email, userId = null }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-user-resetPassword")({ email, userId })
        .then(result => resolve(result.data))
        .catch(error => reject(error.message));
    });
  },
  logout: ({ dispatch }) => {
    $functions().httpsCallable("onCall-utils-logEvent")({
      event: "logged-out"
    });
    return signOut($auth).then(() => {
      dispatch("reset", null, { root: true });
      Vue.gtm.trackEvent({
        event: "app.event",
        category: "Fixed App",
        action: "User logged out"
      });
    });
  },
  reset: async ({ dispatch, commit }) => {
    try {
      await dispatch("unobserveAll", { containing: "auth" }, { root: true });
      commit("resetState");
    } catch (error) {
      console.error(error);
    }
  }
};

const mutations = {
  currentUser: (state, { currentUser }) => {
    state.currentUser = currentUser;
  },
  setClaims: (statem, claims) => {
    state.claims = claims;
  },
  linkProvider: (state, { providerId }) => {
    state.providers[providerId].linked = true;
  },
  unlinkProvider: (state, { providerId }) => {
    state.providers[providerId].linked = false;
  },
  resetState: state => {
    Object.assign(state, defaultState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
