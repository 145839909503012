<template>
  <div class="star-rating has-text-centered">
    <b-icon
      v-for="index in 5"
      :key="index"
      :pack="index <= selected ? 'fas' : 'far'"
      :type="index <= selected ? 'is-success' : 'is-grey'"
      icon="star"
      size="is-medium"
      class="has-margin-x-25 has-cursor-pointer"
      @click.native="rate(index)"
    />
  </div>
</template>

<script>
export default {
  name: "StarRating",
  props: {
    rating: {
      type: Number,
      required: false,
      default: 0,
      validator: value => {
        return value >= 0 && value <= 5;
      }
    }
  },
  data() {
    return {
      selected: this.rating
    };
  },
  methods: {
    rate(rating) {
      this.selected = rating;
      this.$emit("change", this.selected);
    }
  }
};
</script>

<style lang="scss" scoped>
.star-rating > span {
  &:hover {
    transform: scale(1.1);
  }
}
</style>
