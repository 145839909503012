import Vue from "vue";
import { $firestore } from "@/firebase";
import _ from "lodash";
import { doc } from "firebase/firestore";

const getters = {
  term: state => ({ productId, termId }) => {
    return _.get(state, `terms.observed.${productId}.${termId}`);
  }
};

const actions = {
  observeTerm: ({ dispatch, commit }, { productId, termId }) => {
    return new Promise((resolve, reject) => {
      const productTermRef = doc(
        $firestore,
        `products/${productId}/terms/${termId}`
      );

      dispatch(
        "observe",
        {
          key: `prodcuts/${productId}/terms/${termId}`,
          ref: productTermRef,
          onSnapshot: productTerm => {
            if (!productTerm.exists()) {
              dispatch("unobserveProductTerm", { productId, termId });
              return reject();
            }
            commit("setProductTerms", {
              productId,
              terms: {
                [termId]: _.merge({ _id: productTerm.id }, productTerm.data())
              }
            });
            return resolve();
          },
          onError: error => {
            dispatch("unobserveProductTerm", { productId, termId });
            return reject(error);
          }
        },
        { root: true }
      )
        .then(resolve)
        .catch(reject);
    });
  },
  unobserveTerm: ({ commit, dispatch }, { productId, termId }) => {
    return dispatch(`unobserve`, `prodcuts/${productId}/terms/${termId}`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit(`unsetProductTerm`, { productId, termId });
      }
    });
  }
};

const mutations = {
  setProductTerms: (state, { productId, terms }) => {
    Vue.set(
      state.terms.observed,
      productId,
      _.merge({}, state.terms.observed[productId], terms)
    );
  },
  unsetProductTerm: (state, { productId, termId }) => {
    if (state.terms.observed[productId][termId]) {
      Vue.delete(state.terms.observed[productId], termId);
    }
  }
};

export { getters, actions, mutations };
