const _ = require("lodash");
const _countries = require("../data/countries.json");
const _taxRates = require("../data/tax-rates.json");
const currencies = require("../data/currencies.json");

const countries = _.merge({}, _countries, _taxRates);

const theCountries = ["NL", "GB", "US"];

const taxRate = countryCode => {
  return _.get(countries, `${countryCode}.taxRate`, 0);
};

const country = countryCode => {
  return _.get(countries, `${countryCode}.name`, countryCode);
};

export default {
  install: Vue => {
    Vue.prototype.$formatCurrency = (amount, currency) => {
      const prefix = amount < 0 ? "-" : "";
      amount = parseFloat(Math.round(Math.abs(amount) * 100) / 100).toFixed(2);
      currency = _.merge({ prefix: "", suffix: "" }, currencies[currency]);
      return `${prefix}${currency.prefix}${amount} ${currency.suffix}`.trim();
    };

    Vue.prototype.$tax = (total, jurisdiction) => {
      const pretax = total / (1 + taxRate(jurisdiction) / 100);
      return {
        total: total - pretax,
        rate: taxRate(jurisdiction),
        jurisdiction,
        country: `${theCountries.includes(jurisdiction) ? "the " : ""}${country(
          jurisdiction
        )}`
      };
    };

    Vue.prototype.$pluralise = (amount, singularForm, pluralForm) => {
      //1) Singular. (+1 or -1)
      // 2) Plural. (Everything else. incl. 0)
      if (amount === 1 || amount == -1) return `${amount}${singularForm}`;
      return `${amount}${pluralForm}`;
    };

    Vue.prototype.$humanFileSize = size => {
      if (size === 0) return "0 kB";
      var i = Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["bytes", "kB", "MB", "GB", "TB"][i]
      );
    };
  }
};
