import { $functions } from "@/firebase";

const getters = {};

const actions = {
  // eslint-disable-next-line no-empty-pattern
  recaptureScreenshot: ({}, { siteId }) => {
    return new Promise((resolve, reject) => {
      const recapture = $functions().httpsCallable("screenshot-recapture");
      recapture({ siteId })
        .then(result => {
          return resolve(result.data);
        })
        .catch(error => {
          return reject(error);
        });
    });
  }
};

const mutations = {};

export { getters, actions, mutations };
