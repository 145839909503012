import store from "@store";

export const register = {
  path: "/register",
  component: () => import("@skeleton/loggedOut/index"),
  children: [
    {
      path: "",
      name: "register",
      component: () => import("@views/auth/register"),
      meta: {
        group: "auth",
        blockForWhitelabel: true,
        breadcrumb: {
          label: "Register"
        }
      }
    }
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isAuthenticated"]()) {
      return next({ name: "sites" });
    }
    next();
  }
};

export const login = {
  path: "/login",
  component: () => import("@skeleton/loggedOut/index"),
  children: [
    {
      path: "",
      name: "login",
      component: () => import("@views/auth/login"),
      meta: {
        title: "Login",
        group: "auth",
        breadcrumb: {
          label: "Login"
        }
      }
    }
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isAuthenticated"]()) {
      return next({ name: "sites" });
    }
    next();
  }
};

export const onboarding = {
  path: "/join",
  component: () => import("@skeleton/loggedOut/index"),
  children: [
    {
      path: "",
      name: "onboarding",
      component: () => import("@views/auth/onboarding"),
      meta: {
        blockForWhitelabel: true,
        blockNotifications: true,
        group: "auth",
        title: "Join",
        breadcrumb: {
          label: "Join"
        }
      }
    }
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["user/isResellerUser"]()) {
      return next({ name: "sites" });
    }
    next();
  }
};

export const resetpassword = {
  path: "/resetpassword/:hash",
  component: () => import("@skeleton/loggedOut/index"),
  children: [
    {
      path: "",
      name: "resetpassword",
      component: () => import("@views/auth/resetpassword"),
      meta: {
        group: "auth",
        breadcrumb: {
          label: "Reset password"
        }
      }
    }
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isAuthenticated"]()) {
      return next({ name: "sites" });
    }
    next();
  }
};

export const logout = {
  path: "/logout",
  name: "logout",
  meta: {
    group: "auth",
    requiresAuth: true
  },
  beforeEnter: () => {
    store.dispatch("auth/logout");
  }
};
