var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.filterable)?_c('b-field',{staticClass:"has-margin-bottom-100",attrs:{"grouped":""}},[_c('b-input',{attrs:{"expanded":"","disabled":_vm.disabled,"placeholder":_vm.placeholder},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-select',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}},[_c('option',{attrs:{"value":""}},[_vm._v("All ("+_vm._s(_vm.allIds.length)+")")]),_c('option',{attrs:{"value":"selected"}},[_vm._v("Selected ("+_vm._s(_vm.selectedIds.length)+")")]),_c('option',{attrs:{"value":"unselected"}},[_vm._v("Unselected ("+_vm._s(_vm.unselectedIds.length)+")")])])],1):_vm._e(),(!_vm.filteredData.length)?_c('p',{staticClass:"has-padding-100 has-text-grey-light has-text-centered"},[_vm._v(" There are no results "+_vm._s(_vm.view || _vm.filter ? "matching your filter(s)" : "to show")+". ")]):_vm._e(),_vm._l((_vm.filteredData),function({
      label,
      id,
      status,
      isDisabled = false,
      isDisabledWhy = null
    }){return _c('apart',{key:`row-${id}`,staticClass:"has-cursor-pointer",class:{ 'has-text-grey-light': isDisabled },nativeOn:{"click":function($event){return _vm.toggle(id, isDisabled, !!!_vm.selected[id])}}},[_c('p',[_vm._v(" "+_vm._s(label)+" "),(status)?_c('status-label',{attrs:{"status":status}}):_vm._e()],1),(isDisabled)?_c('p',{staticClass:"is-size-7"},[_vm._v(_vm._s(isDisabledWhy))]):_c('b-switch',{attrs:{"value":!!_vm.selected[id],"v-model":_vm.selected[id],"disabled":_vm.disabled},on:{"input":function($event){return _vm.toggle(id, isDisabled, $event)}},nativeOn:{"click":function($event){$event.stopPropagation();}}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }