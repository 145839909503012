import moment from "moment";
import { $firestore } from "@/firebase";
import { doc, increment, writeBatch } from "firebase/firestore";

const defaultState = () => {
  return {};
};

const state = defaultState();

const actions = {
  updateStat: ({ rootGetters }, { batch, statName }) => {
    const internalBatch = batch || writeBatch($firestore);

    const date = moment.utc();
    const y = date.format("YYYY"),
      m = date.format("MM"),
      d = date.format("DD"),
      h = date.format("HH");
    const userId = rootGetters["auth/userId"]();
    const yearStat = doc($firestore, `agents/${userId}/stats/${y}`);
    const monthStat = doc($firestore, `agents/${userId}/stats/${y}${m}`);
    const dayStat = doc($firestore, `agents/${userId}/stats/${y}${m}${d}`);

    const monthStatPayload = {
      [statName]: {
        [d]: increment(1)
      }
    };

    const yearStatPayload = {
      [statName]: {
        [m]: increment(1)
      }
    };

    const dayStatPayload = {
      [statName]: {
        [h]: increment(1)
      }
    };

    internalBatch.set(yearStat, yearStatPayload, { merge: true });
    internalBatch.set(monthStat, monthStatPayload, { merge: true });
    internalBatch.set(dayStat, dayStatPayload, { merge: true });

    if (!batch) {
      return internalBatch.commit();
    }
    return Promise.resolve();
  }
};

export default {
  namespaced: true,
  state,
  actions
};
