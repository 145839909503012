<template>
  <div class="vue-pincode-input">
    <div
      v-for="(char, index) in chars"
      :key="char.key"
      class="vue-pincode"
      :class="{
        split: splitAtPosition != -1 && index === splitAtPosition,
        focused: focusedIndex === index
      }"
      @click="setFocusedCharIndex(index)"
    >
      <component
        :is="disabled ? 'span' : 'input'"
        ref="pins"
        :value="$_.get(char.value, `[0]`, emptyChar)"
        class="pin-char-input"
        type="text"
        pattern="\d*"
        inputmode="numeric"
        @input="onInput({ event: $event, index, value: $event.data })"
        @focus="setFocusedCharIndex(index)"
        @keydown.delete="onDelete(index, $event)"
        @keydown.left="setFocusedCharIndex(index - 1)"
        @keydown.right="setFocusedCharIndex(index + 1)"
        @paste.prevent="onPaste"
        @keydown.up="
          onInput({
            index,
            next: false,
            value:
              char.value === emptyChar
                ? minChar
                : String.fromCharCode(char.value.charCodeAt(0) + 1)
          })
        "
        @keydown.down="
          onInput({
            index,
            next: false,
            value:
              char.value === emptyChar
                ? maxChar
                : String.fromCharCode(char.value.charCodeAt(0) - 1)
          })
        "
      >
        {{ disabled ? $_.trim(char.value) : null }}
      </component>
    </div>
  </div>
</template>
<script>
export default {
  name: "PinInput",
  props: {
    value: {
      default: "",
      type: String
    },
    length: {
      type: Number,
      default: 6
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autoFocusNext: {
      type: Boolean,
      default: true
    },
    focusFirstAtInit: {
      type: Boolean,
      default: true
    },
    splitAtPosition: {
      type: Number,
      default: 3
    },
    focusable: {
      type: Boolean,
      default: true
    },
    emptyChar: {
      type: String,
      default: ""
    },
    minChar: {
      type: String,
      default: "0"
    },
    maxChar: {
      type: String,
      default: "9"
    },
    validationRegex: {
      type: RegExp,
      default: () => /^\d+$/
    }
  },
  data() {
    return {
      chars: [],
      focusedIndex: -1
    };
  },
  computed: {
    currentValue() {
      return this.chars.map(i => i.value).join("");
    },
    isValid() {
      return (
        this.currentValue.length === this.length &&
        !this.$_.some(
          this.currentValue,
          char => !char || char === this.emptyChar
        )
      );
    }
  },
  watch: {
    value(newVal) {
      if (newVal !== this.currentValue) this.init();
    },
    isValid() {
      this.$emit("valid", this.isValid);
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.reset();
      for (let index = 0; index < this.length; index++) {
        this.setupLetterObject(
          index,
          this.$_.get(this.value, `[${index}]`, this.emptyChar)
        );
      }

      if (this.focusFirstAtInit) this.setFocusedCharIndex(0);
    },
    onInput({ index, value, next = true }) {
      if (this.disabled) return;

      // Validate with regex
      const match = this.validationRegex.exec(value);
      if (this.$_.isEmpty(match)) {
        const charBeforeMinChar = String.fromCharCode(
          this.minChar.charCodeAt(0) - 1
        );

        const charAfterMaxChar = String.fromCharCode(
          this.maxChar.charCodeAt(0) + 1
        );

        // Loop values. If maxChar is 9 at UP arrow, go back to minChar (0)
        if (charBeforeMinChar === value) this.chars[index].value = this.maxChar;
        else if (charAfterMaxChar === value)
          this.chars[index].value = this.minChar;
        else this.chars[index].value = this.emptyChar;

        return (this.$refs.pins[index].value = this.chars[index].value);
      }

      this.$refs.pins[index].value = value;
      this.chars[index].value = value || this.emptyChar;
      if (this.autoFocusNext && next)
        this.setFocusedCharIndex(this.focusedIndex + 1);
      this.$emit("input", this.currentValue);
    },
    setupLetterObject(index, value) {
      this.chars.push({ index, value });
    },
    setFocusedCharIndex(newIndex) {
      if (!this.focusable || newIndex < 0 || newIndex >= this.length) {
        return;
      }
      this.focusedIndex = newIndex;
      this.$nextTick(() => {
        if (
          this.$refs.pins &&
          document.activeElement !== this.$refs.pins[newIndex]
        )
          this.$refs.pins[newIndex].focus();
      });
    },
    reset() {
      this.chars = [];
    },
    onDelete(index) {
      if (this.disabled) return;
      this.chars[index].value = this.emptyChar;
      this.$refs.pins[index].value = this.emptyChar;
      this.setFocusedCharIndex(this.focusedIndex - 1);
      this.$emit("input", this.currentValue);
    },
    onPaste(e) {
      if (this.disabled) return;
      let pastedText = e.clipboardData.getData("text");

      // Handle if pasted data is shorter or longer
      pastedText = this.$_.slice(
        this.$_.padEnd(pastedText, this.length, this.emptyChar),
        0,
        this.length
      ).join("");

      // Add all chars and fill with empty char is needed
      for (let index = 0; index < this.length; index++) {
        this.onInput({
          index,
          value: this.$_.get(pastedText, `[${index}]`, this.emptyChar),
          next: false
        });
      }
      this.$emit("input", this.currentValue);
      this.setFocusedCharIndex(0);
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";

.vue-pincode-input {
  display: inline-flex;
}
.vue-pincode {
  display: flex;
  margin: 0.125rem;
  text-align: center;
  border-radius: $radius;
  background: #f5f5f5;
  width: 2.75rem;
  height: 3.5rem;
  cursor: pointer;
  &.focused {
    box-shadow: inset 0 0 0 2px $primary;
  }

  &.split {
    position: relative;
    margin-left: 1rem;
    &:before {
      content: "";
      position: absolute;
      width: 0.6rem;
      height: 2px;
      top: 50%;
      left: -0.875em;
      margin-top: -1px;
      background: $grey-lighter;
    }
  }

  .pin-char-input {
    font-family: $family-custom;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
    width: 1em;
    text-align: center;
    background: transparent;
    outline: none;
    border: none;
    margin: auto;
    user-select: none;
    color: $grey;
    cursor: pointer;
  }
}
</style>
