import Vue from "vue";
import { $functions } from "@/firebase";
import _ from "lodash";

const getters = {};

const actions = {
  orderBasket: (
    context,
    {
      userId,
      siteId,
      productId,
      productTermId,
      paymentMethodId,
      couponId,
      currency
    }
  ) => {
    return new Promise((resolve, reject) => {
      const order = $functions().httpsCallable(`onCall-basket-order`);
      order({
        userId,
        siteId,
        productId,
        productTermId,
        paymentMethodId,
        couponId,
        currency
      })
        .then(result => {
          const order = result.data.data;
          const regex = /^(.+)\s\(.+\)$/i;
          Vue.gtm.trackEvent({
            event: "app.transaction",
            currency: `${order.currency}`,
            transactionId: `${order.orderNum}`,
            transactionAffiliation: `my.fixed.net`,
            transactionTotal: `${order.total}`,
            transactionTax: `${order.tax.total}`,
            transactionProducts: _(order.items)
              .filter(item => item.total >= 0)
              .map(item => ({
                sku: `${productTermId}`,
                name: _.get(regex.exec(item.description), 1, item.description),
                price: `${item.total}`,
                quantity: `1`,
                currency: `${order.currency}`
              }))
              .value()
          });
          return resolve(result.data);
        })
        .catch(error => {
          return reject(error.message);
        });
    });
  },
  // eslint-disable-next-line no-empty-pattern
  renewContract: ({}, { contractId, paymentMethodId }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable(`onCall-contract-renew`)({
          contractId,
          paymentMethodId
        })
        .then(result => {
          resolve(result.data.message);
        })
        .catch(error => {
          reject(error.message);
        });
    });
  },
  // eslint-disable-next-line no-empty-pattern
  cancelContract: (
    context,
    { contractId, reason, terminate, refundType, amount }
  ) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-contract-cancel")({
          contractId,
          reason,
          terminate,
          refundType,
          amount
        })
        .then(result => {
          resolve(result.data.message);
        })
        .catch(error => {
          reject(error.message);
        });
    });
  },
  // eslint-disable-next-line no-empty-pattern
  reactivateContract: ({}, { contractId }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable(`onCall-contract-reactivate`)({
          contractId
        })
        .then(result => {
          return resolve(result.data.message);
        })
        .catch(error => {
          return reject(error.message);
        });
    });
  },
  createManualInvoice: (
    context,
    { siteId = null, paymentMethodId, invoice }
  ) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable(`onCall-invoice-create`)({
          siteId,
          paymentMethodId,
          invoice
        })
        .then(result => {
          return resolve(result.data.message);
        })
        .catch(error => {
          return reject(error.message);
        });
    });
  },
  calculateBasket: (
    context,
    {
      userId = "",
      siteId,
      fqdn,
      productId,
      productTermId,
      paymentMethodId,
      couponId,
      contractId,
      currency
    }
  ) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable(`onCall-basket-calculate`)({
          userId,
          siteId,
          fqdn,
          productId,
          productTermId,
          paymentMethodId,
          couponId,
          contractId,
          currency
        })
        .then(result => {
          return resolve(result.data);
        })
        .catch(error => {
          return reject(error);
        });
    });
  }
};

const mutations = {};

export { getters, actions, mutations };
