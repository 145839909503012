import store from "@store";

export default {
  path: "/reports",
  component: () =>
    import(/* webpackChunkName: "reports" */ "@skeleton/reports/index"),
  beforeEnter: (to, from, next) => {
    store.commit("ui/routing", true);
    next();
  },
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: "site/:siteId/:reportId",
      component: () =>
        import(
          /* webpackChunkName: "site-reports" */ "@views/sites/site/reports/report"
        ),
      meta: {
        title: "Monthly report"
      }
    }
  ]
};
