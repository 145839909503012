<template>
  <div class="vessels">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Vessels",
  props: {
    value: {
      type: Number,
      required: false,
      default: -1
    }
  },
  data() {
    return {
      vessels: [],
      activeIndex: this.value
    };
  },
  computed: {
    activeVessel() {
      return this.$_.get(this.vessels, `[${this.activeIndex}]`, null);
    }
  },
  watch: {
    value(value) {
      if (value !== this.activeIndex) {
        this.changeVessel(value);
      }
    }
  },
  methods: {
    vesselClick(vessel) {
      const newIndex = this.$_.indexOf(this.vessels, vessel);
      this.changeVessel(newIndex);
      if (typeof this.value !== "undefined") {
        this.$emit("input", newIndex);
      }
    },
    changeVessel(newIndex) {
      if (this.activeIndex === newIndex) {
        newIndex = -1;
      }
      this.$set(this, "activeIndex", newIndex);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.vessels {
  /deep/ .vessel {
    border-radius: 0;
    transition-property: background-color, margin;
    transition-duration: 0.16s, 0.24s;
    & + .vessel {
      margin-top: -1px;
    }
    &.is-active {
      &:not(:first-child) {
        margin-top: 0.75rem;
      }
      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }
    }
    &:first-of-type,
    &.is-active,
    &.is-after-active {
      border-top-left-radius: $radius-large;
      border-top-right-radius: $radius-large;
    }
    &:last-of-type,
    &.is-active,
    &.is-before-active {
      border-bottom-left-radius: $radius-large;
      border-bottom-right-radius: $radius-large;
    }
  }
}
</style>
