<template>
  <div class="card">
    <div v-if="imageDefined" :class="imageClasses" class="card-image">
      <slot name="image" />
    </div>

    <div v-if="headerDefined" :class="headerClasses" class="card-header">
      <slot name="header" />
    </div>

    <div v-if="bodyDefined" :class="bodyClasses" class="card-content">
      <slot name="body" />
    </div>

    <div v-if="footerDefined" :class="footerClasses" class="card-footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    imageClasses: {
      type: [String, Object],
      default: ""
    },
    headerClasses: {
      type: [String, Object],
      default: ""
    },
    bodyClasses: {
      type: [String, Object],
      default: ""
    },
    footerClasses: {
      type: [String, Object],
      default: ""
    }
  },
  computed: {
    imageDefined() {
      return !!this.$slots["image"];
    },
    headerDefined() {
      return !!this.$slots["header"];
    },
    bodyDefined() {
      return !!this.$slots["body"];
    },
    footerDefined() {
      return !!this.$slots["footer"];
    }
  }
};
</script>

<style lang="scss">
.card {
  border-radius: 0.4em;
}
.card > div:first-child {
  border-radius: 0.4em 0.4em 0 0;
}
.card > div:last-child {
  border-radius: 0 0 0.4em 0.4em;
}
.card-header-title,
.card-header-icon {
  padding: 1rem !important;
}
</style>
