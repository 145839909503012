import { ModalProgrammatic } from "buefy/dist/components/modal";
import { ConfirmProgrammatic } from "@components/confirm";
import { $functions } from "@/firebase";

const defaultState = () => {
  return {
    observed: {}
  };
};

const state = defaultState();

const getters = {
  data: state => id => {
    return state.observed[id] || null;
  }
};

const actions = {
  addPassword: (context, { password }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-vault-addPassword")({ password })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },
  removePassword: (context, { passwordId }) => {
    return new Promise((resolve, reject) => {
      const confirm = ConfirmProgrammatic.open({
        props: {
          autoClose: false,
          action: `Remove password`,
          message: `Are you sure you want to remove this password from vault?`
        },
        canCancel: ["escape", "outside"],
        events: {
          confirmed: () => {
            $functions()
              .httpsCallable("onCall-vault-deletePassword")({ passwordId })
              .then(result => resolve(result.data))
              .catch(reject)
              .finally(() => confirm.close());
          }
        }
      });
    });
  },
  updatePassword: (context, { passwordId, password }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-vault-updatePassword")({ passwordId, password })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },
  decodeValue: (context, { passwordId, value }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-vault-decodePassword")({ passwordId, value })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },
  openVaultPasswordModal: (context, props) => {
    return new Promise(resolve => {
      const modal = ModalProgrammatic.open({
        parent: window.$rootVue,
        component: () => import("@shared/vault/_vaultPasswordModal.vue"),
        hasModalCard: true,
        width: 640,
        props,
        canCancel: [],
        events: {
          close: () => {
            return resolve("close");
          },
          success: () => {
            modal.close();
            return resolve("success");
          }
        }
      });
    });
  },
  reset: async ({ dispatch, commit }) => {
    try {
      await dispatch("unobserveAll", { containing: "vault" }, { root: true });
      commit("resetState");
    } catch (error) {
      console.error(error);
    }
  }
};

const mutations = {
  resetState: state => {
    Object.assign(state, defaultState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
