<template>
  <b-tag :type="computedStatus.type">{{ computedStatus.label }}</b-tag>
</template>

<script>
export default {
  name: "StatusLabel",
  props: {
    status: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      statuses: {
        "-1": {
          label: "Lapsed",
          type: "is-dark"
        },
        "0": {
          label: "Cancelled",
          type: "is-grey"
        },
        "1": {
          label: "Active",
          type: "is-success"
        },
        "2": {
          label: "Overdue",
          type: "is-danger"
        },
        active: {
          label: "Active",
          type: "is-success"
        },
        inactive: {
          label: "Inactive",
          type: "is-warning"
        },
        discontinued: {
          label: "Discontinued",
          type: "is-warning"
        },
        admin: {
          label: "Admin",
          type: "is-success"
        },
        reseller: {
          label: "Reseller",
          type: "is-warning"
        },
        agent: {
          label: "Agent",
          type: "is-dark"
        },
        "backup-deleted": {
          label: "Marked for deletion",
          type: "is-danger"
        },
        cancelled: {
          label: "Cancelled",
          type: "is-grey"
        },
        client: {
          label: "Client",
          type: "is-grey"
        },
        delegate: {
          label: "Delegate",
          type: "is-grey"
        },
        "reseller-client": {
          label: "Client",
          type: "is-warning"
        },
        "client-replied": {
          label: "Client replied",
          type: "is-warning"
        },
        complete: {
          label: "Complete",
          type: "is-grey"
        },
        deleted: {
          label: "Deleted",
          type: "is-danger"
        },
        due: {
          label: "Due",
          type: "is-danger"
        },
        error: {
          label: "Error",
          type: "is-danger"
        },
        finished: {
          label: "Ok",
          type: "is-success"
        },
        inprogress: {
          label: "In progress",
          type: "is-success"
        },
        "in-progress": {
          label: "In progress",
          type: "is-info"
        },
        "malware-and-security": {
          label: "Malware & Security",
          type: "is-danger"
        },
        new: {
          label: "New",
          type: "is-success"
        },
        none: {
          label: "Pending",
          type: "is-warning"
        },
        "on-hold": {
          label: "On hold",
          type: "is-dark"
        },
        paid: {
          label: "Paid",
          type: "is-success"
        },
        refund: {
          label: "Refund",
          type: "is-warning"
        },
        "staff-only": {
          label: "Staff Only",
          type: "is-warning"
        },
        used: {
          label: "Used",
          type: "is-success"
        },
        unused: {
          label: "Unused",
          type: "is-dark"
        },
        "waiting-for-reply": {
          label: "Waiting for reply",
          type: "is-dark"
        },
        "website-tweaks": {
          label: "Website Tweaks",
          type: "is-purple"
        },
        scheduled: {
          label: "Scheduled",
          type: "is-warning"
        },
        queued: {
          label: "Queued",
          type: "is-dark"
        },
        subscribed: {
          label: "Subscribed",
          type: "is-primary"
        },
        unsubscribed: {
          label: "Unsubscribed",
          type: "is-dark"
        },
        disabled: {
          label: "Disabled",
          type: "is-dark"
        },
        post: {
          label: "POST",
          type: "is-dark"
        },
        get: {
          label: "GET",
          type: "is-primary"
        },
        patch: {
          label: "PATCH",
          type: "is-warning"
        },
        put: {
          label: "PUT",
          type: "is-warning"
        }
      }
    };
  },
  computed: {
    computedStatus() {
      return (
        this.statuses[this.status] || {
          label: this.status,
          type: this.type || "is-dark"
        }
      );
    }
  }
};
</script>
