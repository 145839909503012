<template>
  <div>
    <hr class="has-margin-bottom-100" />

    <div class="is-clearfix">
      <button
        v-if="back"
        type="button"
        class="button is-grey is-outlined is-pulled-left"
        tabindex="-1"
        @click="$emit('back')"
      >
        <b-icon icon="arrow-left" size="is-small" />
        <span>Back</span>
      </button>

      <button
        :class="{ 'is-loading': processing }"
        :disabled="!enabled || processing"
        type="submit"
        class="button is-success is-pulled-right"
        @click="$emit('next')"
      >
        Continue
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepControls",
  props: {
    back: {
      type: Boolean,
      default: true
    },
    continue: {
      type: Boolean,
      default: true
    },
    enabled: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    }
  }
};
</script>
