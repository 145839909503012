import { $functions, $firestore } from "@/firebase";
import Vue from "vue";
import _ from "lodash";
import { ModalProgrammatic as $modal } from "buefy/dist/components/modal";
import {
  collection,
  query,
  where,
  serverTimestamp,
  updateDoc
} from "firebase/firestore";

const defaultState = () => {
  return {
    observed: {}
  };
};

const state = defaultState();

const getters = {
  tokens: state => ({ scope }) => {
    if (!state.observed) return false;
    return _.get(state.observed, scope, {});
  }
};

const actions = {
  observeSiteTokens: ({ dispatch }, { siteId }) => {
    const scope = `$site_${siteId}`;
    const key = `sites/${siteId}/tokens`;
    const ref = query(
      collection($firestore, key),
      where("isRevoked", "==", false)
    );
    return dispatch("observeTokens", { key, ref, scope });
  },
  observeUserTokens: ({ dispatch }, { userId }) => {
    const scope = `$user_${userId}`;
    const key = `users/${userId}/tokens`;
    const ref = query(
      collection($firestore, key),
      where("isRevoked", "==", false)
    );
    return dispatch("observeTokens", { key, ref, scope });
  },
  observeTokens: ({ commit, dispatch }, { key, ref, scope }) => {
    return dispatch(
      "observe",
      {
        key,
        ref,
        onSnapshot: snapshot => {
          if (!snapshot.metadata.hasPendingWrites) {
            _.each(snapshot.docChanges(), change => {
              const token = change.doc;
              if (change.type === "removed") {
                commit("unsetToken", { scope, tokenId: token.id });
              } else {
                commit("setToken", {
                  scope,
                  token: _.merge(
                    { _id: token.id, _ref: token.ref },
                    token.data()
                  )
                });
              }
            });
          }
        }
      },
      { root: true }
    );
  },
  unobserveSiteTokens: ({ dispatch }, { siteId }) => {
    const scope = `$site_${siteId}`;
    const key = `sites/${siteId}/tokens`;
    return dispatch("unobserveTokens", { scope, key });
  },
  unobserveUserTokens: ({ dispatch }, { userId }) => {
    const scope = `$user_${userId}`;
    const key = `users/${userId}/tokens`;
    return dispatch("unobserveTokens", { scope, key });
  },
  unobserveTokens: ({ commit, dispatch }, { key, scope }) => {
    return dispatch(`unobserve`, key, { root: true }).then(unsubscribed => {
      if (unsubscribed) {
        commit("unsetTokens", { scope });
      }
    });
  },
  openAddTokenModal(context, token) {
    $modal.open({
      component: () => import("@shared/tokens/_tokenModal"),
      parent: window.$rootVue,
      width: 520,
      hasModalCard: true,
      canCancel: ["button"],
      props: {
        token
      }
    });
  },
  addToken: (context, payload) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-token-add")(payload)
        .then(result => resolve(result.data))
        .catch(error => reject(error.message));
    });
  },
  revokeToken: (context, tokenRef) => {
    return updateDoc(tokenRef, {
      isRevoked: true,
      dateRevoked: serverTimestamp()
    });
  }
};

const mutations = {
  setToken: (state, { scope, token }) => {
    const tokens = _.merge({}, _.get(state.observed, scope, {}), {
      [token._id]: token
    });
    Vue.set(state.observed, scope, tokens);
  },
  unsetToken: (state, { scope, tokenId }) => {
    const tokens = _.omit(_.get(state.observed, scope, {}), tokenId);
    Vue.set(state.observed, scope, tokens);
  },
  unsetTokens: (state, { scope }) => {
    if (state.observed[scope]) {
      Vue.delete(state.observed, scope);
    }
  },
  resetState: state => {
    Object.assign(state, defaultState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
