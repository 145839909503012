import { $firestore } from "@/firebase";
import Vue from "vue";
import _ from "lodash";
import {
  doc,
  collection,
  query,
  where,
  orderBy,
  getDocs
} from "firebase/firestore";

const getters = {
  siteChanges: state => siteId => {
    return _.get(state, `changes.observed.${siteId}`, null);
  }
};

const actions = {
  getChangesPaginated: (
    { dispatch, commit },
    { siteId, dateFrom, limit = 10, cursor }
  ) => {
    return new Promise((resolve, reject) => {
      let changesRef = collection($firestore, `sites/${siteId}/changes`);

      if (dateFrom)
        changesRef = query(changesRef, where("timestamp", ">=", dateFrom));

      changesRef = query(changesRef, orderBy("timestamp", "desc"));

      return dispatch(
        "pagination/getPaginated",
        {
          ref: changesRef,
          limit: limit,
          cursor
        },
        { root: true }
      )
        .then(({ results, complete }) => {
          const changesReduced = _(results)
            .filter(i => i.type !== "removed")
            .reduce((result, change) => {
              const data = change.data();
              result[change.id] = {
                ...data,
                _id: change.id,
                timestamp: data.timestamp.toDate()
              };
              return result;
            }, {});

          commit("setChanges", {
            siteId,
            changes: changesReduced
          });
          resolve({ results, complete });
        })
        .catch(reject);
    });
  },
  getChanges: ({ commit }, { siteId, dateFrom, dateUntil }) => {
    let changesRef = query(
      collection($firestore, `sites/${siteId}/changes`),
      where("initialState", "==", false)
    );

    if (dateFrom)
      changesRef = query(changesRef, where("timestamp", ">=", dateFrom));
    if (dateUntil)
      changesRef = query(changesRef, where("timestamp", "<", dateUntil));

    return getDocs(changesRef).then(snap => {
      const changes = _(snap.docs).reduce((result, firebaseChange) => {
        const data = firebaseChange.data();
        result[firebaseChange.id] = {
          ...data,
          _id: firebaseChange.id,
          timestamp: data.timestamp.toDate()
        };
        return result;
      }, {});

      commit("setChanges", {
        siteId,
        changes
      });
    });
  },
  observeChanges: ({ commit, dispatch }, { siteId, dateFrom, dateUntil }) => {
    let changesRef = query(
      collection($firestore, `sites/${siteId}/changes`),
      where("initialState", "==", false)
    );

    if (dateFrom)
      changesRef = query(changesRef, where("timestamp", ">=", dateFrom));
    if (dateUntil)
      changesRef = query(changesRef, where("timestamp", "<", dateUntil));

    return dispatch(
      "observe",
      {
        key: `sites/${siteId}/changes`,
        ref: changesRef,
        onSnapshot: snapshot => {
          // React to server changes
          if (!snapshot.metadata.hasPendingWrites) {
            const changesReduced = _(snapshot.docChanges())
              .filter(i => i.type !== "removed")
              .reduce((result, firebaseChange) => {
                const data = firebaseChange.doc.data();
                result[firebaseChange.doc.id] = {
                  ...data,
                  _id: firebaseChange.doc.id,
                  timestamp: data.timestamp.toDate()
                };
                return result;
              }, {});

            commit("setChanges", {
              siteId,
              changes: changesReduced
            });

            _.each(
              snapshot.docChanges().filter(i => i.type === "remove"),
              firebaseChange => {
                commit("unsetChange", {
                  siteId,
                  changeId: firebaseChange.doc.id
                });
              }
            );
          }
        }
      },
      { root: true }
    );
  },
  observeChange: ({ commit, dispatch }, { siteId, changeId }) => {
    return new Promise((resolve, reject) => {
      const siteRef = doc($firestore, `sites/${siteId}/changes`, changeId);
      dispatch(
        "observe",
        {
          key: `sites/${siteId}/change/${changeId}`,
          ref: siteRef,
          onSnapshot: site => {
            if (site.metadata.fromCache) return resolve();
            if (!site.exists()) {
              dispatch("unobserveSite", { siteId: siteId });
              return reject();
            }
            commit("setSite", { siteId: siteId, siteData: site.data() });
            resolve();
          },
          onError: error => {
            dispatch("unobserveSite", { siteId: siteId });
            return reject(error);
          }
        },
        { root: true }
      );
    });
  },
  unobserveChange: ({ commit, dispatch, getters }, { siteId, changeId }) => {
    return dispatch(`sites/${siteId}/change/${changeId}`, { root: true }).then(
      remove => {
        if (remove) {
          const siteRole = getters["site/userRole"](siteId);
          if (["admin", "agent", "unknown"].includes(siteRole)) {
            commit("unsetSite", { siteId: siteId });
          }
        }
      }
    );
  },
  unobserveChanges: ({ commit, dispatch }, { siteId }) => {
    return dispatch(`unobserve`, `sites/${siteId}/changes`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit("unsetChanges", { siteId });
      }
    });
  }
};

const mutations = {
  setChange: (state, { siteId, changeId, changeData }) => {
    if (!state.changes.observed[siteId])
      Vue.set(state.changes.observed, siteId, {});

    Vue.set(
      state.changes.observed[siteId],
      changeId,
      _.merge(
        {
          _id: changeId
        },
        changeData
      )
    );
  },
  setChanges: (state, { siteId, changes }) => {
    Vue.set(
      state.changes.observed,
      siteId,
      _.merge({}, state.changes.observed[siteId], changes)
    );
  },
  unsetChange: (state, { siteId, changeId }) => {
    if (state.changes.observed[siteId][changeId]) {
      Vue.delete(state.changes.observed[siteId], changeId);
    }
  },
  unsetChanges: (state, { siteId }) => {
    if (state.changes.observed[siteId]) {
      Vue.set(state.changes.observed, siteId, {});
    }
  }
};

export { getters, actions, mutations };
