const defaultState = () => {
  return {
    observed: {},
    notes: { observed: {} },
    secrets: { observed: {} },
    delegates: { observed: {} },
    reports: { observed: {} },
    roles: { observed: {} },
    changes: { observed: {} },
    observingUserSites: false
  };
};

const state = defaultState();

const getters = Object.assign(
  require("./core").getters,
  require("./backups").getters,
  require("./delegates").getters,
  require("./reports").getters,
  require("./secrets").getters,
  require("./notes").getters,
  require("./screenshot").getters,
  require("./roles").getters,
  require("./changes").getters
);

const actions = Object.assign(
  require("./core").actions,
  require("./backups").actions,
  require("./delegates").actions,
  require("./reports").actions,
  require("./secrets").actions,
  require("./notes").actions,
  require("./screenshot").actions,
  require("./roles").actions,
  require("./changes").actions,
  {
    reset: async ({ dispatch, commit }) => {
      try {
        await dispatch("unobserveAll", { containing: "sites" }, { root: true });
        commit("resetState");
      } catch (error) {
        console.error(error);
      }
    }
  }
);

const mutations = Object.assign(
  require("./core").mutations,
  require("./backups").mutations,
  require("./delegates").mutations,
  require("./reports").mutations,
  require("./secrets").mutations,
  require("./notes").mutations,
  require("./screenshot").mutations,
  require("./roles").mutations,
  require("./changes").mutations,
  {
    resetState: state => {
      Object.assign(state, defaultState());
    }
  }
);

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
