import Vue from "vue";
import { $functions, $firestore } from "@/firebase";
import { sha256 } from "js-sha256";
import _ from "lodash";
import { doc, collection, query, where } from "firebase/firestore";

const defaultState = () => {
  return {
    observed: {}
  };
};

const state = defaultState();

const getters = {
  reseller: (state, getters, rootState) => (
    resellerId = rootState.auth.currentUser.uid
  ) => {
    if (!state.observed[resellerId]) return false;
    return state.observed[resellerId] || {};
  },
  users: state => resellerId => {
    return _.get(state, `observed.${resellerId}._users`, {});
  },
  name: state => resellerId => {
    return _.get(
      state,
      `observed.${resellerId}.name`,
      window.location.hostname
    );
  }
};

const actions = {
  observeReseller: (
    { commit, dispatch },
    { resellerId, checkExistance = true }
  ) => {
    return new Promise((resolve, reject) => {
      const resellerRef = doc($firestore, "resellers", resellerId);
      dispatch(
        "observe",
        {
          key: `resellers/${resellerId}`,
          ref: resellerRef,
          onSnapshot: reseller => {
            if (checkExistance && !reseller.exists()) {
              dispatch("unobserveReseller", { resellerId: resellerId });
              return reject();
            }
            commit("setReseller", {
              resellerId: reseller.id,
              resellerData: reseller.data()
            });
            resolve(reseller);
          },
          onError: error => {
            dispatch("unobserveReseller", { resellerId: resellerId });
            return reject(error);
          }
        },
        { root: true }
      );
    });
  },
  unobserveReseller: ({ commit, dispatch }, { resellerId }) => {
    return new Promise(resolve => {
      dispatch(`unobserve`, `resellers/${resellerId}`, { root: true }).then(
        remove => {
          if (remove) {
            commit("unsetReseller", { resellerId: resellerId });
          }
        }
      );
      resolve();
    });
  },
  observeUsers: ({ commit, dispatch }, { resellerId }) => {
    let usersRef = query(
      collection($firestore, "users"),
      where("resellerId", "==", resellerId)
    );
    return dispatch(
      "observe",
      {
        key: `resellers/${resellerId}/users`,
        ref: usersRef,
        onSnapshot: snapshot => {
          _.each(snapshot.docChanges(), change => {
            const user = change.doc;
            if (change.type === "removed") {
              commit("unsetUser", {
                resellerId,
                noteId: user.id
              });
            } else {
              commit("setUsers", {
                resellerId,
                users: {
                  [user.id]: _.merge({ _id: user.id }, user.data())
                }
              });
            }
          });
        }
      },
      { root: true }
    );
  },
  unobserveUsers: ({ commit, dispatch }, { resellerId }) => {
    return dispatch(`unobserve`, `resellers/${resellerId}/users`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit("unsetUsers", { resellerId });
      }
    });
  },
  // eslint-disable-next-line no-empty-pattern
  makeReseller: ({}, { userId, name = null, primaryColor = null }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("reseller-makeReseller")({ userId, name, primaryColor })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },
  // eslint-disable-next-line no-empty-pattern
  generateNewPassword: ({}, { userId }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("reseller-generateUserPassword")({ userId })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },
  // eslint-disable-next-line no-empty-pattern
  changePassword: ({}, { userId, password, oldPassword }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("reseller-changePassword")({
          userId,
          password: sha256(password),
          oldPassword: oldPassword ? sha256(oldPassword) : null
        })
        .then(result => {
          return resolve(result.data);
        })
        .catch(error => {
          return reject(error);
        });
    });
  },
  // eslint-disable-next-line no-empty-pattern
  generateResetHash: ({}, { userId }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("reseller-generateResetHash")({ userId })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },
  // eslint-disable-next-line no-empty-pattern
  resetPassword: ({}, { password, hash, autologin }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("reseller-resetPassword")({
          password: sha256(password),
          hash,
          autologin
        })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },
  // eslint-disable-next-line no-empty-pattern
  updateClientsOfSite: ({}, { siteId, users, resellerId = null }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("reseller-updateClientsOfSite")({
          siteId,
          users,
          resellerId
        })
        .then(result => {
          return resolve(result.data);
        })
        .catch(error => {
          return reject(error);
        });
    });
  },
  // eslint-disable-next-line no-empty-pattern
  updateSitesOfClient: ({}, { sites, userId, resellerId = null }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("reseller-updateSitesOfClient")({
          sites,
          userId,
          resellerId
        })
        .then(result => {
          return resolve(result.data);
        })
        .catch(error => {
          return reject(error);
        });
    });
  },
  // eslint-disable-next-line no-empty-pattern
  getTasksReport: ({}, { resellerId }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("report-exportResellerTasks")({ resellerId })
        .then(result => resolve(result.data))
        .catch(error => reject(error.message));
    });
  }
};

const mutations = {
  setUsers: (state, { resellerId, users }) => {
    Vue.set(
      state.observed,
      resellerId,
      _.merge({}, state.observed[resellerId], { _users: users })
    );
  },
  unsetUser: (state, { resellerId, userId }) => {
    if (state.observed[resellerId]._users[userId]) {
      Vue.delete(state.observed[resellerId]._users, userId);
    }
  },
  unsetUsers: (state, { resellerId }) => {
    if (state.observed[resellerId]) {
      Vue.set(state.observed[resellerId], "_users", {});
    }
  },
  setReseller: (state, { resellerId, resellerData }) => {
    const prevData = state.observed[resellerData] || {};
    Vue.set(
      state.observed,
      resellerId,
      _.merge(
        {
          _id: resellerId,
          _users: prevData._users || {}
        },
        resellerData
      )
    );
  },
  unsetReseller: (state, { resellerId }) => {
    if (state.observed[resellerId]) {
      Vue.delete(state.observed, resellerId);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
