<template>
  <div
    :class="{
      'is-hoverable': isHoverable,
      'is-centered': isCentered,
      'is-wrapped': wrap
    }"
    class="apart"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "Apart",
  props: {
    isHoverable: {
      type: Boolean,
      default: false
    },
    isCentered: {
      type: Boolean,
      default: false
    },
    wrap: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";
.apart {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25em;
  min-height: 3rem;

  & > * {
    margin: 0.25rem;
  }
  & + .apart {
    border-top: 1px solid $border;
  }
  &:not(.is-wrapped) {
    :first-child {
      flex-shrink: 0;
    }
  }
  &.is-wrapped {
    flex-wrap: wrap;
  }
  &.is-centered {
    justify-content: center;
  }
  &.is-hoverable:hover {
    background-color: $light;
  }
}
</style>
