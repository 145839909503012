import { $functions } from "@/firebase";
import { ConfirmProgrammatic } from "@components/confirm";

const getters = {
  cards: state => userId => {
    return state.cards[userId] || null;
  }
};

const actions = {
  getPaymentMethods: async (context, { userId }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-account-billing")({
          action: "getPaymentMethods",
          userId
        })
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },

  beginSetup: () => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-account-billing")({
          action: "createSetupIntent"
        })
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },

  deletePaymentMethod: (context, { brand, id, last4, userId }) => {
    return new Promise((resolve, reject) => {
      const confirm = ConfirmProgrammatic.open({
        props: {
          autoClose: false,
          action: `Remove payment method`,
          message: `Are you sure you want to remove the ${brand} ending ${last4}? This cannot be undone.`
        },
        canCancel: ["escape", "outside"],
        events: {
          confirmed: () => {
            $functions()
              .httpsCallable("onCall-account-billing")({
                action: "deletePaymentMethod",
                userId,
                params: { id }
              })
              .then(result => resolve(result.data))
              .catch(reject)
              .finally(() => {
                confirm.close();
              });
          }
        }
      });
    });
  }
};

const mutations = {};

export { getters, actions, mutations };
