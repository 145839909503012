<template>
  <nav
    ref="tabs"
    :class="{ 'is-responsive': isResponsive, 'is-borderless': isBorderless }"
    class="tabs"
  >
    <ul v-if="routerLinks">
      <router-link
        v-for="route in filteredRoutes"
        :key="$_.camelCase(route.label)"
        :to="route.to"
        tag="li"
        active-class="router-link-active is-active"
      >
        <a>{{ route.label }}</a>
      </router-link>
    </ul>

    <ul v-else>
      <li
        v-for="route in filteredRoutes"
        :key="$_.camelCase(route.label)"
        :class="{ 'is-active': route === activeRoute }"
      >
        <a href="javascript:void(0)" @click="$emit('changed', route)">
          {{ route.label }}
        </a>
      </li>
    </ul>

    <div v-if="isResponsive" class="select">
      <select
        v-if="routerLinks"
        title="Select an option"
        @change="$router.push({ path: $event.target.value })"
      >
        <option disabled value>Select an option</option>

        <option
          v-for="(route, i) in filteredRoutes"
          :key="i"
          :value="route.to.path"
          :selected="route.to.path === $route.path"
          >{{ route.label }}</option
        >
      </select>

      <select
        v-else
        title="Select an option"
        @change="$emit('changed', routes[$event.target.value])"
      >
        <option disabled value>Select an option</option>

        <option
          v-for="(route, i) in filteredRoutes"
          :key="i"
          :value="i"
          :selected="route === activeRoute"
          >{{ route.label }}</option
        >
      </select>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "HorizontalNav",
  props: {
    routes: {
      type: Array,
      required: true
    },
    routerLinks: {
      type: Boolean,
      required: false,
      default: true
    },
    activeRoute: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    isBorderless: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isResponsive: false
    };
  },
  computed: {
    ...mapState({
      width: state => state.ui.window.width
    }),
    filteredRoutes() {
      return this.$_(this.routes)
        .filter(route => {
          return route.guard ? route.guard() : true;
        })
        .filter(route => {
          if (this.routerLinks) {
            return this.$_.has(route, "to");
          } else {
            return true;
          }
        })
        .value();
    }
  },
  watch: {
    width() {
      if (this.$refs.tabs) {
        this.evaluateWidth();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.evaluateWidth();
    });
  },
  methods: {
    evaluateWidth() {
      this.isResponsive =
        this.$refs.tabs.scrollWidth > this.$refs.tabs.offsetParent.offsetWidth;
    }
  }
};
</script>

<style lang="scss" scoped>
nav {
  position: relative;

  &.is-responsive {
    overflow: hidden;

    li {
      visibility: hidden;
    }

    div.select {
      position: absolute;
      height: auto;
      top: 0;
      right: 0;
      bottom: 1px;
      left: 0;

      select {
        width: 100%;
        height: 100%;
        border: none !important;
        box-shadow: 0 0 0 0 !important;
      }
    }
  }

  &.is-borderless {
    ul,
    li:not(.is-active) a:not(:hover) {
      border-bottom-color: transparent;
    }
  }
}
</style>
