<template>
  <b-field :addons="showButton" expanded>
    <p class="control is-expanded">
      <input
        ref="datePicked"
        v-model="timeStampPicked"
        class="input"
        :readonly="readonly"
        expanded
        :placeholder="`eg. ${$moment().format(format)}`"
        :disabled="disabled"
        @click="onInputClick"
      />
    </p>
    <p v-if="showButton && !disabled" class="control">
      <a
        class="button is-success"
        style="height: 100%;"
        @click="openSetTimestampModal()"
      >
        <b-icon icon="calendar-alt" size="is-small" />
      </a>
    </p>
  </b-field>
</template>

<script>
export default {
  name: "DateTimePicker",
  props: {
    value: { type: String, required: false, default: "" },
    format: { type: String, default: "DD-MM-YYYY HH:mm" },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    minDate: { type: String, default: "" },
    maxDate: { type: String, default: "" },
    incrementMinutes: { type: Number, default: 1 },
    focus: { type: Boolean, default: false },
    showButton: { type: Boolean, default: false }
  },
  data() {
    return {
      timeStampPicked: ""
    };
  },
  watch: {
    timeStampPicked(newDateTime) {
      this.validate(newDateTime)
        .then(() => {
          const date = this.$moment(newDateTime, this.format).format(
            this.format
          );
          this.$emit("input", date);
        })
        .catch(() => {
          this.$emit("input", null);
        });
    }
  },
  created() {
    this.timeStampPicked = this.value
      ? this.$moment(this.value, this.format).format(this.format)
      : "";
  },
  methods: {
    onInputClick() {
      if (!this.showButton || this.readonly) {
        this.openSetTimestampModal();
      }
    },
    openSetTimestampModal() {
      const modal = this.$modal.open({
        component: () => import("./TimestampModal"),
        parent: this,
        props: {
          timestamp: this.$moment(this.timeStampPicked, this.format).isValid()
            ? this.timeStampPicked
            : this.$moment().format(this.format),
          format: this.format,
          incrementMinutes: this.incrementMinutes,
          min: this.minDate,
          max: this.maxDate
        },
        width: 480,
        hasModalCard: true,
        events: {
          success: newTimeStamp => {
            this.timeStampPicked = newTimeStamp;
            this.$nextTick(() => {
              this.$refs.datePicked.select();
            });
            modal.close();
          }
        }
      });
    },
    validate(dateTime) {
      return new Promise((resolve, reject) => {
        const moment = this.$moment(dateTime, this.format, true);
        const minDate = this.$moment(this.minDate, this.format);
        const maxDate = this.$moment(this.maxDate, this.format);
        if (!moment.isValid()) return reject();
        if (this.minDate && moment.isSameOrBefore(minDate)) return reject();
        if (this.maxDate && moment.isSameOrAfter(maxDate)) return reject();
        resolve();
      });
    }
  }
};
</script>
