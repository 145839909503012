import { uploadBytesResumable } from "firebase/storage";

const actions = {
  uploadFile: (context, { fileRef, file, metadata }) => {
    return new Promise((resolve, reject) => {
      // Abort if file size >16MB
      if (file.size > 16 * 1024 * 1024)
        return reject(new Error("File is bigger than 16MB"));
      const uploadTask = uploadBytesResumable(fileRef, file, metadata);
      return resolve({ uploadTask });
    });
  }
};

export default {
  namespaced: true,
  state: {},
  getters: {},
  actions,
  mutations: {}
};
