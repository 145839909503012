<template>
  <div>
    <div v-if="!$_.isEmpty(messages)" class="has-text-centered">
      <p class="title is-size-5 has-padding-bottom-100">
        {{ $_.get(messages, `[${active}]`, "") }}
      </p>
    </div>
    <div class="step-tracker">
      <div class="step-tracker-track">
        <span
          :style="{ right: `${100 - complete}%` }"
          class="step-tracker-line"
        />
      </div>
      <span
        v-for="(step, index) in total"
        :key="index"
        :class="{ 'is-active': index === active }"
        class="step-tracker-point"
        @click="$emit('click', index)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "StepTracker",
  props: {
    total: {
      type: Number,
      required: true
    },
    active: {
      type: Number,
      required: true
    },
    messages: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    complete() {
      return (this.active / (this.total - 1)) * 100;
    }
  }
};
</script>

<style lang="scss" scoped>
$inactiveColor: #cccccc;
$activeColor: #00c6ce;
.step-tracker {
  font-size: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  .step-tracker-track {
    height: 0.3em;
    top: 0.35em;
    left: 0.35em;
    right: 0.35em;
    background: $inactiveColor;
    position: absolute;
    z-index: 0;
    .step-tracker-line {
      position: absolute;
      height: 100%;
      left: 0;
      right: 100%;
      background: $activeColor;
      transition-property: "background";
      transition-duration: 0.66s;
    }
  }
  .step-tracker-point {
    width: 1em;
    height: 1em;
    background: $activeColor;
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
    transition-property: "background";
    transition-duration: 0.66s;
    transition-delay: 0.5s;
    &.is-active ~ .step-tracker-point {
      cursor: default;
      background: $inactiveColor;
      transition-duration: 0s;
      transition-delay: 0s;
    }
  }
}
</style>
