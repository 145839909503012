<template>
  <div
    class="pane"
    @dragover.prevent="$emit('dragover')"
    @dragleave.prevent="$emit('dragleave', $event)"
    @drop.prevent="$emit('drop', $event)"
  >
    <div v-if="headerDefined" class="pane-header">
      <slot name="header" />
    </div>

    <div class="pane-content is-scrollable">
      <slot name="content" />
    </div>

    <div class="pane-footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Pane",
  computed: {
    headerDefined() {
      return !!this.$slots["header"];
    },
    contentDefined() {
      return !!this.$slots["content"];
    },
    footerDefined() {
      return !!this.$slots["footer"];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.pane {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;

  .pane-content,
  .pane-header,
  .pane-footer {
    width: 100%;
    position: relative;
  }

  .pane-content {
    flex-grow: 1;
  }

  .pane-header,
  .pane-footer {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .pane-footer:not(:empty) {
    border-top: 1px solid $border;
  }
}
</style>
