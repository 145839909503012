import Vue from "vue";
import { $firestore } from "@/firebase";
import { doc } from "firebase/firestore";

const defaultState = () => {
  return {
    stats: {
      sites: {},
      tasks: {},
      users: {},
      contracts: {}
    }
  };
};

const state = defaultState();

const getters = {};

const actions = {
  observeStats: ({ commit, dispatch }, { statsId }) => {
    const statsRef = doc($firestore, `stats`, statsId);
    return dispatch(
      "observe",
      {
        key: `admin/stats/${statsId}`,
        ref: statsRef,
        onSnapshot: stats => {
          commit("setStats", { stats: stats });
        },
        onError: () => {
          commit("unsetStats", { statsId: statsId });
        }
      },
      { root: true }
    );
  },
  unobserveStats: ({ commit, dispatch }, { statsId }) => {
    return dispatch(`unobserve`, `admin/stats/${statsId}`, { root: true }).then(
      unsubscribed => {
        if (unsubscribed) {
          commit("unsetStats", { statsId });
        }
      }
    );
  },
  reset: async ({ dispatch, commit }) => {
    try {
      await dispatch("unobserveAll", { containing: "admin" }, { root: true });
      commit("resetState");
    } catch (error) {
      console.error(error);
    }
  }
};

const mutations = {
  setStats: (state, { stats }) => {
    Vue.set(state.stats, stats.id, stats.data() || {});
  },
  unsetStats: (state, { statsId }) => {
    Vue.set(state.stats, statsId, {});
  },
  resetState: state => {
    Object.assign(state, defaultState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
