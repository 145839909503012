import Vue from "vue";
import { $firestore } from "@/firebase";
import { doc } from "firebase/firestore";

const defaultState = () => {
  return {
    observed: {}
  };
};

const state = defaultState();

const getters = {
  data: state => id => {
    return state.observed[id] || null;
  }
};

const actions = {
  observe: ({ dispatch, commit }, docId) => {
    return new Promise((resolve, reject) => {
      const docRef = doc($firestore, "algolia", docId);

      dispatch(
        "observe",
        {
          key: `algolia/${docId}`,
          ref: docRef,
          onSnapshot: doc => {
            if (!doc.exists()) {
              dispatch("unobserve", docId);
              return reject();
            }
            commit("setData", {
              id: doc.id,
              data: doc.data() || {}
            });
            return resolve();
          },
          onError: error => {
            dispatch("unobserve", docId);
            return reject(error);
          }
        },
        { root: true }
      )
        .then(resolve)
        .catch(reject);
    });
  },
  unobserve: ({ commit, dispatch }, docId) => {
    return dispatch(`unobserve`, `algolia/${docId}`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit(`unsetData`, docId);
      }
    });
  },
  reset: async ({ dispatch, commit }) => {
    try {
      await dispatch("unobserveAll", { containing: "algolia" }, { root: true });
      commit("resetState");
    } catch (error) {
      console.error(error);
    }
  }
};

const mutations = {
  setData: (state, { id, data }) => {
    Vue.set(state.observed, id, data);
  },
  unsetData: (state, id) => {
    if (state.observed[id]) {
      Vue.delete(state.observed, id);
    }
  },
  resetState: state => {
    Object.assign(state, defaultState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
