<template>
  <transition
    :enter-active-class="transition.enter"
    :leave-active-class="transition.leave"
  >
    <div v-show="isActive" :class="[type, position]" class="snackbar">
      <p class="text">{{ message }}</p>
      <div v-if="actionText" :class="type" class="action" @click="action">
        <button class="button is-dark">{{ actionText }}</button>
      </div>

      <div
        v-for="a in actions"
        :key="a.text"
        :style="a.style"
        :class="$_.merge({}, { [type]: true }, a.cssClass)"
        class="action"
        @click="a.click"
      >
        <button class="button is-dark">{{ a.text }}</button>
      </div>
    </div>
  </transition>
</template>

<script>
import config from "buefy/src/utils/config";
import NoticeMixin from "buefy/src/utils/NoticeMixin";

export default {
  name: "FSnackbar",
  mixins: [NoticeMixin],
  props: {
    actions: {
      type: Array,
      default: () => []
    },
    actionText: {
      type: String,
      default: ""
    },
    onAction: {
      type: Function,
      default: () => {}
    },
    indefinite: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newDuration: this.duration || config.defaultSnackbarDuration
    };
  },
  methods: {
    /**
     * Click listener.
     * Call action prop before closing (from Mixin).
     */
    action() {
      this.onAction();
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.snackbar {
  max-width: 800px !important;
  .text {
    flex: 1;
  }
  .action button {
    text-transform: none !important;
  }
  .action {
    padding-left: 0;
    padding-right: 0;
    &:last-child {
      margin-right: 0.75em;
    }
  }
  .action > button {
    padding-left: 0.375em;
    padding-right: 0.375em;
  }
}
</style>
