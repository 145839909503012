<template>
  <div class="custom-notification">
    <timestamp :date="dateCreated" />

    <p>
      <pulse v-if="!read" class="has-margin-right-50" />
      {{ message }}
    </p>

    <p class="has-margin-top-25">
      <a
        v-if="!read"
        href="javascript:void(0);"
        class="has-margin-right-25"
        @click="$emit('dismissed')"
      >
        <strong>
          <u>Dismiss</u>
        </strong>
      </a>

      <router-link
        v-if="!$_.isEmpty(cta)"
        :to="cta.path"
        class="has-text-dark"
        @click.native="$emit('ctaClicked')"
      >
        <strong>
          <u>{{ cta.label }}</u>
        </strong>
      </router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    dateCreated: {
      type: Date,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    read: {
      type: Boolean,
      required: true
    },
    cta: {
      type: Object,
      default: () => {
        return {};
      },
      required: false,
      validator: value => {
        return value["path"] && value["label"];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.custom-notification {
  font-size: 0.9375rem;
  padding: 1em;
  &:not(:last-child) {
    border-bottom: 1px dotted $grey-lighter;
  }
}
</style>
