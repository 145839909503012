<template>
  <a class="has-text-danger" @click="confirm">
    <slot />
  </a>
</template>

<script>
export default {
  name: "Confirm",
  props: {
    action: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  methods: {
    confirm() {
      this.$confirm.open({
        parent: this,
        props: {
          action: this.action,
          message: this.message
        },
        events: {
          confirmed: () => {
            this.$emit("confirmed");
          }
        }
      });
    }
  }
};
</script>
