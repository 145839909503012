import Vue from "vue";
import { $functions } from "@/firebase";
import { getCookie } from "@services/cookies";

const defaultState = () => {
  return {
    observed: {}
  };
};

const state = defaultState();

const getters = {
  affiliate: state => affAccountId => {
    return state.observed[affAccountId] || null;
  }
};

const actions = {
  observeAffiliate: ({ dispatch, commit }, { affAccountRef }) => {
    return dispatch(
      "observe",
      {
        key: `affiliate/${affAccountRef.id}`,
        ref: affAccountRef,
        onSnapshot: affAccountDoc => {
          commit("setAffiliate", {
            affAccountId: affAccountDoc.id,
            data: affAccountDoc.data()
          });
        }
      },
      { root: true }
    );
  },
  unobserveAffiliate: ({ commit, dispatch }, { affAccountId }) => {
    return dispatch(`unobserve`, `affiliate/${affAccountId}`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit(`unsetAffiliate`, { affAccountId });
      }
    });
  },
  // eslint-disable-next-line no-empty-pattern
  inviteUser: ({}, { email, message }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("affiliate-inviteUser")({ email, message })
        .then(result => resolve(result.data))
        .catch(error => reject(error.message));
    });
  },
  linkReferrer: (context, { userId, referrerId, backdateCommission }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-user-linkReferrer")({
          userId,
          referrerId,
          backdateCommission
        })
        .then(result => resolve(result.data))
        .catch(error => reject(error.message));
    });
  },
  connect: async () => {
    try {
      const affCookieKey = process.env.VUE_APP_AFFILIATE_COOKIE_NAME;
      const affAccountId = getCookie(affCookieKey);
      if (affAccountId) {
        await $functions().httpsCallable("onCall-affiliate-connect")({
          affAccountId
        });
      }
      return Promise.resolve();
    } catch (error) {
      return console.warn(error.message);
    }
  },
  acceptInvite: (context, { email }) => {
    return $functions()
      .httpsCallable("affiliate-acceptInvite")({
        email
      })
      .catch(error => console.error(error.message));
  },
  reset: async ({ dispatch, commit }) => {
    try {
      await dispatch(
        "unobserveAll",
        { containing: "affiliate" },
        { root: true }
      );
      commit("resetState");
    } catch (error) {
      console.error(error);
    }
  }
};

const mutations = {
  resetState: state => {
    Object.assign(state, defaultState());
  },
  setAffiliate: (state, { affAccountId, data }) => {
    Vue.set(
      state.observed,
      affAccountId,
      Vue.prototype.$_.merge({}, data, { _id: affAccountId })
    );
  },
  unsetAffiliate: (state, { affAccountId }) => {
    if (state.observed[affAccountId]) {
      Vue.delete(state.observed, affAccountId);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
