import Vue from "vue";
import { $firestore } from "@/firebase";
import _ from "lodash";
import { doc } from "firebase/firestore";

const defaultState = () => {
  return {
    observed: {}
  };
};

const state = defaultState();

const getters = {
  order: state => orderId => {
    return state.observed[orderId];
  }
};

const actions = {
  observeOrder: ({ dispatch, commit }, { orderId }) => {
    return new Promise((resolve, reject) => {
      const orderRef = doc($firestore, `orders`, orderId);
      dispatch(
        "observe",
        {
          key: `orders/${orderId}`,
          ref: orderRef,
          onSnapshot: order => {
            if (!order.exists()) {
              dispatch("unobserveOrder", { orderId });
              return reject();
            }
            commit("setOrder", {
              orderId,
              orderData: order.data() || {}
            });
            return resolve();
          },
          onError: error => {
            dispatch("unobserveOrder", { orderId });
            return reject(error);
          }
        },
        { root: true }
      )
        .then(resolve)
        .catch(reject);
    });
  },
  unobserveOrder: ({ commit, dispatch }, { orderId }) => {
    return dispatch(`unobserve`, `orders/${orderId}`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit(`unsetOrder`, { orderId });
      }
    });
  },
  reset: async ({ dispatch, commit }) => {
    try {
      await dispatch("unobserveAll", { containing: "orders" }, { root: true });
      commit("resetState");
    } catch (error) {
      console.error(error);
    }
  }
};

const mutations = {
  setOrder: (state, { orderId, orderData }) => {
    orderData = _.isEmpty(orderData)
      ? orderData
      : _.merge({}, orderData, { _id: orderId });
    Vue.set(state.observed, orderId, orderData);
  },
  unsetOrder: (state, { orderId }) => {
    if (state.observed[orderId]) {
      Vue.delete(state.observed, orderId);
    }
  },
  resetState: state => {
    Object.assign(state, defaultState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
