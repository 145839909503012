import Vue from "vue";
import _ from "lodash";
import router from "@router";
import { Snackbar } from "@src/components/snackbar";
import { $functions, $firestore } from "@/firebase";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  where,
  limit as queryLimit
} from "firebase/firestore";

const getters = {
  user: (state, getters, rootState) => (
    userId = rootState.auth.currentUser.uid
  ) => {
    if (!state.observed[userId]) return false;
    return state.observed[userId] || {};
  },
  name: (state, getters, rootState) => (
    userId = rootState.auth.currentUser.uid
  ) => {
    if (!state.observed[userId]) return "";
    return state.observed[userId].name || "";
  },
  email: (state, getters, rootState) => (
    userId = rootState.auth.currentUser.uid
  ) => {
    if (!state.observed[userId]) return "";
    return state.observed[userId].email || "";
  },
  avatarUrl: (state, getters, rootState) => (
    userId = rootState.auth.currentUser.uid
  ) => {
    if (!state.observed[userId]) return "";
    return state.observed[userId].avatarUrl || "";
  },
  currency: (state, getters, rootState) => (
    userId = rootState.auth.currentUser.uid
  ) => {
    if (!state.observed[userId]) return "gbp";
    return state.observed[userId].currency || "gbp";
  },
  billingEntity: (state, getters, rootState) => (
    userId = rootState.auth.currentUser.uid
  ) => {
    if (!state.observed[userId]) return "fixed_wordpress_maintenance_llc";
    return (
      state.observed[userId].billingEntity || "fixed_wordpress_maintenance_llc"
    );
  },
  missingUserData: (state, getters, rootState) => (
    userId = rootState.auth.currentUser.uid
  ) => {
    const user = state.observed[userId];
    if (!user) return false;
    if (_.isEmpty(user.name)) return true;
    if (!_.isBoolean(user.sendOffers)) return true;
    if (!_.isBoolean(user.sendProductInformation)) return true;
    if (!_.isBoolean(user.sendStatusReports)) return true;
    return false;
  },
  isAdmin: (state, getters, rootState) => (
    userId = rootState.auth.currentUser.uid
  ) => {
    if (!state.observed[userId]) return false;
    return state.observed[userId].isAdmin || false;
  },
  isOnlyAgent: (state, getters, rootState) => (
    userId = rootState.auth.currentUser.uid
  ) => {
    if (!state.observed[userId]) return false;
    return (
      (!state.observed[userId].isAdmin && state.observed[userId].isAgent) ||
      false
    );
  },
  isAgent: (state, getters, rootState) => (
    userId = rootState.auth.currentUser.uid
  ) => {
    if (!state.observed[userId]) return false;
    return state.observed[userId].isAgent || false;
  },
  isResellerUser: (state, getters, rootState) => (
    userId = rootState.auth.currentUser.uid
  ) => {
    if (!state.observed[userId]) return false;
    return state.observed[userId].isResellerUser || false;
  },
  isReseller: (state, getters, rootState) => (
    userId = rootState.auth.currentUser.uid
  ) => {
    if (!state.observed[userId]) return false;
    return state.observed[userId].isReseller || false;
  },
  unreadNotificationCount: state => () => {
    return _.toArray(state.notifications.observed).length;
  }
};

const actions = {
  observeUser: ({ commit, dispatch }, { userId, checkExistance = true }) => {
    return new Promise((resolve, reject) => {
      const userRef = doc($firestore, `users`, userId);
      dispatch(
        "observe",
        {
          key: `users/${userId}`,
          ref: userRef,
          onSnapshot: user => {
            if (checkExistance && !user.exists()) {
              dispatch("unobserveUser", { userId: userId });
              return reject();
            }
            commit("setUser", { userId: user.id, userData: user.data() });
            resolve(user);
          }
        },
        { root: true }
      )
        .then(resolve)
        .catch(error => {
          dispatch("unobserveUser", { userId: userId });
          reject(error);
        });
    });
  },
  unobserveUser: ({ commit, dispatch }, { userId }) => {
    return new Promise(resolve => {
      dispatch(`unobserve`, `users/${userId}`, { root: true }).then(remove => {
        if (remove) {
          commit("unsetUser", { userId: userId });
        }
      });
      resolve();
    });
  },
  getActivities: (context, { ref, cursor = null, limit = 10 }) => {
    return new Promise((resolve, reject) => {
      ref = cursor ? query(ref, startAfter(cursor)) : ref;
      getDocs(query(ref, queryLimit(limit + 1)))
        .then(snapshot => {
          return resolve({
            activities: snapshot.docs.splice(0, limit),
            complete: snapshot.size < limit + 1
          });
        })
        .catch(error => {
          return reject(error);
        });
    });
  },
  observeAlerts: ({ state, commit, dispatch }, { userId }) => {
    const alertsRef = query(
      collection($firestore, "alerts"),
      where("userId", "==", userId),
      orderBy(`dateCreated`, `desc`)
    );
    return dispatch(
      "observe",
      {
        key: `users/${userId}/alerts`,
        ref: alertsRef,
        onSnapshot: snapshot => {
          _.each(snapshot.docChanges(), change => {
            const alert = change.doc;
            if (change.type === "removed") {
              commit("unsetAlert", {
                alertId: alert.id
              });
            } else {
              if (_.has(state.alerts.excluded, alert.id)) {
                deleteDoc(alert.ref);
              } else if (!alert.data().isMuted) {
                let snackbar;
                if (_.has(state.alerts.observed, alert.id)) {
                  snackbar = state.alerts.observed[alert.id].snackbar;
                  snackbar.message = alert.data().message;
                } else {
                  snackbar = Snackbar.open({
                    indefinite: true,
                    message: alert.data().message,
                    type: "is-primary",
                    position: "is-bottom",
                    queue: false,
                    actions: [
                      {
                        text: "View",
                        click: () => {
                          if (alert.data().cta)
                            router.push({ path: alert.data().cta });
                          deleteDoc(alert.ref);
                        }
                      },
                      {
                        text: "Dismiss",
                        cssClass: {
                          "is-warning": true
                        },
                        click: () => {
                          deleteDoc(alert.ref);
                        }
                      }
                    ]
                  });
                }
                commit("setAlert", {
                  alertId: alert.id,
                  alertData: _.merge({}, alert.data(), { snackbar })
                });
              }
            }
          });
        }
      },
      { root: true }
    );
  },
  saveDetails: ({ rootState }, payload) => {
    return new Promise((resolve, reject) => {
      setDoc(
        doc($firestore, "users", rootState.auth.currentUser.uid),
        {
          name: payload.name,
          email: payload.email,
          sendOffers: payload.sendOffers,
          sendProductInformation: payload.sendProductInformation,
          sendStatusReports: payload.sendStatusReports,
          dateUpdated: serverTimestamp()
        },
        {
          merge: true
        }
      )
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error.message);
        });
    });
  },
  updateProfile: (
    { rootState },
    { userId = rootState.auth.currentUser.uid, payload }
  ) => {
    return updateDoc(
      doc($firestore, "users", userId),
      Object.assign(payload, {
        dateUpdated: serverTimestamp()
      })
    );
  },
  getNotifications: (context, { ref, cursor = null, limit = 10 }) => {
    return new Promise((resolve, reject) => {
      ref = cursor ? query(ref, startAfter(cursor)) : ref;
      getDocs(query(ref, queryLimit(limit + 1)))
        .then(snapshot => {
          return resolve({
            notifications: snapshot.docs.splice(0, limit),
            complete: snapshot.size < limit + 1
          });
        })
        .catch(error => {
          return reject(error);
        });
    });
  },
  observeNotifications: ({ commit, dispatch, state }, { userId }) => {
    const notificationsRef = query(
      collection($firestore, `users/${userId}/notifications`),
      where("read", "==", false),
      orderBy(`dateCreated`, `desc`)
    );
    return dispatch(
      "observe",
      {
        key: `users/${userId}/notifications`,
        ref: notificationsRef,
        onSnapshot: snapshot => {
          // React to server changes
          const count = _.keys(state.notifications.observed).length;
          _.each(snapshot.docChanges(), change => {
            const notification = change.doc;
            switch (change.type) {
              case "removed":
                /**
                 * We don't actually remove the notification but just
                 * set it as read so if the user dismisses it from another device
                 * it won't disappear here
                 */
                commit("setNotificationAsRead", {
                  notificationId: notification.id
                });

                break;
              default:
                commit("setNotifications", {
                  notifications: {
                    [notification.id]: _.merge(
                      { _id: notification.id },
                      notification.data()
                    )
                  }
                });
                break;
            }
          });
          // Dispatch browser alert
          if (snapshot.size > count) {
            if (!router.currentRoute.meta.blockNotifications) {
              dispatch(
                "ui/showBrowserNotification",
                {
                  message: `You have ${snapshot.size} unread messages`,
                  onclick: () => {
                    dispatch("ui/openNotifications", null, {
                      root: true
                    });
                  }
                },
                { root: true }
              );
            }
          }
        }
      },
      { root: true }
    );
  },
  markNotificationAsRead: (
    { rootState },
    { userId = rootState.auth.currentUser.uid, notification }
  ) => {
    return updateDoc(
      doc($firestore, `users/${userId}/notifications`, notification._id),
      {
        read: true,
        dateUpdated: serverTimestamp()
      }
    );
  },
  enable: (context, { userId, enable }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-user-enable")({
          userId,
          enable
        })
        .then(result => {
          return resolve(result.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateAccessRights: (context, { userId, role }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-user-updateAccessRights")({
          userId,
          role
        })
        .then(result => {
          return resolve(result.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addCredit: (context, { userId, amount, currency, note }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-user-addCredit")({
          userId,
          amount,
          currency,
          note
        })
        .then(result => resolve(result.data))
        .catch(error => reject(error));
    });
  },
  generateSupportPin: (context, { userId }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-user-generateSupportPin")({
          userId
        })
        .then(result => resolve(result.data))
        .catch(error => reject(error));
    });
  },
  reset: async ({ state, dispatch, commit }) => {
    try {
      await dispatch("unobserveAll", { containing: "users" }, { root: true });
      _.each(state.alerts.observed, alert => alert.snackbar.close());
      commit("resetState");
    } catch (error) {
      console.error(error);
    }
  },
  disableAlertsFor: ({ commit }, alertId) => {
    deleteDoc(doc($firestore, "alerts", alertId)).catch(err => err);
    commit("disableAlertsFor", alertId);
  }
};

const mutations = {
  disableAlertsFor: (state, key) => {
    Vue.set(state.alerts.excluded, key, true);
  },
  enableAlertsFor: (state, key) => {
    Vue.delete(state.alerts.excluded, key, true);
  },
  setUser: (state, { userId, userData }) => {
    Vue.set(state.observed, userId, userData);
  },
  unsetUser: (state, { userId }) => {
    if (state.observed[userId]) {
      Vue.delete(state.observed, userId);
    }
  },
  setAlert: (state, { alertId, alertData }) => {
    Vue.set(state.alerts.observed, alertId, alertData);
  },
  unsetAlert: (state, { alertId }) => {
    if (state.alerts.observed[alertId]) {
      state.alerts.observed[alertId].snackbar.close();
      Vue.delete(state.alerts.observed, alertId);
    }
  },
  setNotifications: (state, { notifications }) => {
    Vue.set(
      state.notifications,
      "observed",
      _.merge({}, state.notifications.observed, notifications)
    );
  },
  setNotificationAsRead: (state, { notificationId }) => {
    if (state.notifications.observed[notificationId]) {
      state.notifications.observed[notificationId].read = true;
    }
  },
  unsetNotification: (state, { notificationId }) => {
    if (state.notifications.observed[notificationId]) {
      Vue.delete(state.notifications.observed, notificationId);
    }
  }
};

export { getters, actions, mutations };
