import Vue from "vue";
import { $functions, $firestore } from "@/firebase";
import _ from "lodash";
import { ConfirmProgrammatic } from "@components/confirm";
import { collection } from "firebase/firestore";

const getters = {
  siteSecrets: state => ({ siteId }) => {
    return _.get(state, `secrets.observed.${siteId}`, {});
  }
};

const actions = {
  addSecret: (context, { siteId, secret }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-site-addSecret")({ siteId, secret })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },
  removeSecret: (context, { secretId, siteId }) => {
    return new Promise((resolve, reject) => {
      const confirm = ConfirmProgrammatic.open({
        props: {
          autoClose: false,
          action: `Remove secret`,
          message: `Are you sure you want to remove this site secret?`
        },
        canCancel: ["escape", "outside"],
        events: {
          confirmed: () => {
            $functions()
              .httpsCallable("onCall-site-deleteSecret")({ secretId, siteId })
              .then(result => resolve(result.data))
              .catch(reject)
              .finally(() => confirm.close());
          }
        }
      });
    });
  },
  updateSecret: (context, { secretId, siteId, secret }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-site-updateSecret")({ secretId, siteId, secret })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },
  decodeValue: (context, { siteId, secretId, value }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-site-decodeSecret")({ siteId, secretId, value })
        .then(result => resolve(result.data))
        .catch(reject);
    });
  },
  observeSecrets: ({ commit, dispatch }, { siteId }) => {
    const secretsRef = collection($firestore, `sites/${siteId}/secrets`);
    return dispatch(
      "observe",
      {
        key: `sites/${siteId}/secrets`,
        ref: secretsRef,
        onSnapshot: snapshot => {
          // React to server changes
          _.each(snapshot.docChanges(), change => {
            const secret = change.doc;
            if (change.type === "removed") {
              commit("unsetSecret", {
                siteId,
                secretId: secret.id
              });
            } else {
              commit("setSecrets", {
                siteId: siteId,
                secretId: secret.id,
                secrets: {
                  [secret.id]: _.merge({ _id: secret.id }, secret.data())
                }
              });
            }
          });
        }
      },
      { root: true }
    );
  },
  unobserveSecrets: ({ commit, dispatch }, { siteId }) => {
    return dispatch(`unobserve`, `sites/${siteId}/secrets`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit(`unsetSecrets`, { siteId });
      }
    });
  }
};

const mutations = {
  setSecrets: (state, { siteId, secrets }) => {
    Vue.set(
      state.secrets.observed,
      siteId,
      _.merge({}, state.secrets.observed[siteId], secrets)
    );
  },
  unsetSecret: (state, { siteId, secretId }) => {
    if (state.secrets.observed[siteId][secretId]) {
      Vue.delete(state.secrets.observed[siteId], secretId);
    }
  },
  unsetSecrets: (state, { siteId }) => {
    if (state.secrets.observed[siteId]) {
      Vue.delete(state.secrets.observed, siteId);
    }
  }
};

export { getters, actions, mutations };
