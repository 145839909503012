import store from "@store";

export default {
  path: "/sites",
  component: () =>
    import(/* webpackChunkName: "authenticated" */ "@skeleton/loggedIn/index"),
  beforeEnter: (to, from, next) => {
    store.commit("ui/routing", true);
    next();
  },
  meta: {
    requiresAuth: true,
    breadcrumb: {
      label: "My sites"
    }
  },
  children: [
    {
      path: "",
      name: "sites",
      components: {
        default: () => import("@views/sites")
      },
      meta: {
        title: "My sites"
      }
    },
    {
      path: ":siteId",
      component: () => import("@views/sites/site"),
      beforeEnter: (to, from, next) => {
        store.commit("ui/routing", true);
        next();
      },
      meta: {
        key: params => params.siteId,
        breadcrumb: {
          param: "siteId",
          getter: "sites/site",
          label: site => `${site.fqdn}`
        }
      },
      children: [
        {
          path: "",
          redirect: "tasks"
        },
        {
          path: "tasks",
          component: () => import("@views/sites/site/tasks"),
          meta: {
            title: {
              param: "siteId",
              getter: "sites/site",
              format: site => `Tasks | ${site.fqdn}`
            },
            breadcrumb: {
              label: "tasks"
            }
          }
        },
        {
          path: "info",
          component: () => import("@views/sites/site/info"),
          meta: {
            title: {
              param: "siteId",
              getter: "sites/site",
              format: site => `Info | ${site.fqdn}`
            },
            breadcrumb: {
              label: "info"
            }
          }
        },
        {
          path: "logs",
          component: () => import("@views/sites/site/logs"),
          meta: {
            title: {
              param: "siteId",
              getter: "sites/site",
              format: site => `Logs | ${site.fqdn}`
            },
            breadcrumb: {
              label: "logs"
            }
          }
        },
        {
          path: "backups",
          component: () => import("@views/sites/site/backups"),
          meta: {
            title: {
              param: "siteId",
              getter: "sites/site",
              format: site => `Backups | ${site.fqdn}`
            },
            breadcrumb: {
              label: "backups"
            }
          }
        },
        {
          path: "monitoring",
          component: () => import("@views/sites/site/monitoring"),
          meta: {
            title: {
              param: "siteId",
              getter: "sites/site",
              format: site => `Monitoring | ${site.fqdn}`
            },
            breadcrumb: {
              label: "monitoring"
            }
          }
        },
        {
          path: "settings",
          component: () => import("@views/sites/site/settings"),
          props: route => {
            return { siteId: route.params.siteId };
          },
          meta: {
            title: {
              param: "siteId",
              getter: "sites/site",
              format: site => `Settings | ${site.fqdn}`
            },
            breadcrumb: {
              label: "settings"
            }
          }
        },
        {
          path: "reports",
          component: () => import("@views/sites/site/reports"),
          meta: {
            title: {
              param: "siteId",
              getter: "sites/site",
              format: site => `Reports | ${site.fqdn}`
            },
            breadcrumb: {
              label: "reports"
            }
          }
        },
        {
          path: "changes",
          component: {
            name: "ChangesView",
            template: `<router-view />`
          },
          children: [
            {
              path: "",
              component: () => import("@views/sites/site/changes"),
              props: route => {
                return { siteId: route.params.siteId };
              },
              meta: {
                title: {
                  param: "siteId",
                  getter: "sites/site",
                  format: site => `Changes | ${site.fqdn}`
                },
                breadcrumb: {
                  label: "changes"
                }
              }
            }
          ]
        },
        {
          path: "plugins-and-themes",
          component: {
            name: "PluginsAndThemesView",
            template: `<router-view />`
          },
          children: [
            {
              path: "",
              name: "sitePlugins",
              component: () => import("@views/sites/site/plugins-and-themes"),
              props: route => {
                return { siteId: route.params.siteId };
              },
              meta: {
                title: {
                  param: "siteId",
                  getter: "sites/site",
                  format: site => `Plugins & Themes | ${site.fqdn}`
                },
                breadcrumb: {
                  label: "plugins & Themes"
                }
              }
            }
          ]
        },
        {
          path: "*",
          name: "site-error",
          component: () => import("@views/error"),
          props: { code: 404 },
          meta: {
            title: {
              param: "siteId",
              getter: "sites/site",
              format: site => `${site.fqdn}`
            }
          }
        }
      ]
    }
  ]
};
