import Avatar from "./avatar";
import Apart from "./apart";
import BarChart from "./charts/bar-chart";
import Card from "./card";
import ChatMessage from "./chat-message";
import Collapse from "./collapse";
import ColorDot from "./color-dot";
import Confirm from "./confirm";
import CornerRibbon from "./corner-ribbon";
import Contextualise from "./contextualise";
import DataSorter from "./data-sorter";
import DoughnutChart from "./charts/doughnut-chart";
import FloatingButton from "./floating-button";
import HorizontalNav from "./horizontal-nav";
import LineChart from "./charts/line-chart";
import List from "./list";
import Loading from "./loading";
import LoadMore from "./load-more";
import NoResults from "./noresults";
import Notification from "./notification";
import Pane from "./pane";
import PieChart from "./charts/pie-chart";
import Pulse from "./pulse";
import Ribbon from "./ribbon";
import Screenshot from "./screenshot";
import StarRating from "./star-rating";
import StaticField from "./static-field";
import StatusLabel from "./status-label";
import StepControls from "./step-controls";
import StepTracker from "./step-tracker";
import Timestamp from "./timestamp";
import Note from "./note";
import DatePickerModal from "./datePickerModal";
import ColorPicker from "./colorPicker";
import SingleInputModal from "./single-input-modal";
import ModalCard from "./modal-card";
import SchedulePicker from "./schedule-picker";
import SwitchSelector from "./switch-selector";
import FileSelector from "./file-selector";
import Urlify from "./urlify";
import { Vessel, Vessels } from "./vessel";
import { DateTimePicker, TimestampModal } from "./DateTimePicker";
import CurrencyInput from "./currencyInput";
import Selector from "./selector";
import PinInput from "./pinInput";

// import TimePickerModal from "./timePickerModal";

const components = {
  Apart,
  Avatar,
  BarChart,
  Card,
  ChatMessage,
  Collapse,
  ColorDot,
  ColorPicker,
  Confirm,
  Contextualise,
  CornerRibbon,
  CurrencyInput,
  DataSorter,
  DatePickerModal,
  DateTimePicker,
  DoughnutChart,
  FileSelector,
  FloatingButton,
  HorizontalNav,
  LineChart,
  List: List.List,
  ListCard: List.ListCard,
  LoadMore,
  Loading,
  ModalCard,
  NoResults,
  Note,
  Notification,
  Pane,
  PieChart,
  Pulse,
  Ribbon,
  SchedulePicker,
  Screenshot,
  Selector,
  SingleInputModal,
  StarRating,
  StaticField,
  StatusLabel,
  StepControls,
  StepTracker,
  SwitchSelector,
  Timestamp,
  TimestampModal,
  Urlify,
  Vessel,
  Vessels,
  PinInput
  // TimePickerModal
};

export default {
  install: Vue => {
    for (let componentKey in components) {
      let component = components[componentKey];
      Vue.component(component.name || componentKey, components[componentKey]);
    }
  }
};
