import _ from "lodash";
import Vue from "vue";
import Vuex from "vuex";
import { onSnapshot as getSnapshot } from "firebase/firestore";

Vue.use(Vuex);

const defaultState = () => {
  return {
    cache: {},
    observing: {},
    promises: {}
  };
};

export default new Vuex.Store({
  state: defaultState(),

  getters: {
    cached: state => ({ key }) => {
      return state.cache[key] || null;
    }
  },

  actions: {
    reset: ({ commit, dispatch }) => {
      dispatch("auth/reset");
      dispatch("user/reset");
      dispatch("admin/reset");
      dispatch("affiliate/reset");
      dispatch("billing/reset");
      dispatch("sites/reset");
      dispatch("tasks/reset");
      dispatch("orders/reset");
      dispatch("invoices/reset");
      dispatch("transactions/reset");
      dispatch("products/reset");
      dispatch("promotion/reset");
      dispatch("algolia/reset");
      dispatch("vault/reset");
      commit("resetState");
    },

    observe: ({ commit, state }, { key, ref, onSnapshot, onError = null }) => {
      if (state.observing[key]) {
        commit("observe", { key });
        return state.observing[key].subscribe;
      } else {
        let offSnapshot;
        commit("observe", {
          key,
          subscribe: new Promise((resolve, reject) => {
            offSnapshot = getSnapshot(
              ref,
              { includeMetadataChanges: true },
              snapshot => {
                if (!snapshot.metadata.hasPendingWrites) {
                  onSnapshot(snapshot);
                }
                resolve(snapshot);
              },
              error => {
                if (_.isFunction(onError)) onError(error);
                reject(error);
              }
            );
          }),
          unsubscribe: () => {
            offSnapshot();
            offSnapshot = null;
          }
        });
        // After the commit above should return Promise
        return state.observing[key].subscribe;
      }
    },

    unobserve: ({ commit, state }, key) => {
      return new Promise(resolve => {
        if (_.has(state.observing, key)) {
          const count = state.observing[key].count;
          if (count === 1) {
            state.observing[key].unsubscribe();
            resolve(true);
          } else {
            resolve(false);
          }
          commit("unobserve", { key });
        }
      });
    },

    unobserveAll: ({ commit, state }, { containing = null }) => {
      return new Promise(resolve => {
        let observing = state.observing;
        if (containing) {
          observing = _.pickBy(observing, (obj, key) => {
            return _.includes(key, containing);
          });
        }
        _.each(observing, (observation, key) => {
          observation.unsubscribe();
          commit("unobserve", { key, force: true });
        });
        resolve();
      });
    }
  },

  mutations: {
    cache: (state, { key, value }) => {
      Vue.set(state.cache, key, value);
    },
    discardCache: (state, key) => {
      Vue.delete(state.cache, key);
    },
    observe: (state, { key, subscribe, unsubscribe = null }) => {
      const observation = state.observing[key];
      const payload = _.merge(
        { count: 0, subscribe, unsubscribe },
        observation
      );
      payload.count++;
      Vue.set(state.observing, key, payload);
    },
    unobserve: (state, { key, force = false }) => {
      const observation = state.observing[key];
      const payload = _.merge({ count: 1 }, observation);
      payload.count--;
      if (!force && payload.count) {
        Vue.set(state.observing, key, payload);
      } else {
        Vue.delete(state.observing, key);
      }
    },
    resetState: state => {
      Vue.set(state, "cache", {});
    }
  },

  modules: {
    auth: require("./modules/auth").default,
    admin: require("./modules/admin").default,
    algolia: require("./modules/algolia").default,
    billing: require("./modules/billing/").default,
    ui: require("./modules/ui").default,
    user: require("./modules/user").default,
    sites: require("./modules/sites/").default,
    tasks: require("./modules/tasks").default,
    file: require("./modules/file").default,
    pagination: require("./modules/pagination").default,
    promotion: require("./modules/promotion").default,
    affiliate: require("./modules/affiliate").default,
    orders: require("./modules/orders").default,
    invoices: require("./modules/invoices").default,
    transactions: require("./modules/transactions").default,
    log: require("./modules/log").default,
    reseller: require("./modules/reseller").default,
    tokens: require("./modules/tokens").default,
    products: require("./modules/products").default,
    agents: require("./modules/agents").default,
    vault: require("./modules/vault").default
  }
});
