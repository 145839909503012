<template>
  <div
    :class="{ 'is-author': author }"
    class="chat-message"
    :style="takeMaxSpace ? 'flex: 1;' : null"
  >
    <div class="chat-message-body" :style="takeMaxSpace ? 'flex: 1;' : null">
      <p>
        <template v-for="(i, index) in urlifiedMessages">
          <a
            v-if="i.type === type.URL"
            :key="index"
            :href="i.value"
            @click.prevent="openLink(i.value)"
          >
            <u>{{ i.value }}</u>
          </a>
          <template v-else>{{ i.value }}</template>
        </template>
      </p>

      <span v-if="timestamp" class="chat-message-timestamp">{{
        $moment(datePosted).format("HH:mm")
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatMessage",
  props: {
    message: {
      required: true,
      type: String
    },
    timestamp: {
      required: false,
      validator: value => {
        return ["object", "null"].includes(typeof value);
      },
      default: null
    },
    author: {
      required: true,
      type: Boolean
    },
    takeMaxSpace: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    type() {
      return {
        TEXT: 0,
        URL: 1
      };
    },
    datePosted() {
      return this.timestamp ? this.timestamp.toDate() : new Date();
    },
    urlifiedMessages() {
      return this.urlify();
    }
  },
  methods: {
    openLink(url) {
      url = url.match(/^https?:/) ? url : "//" + url;
      window.open(url, "_blank");
    },
    urlify() {
      let data = [{ type: this.type.TEXT, value: this.message }];
      let current = this.message;
      let match = null;
      do {
        const urlRegex = /((?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$]))/im;
        match = urlRegex.exec(current);
        if (!this.$_.isEmpty(match)) {
          data.pop();
          const value = match[1] || "";
          const index = current.indexOf(value);
          data.push({
            type: this.type.TEXT,
            value: current.substr(0, index)
          });
          data.push({
            type: this.type.URL,
            value: value
          });
          data.push({
            type: this.type.TEXT,
            value: current.substr(index + value.length)
          });
          current = current.substr(index + value.length);
        }
      } while (match);
      return data;
    }
  }
};
</script>
