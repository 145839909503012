<template>
  <loading v-if="loading" centered />

  <div v-else id="agent-list">
    <div
      v-if="showDataFilter && showFilters"
      id="agent-list-filters"
      class="has-background-white has-padding-25 has-padding-top-50"
    >
      <data-filter
        :filters="filters"
        :active-filters="activeFilters"
        borderless
        @filtersChanged="filtersChanged"
      />
    </div>

    <div
      v-if="showSorting"
      id="agent-list-sorters"
      class="has-background-white has-padding-50"
    >
      <data-sorter
        :sorters="sorters"
        :active-sort="activeSort"
        @onSort="onSort"
      />
    </div>

    <div id="agent-list-container">
      <no-results
        v-if="table.data.length == 0"
        title="No results"
        message="There are no results to show."
        style="margin: auto;"
      />

      <div v-for="(item, index) in table.data" v-else :key="item._id">
        <slot :item="item" :index="index" />
      </div>

      <load-more
        v-if="!table.complete"
        :loading="table.loading"
        @loadMore="getData(true)"
      />
    </div>

    <b-loading :is-full-page="false" :active.sync="table.loading" />
  </div>
</template>

<script>
export default {
  name: "List",
  mixins: [require("@mixins/table-with-filtering-and-sorting").default],
  props: {
    reference: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    },
    filters: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    sorters: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    activeSort: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    excluded: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      loading: true,
      table: {
        limit: 20,
        sort: this.activeSort,
        excluded: this.excluded
      }
    };
  },
  computed: {
    showFilters() {
      return !!this.filters && !!this.filters.length;
    },
    showSorting() {
      return !!this.sorters && !!this.sorters.length;
    }
  },
  methods: {
    getFirestoreRef() {
      return this.buildFirestoreRef(this.reference);
    },
    afterDataLoad() {
      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";

#agent-list {
  font-size: 1rem;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;

  #agent-list-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-bottom: 5rem;
  }

  #agent-list-filters,
  #agent-list-sorters {
    min-height: 4em;
    border-bottom: 1px solid $grey-lighter;
    display: flex;
  }
}
.data-filter {
  margin-bottom: 0 !important;
  flex-direction: column;
  align-items: start !important;

  .add-filter-card {
    top: 0.25em !important;
  }
}
</style>
