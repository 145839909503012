<template>
  <div class="modal-card modal-datepicker">
    <header class="modal-card-head has-background-primary">
      <p class="modal-card-title has-text-white has-text-centered">
        Select date
      </p>
    </header>
    <section class="modal-card-body has-padding-x-0 has-padding-y-100 is-flex">
      <b-datepicker
        :value="value"
        v-bind="$attrs"
        v-on="listeners"
        @input="$emit('input', $event)"
      />
    </section>
  </div>
</template>

<script>
export default {
  name: "DatePickerModal",
  inheritAttrs: false,
  props: {
    value: {
      type: Date,
      required: false,
      default: ""
    }
  },
  computed: {
    listeners() {
      const { ...listeners } = this.$listeners;
      return listeners;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-datepicker {
  .datepicker {
    margin: auto;
    & /deep/ .dropdown-content {
      box-shadow: none;
    }
  }
}
</style>
