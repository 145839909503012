import store from "@store";

export default {
  path: "/admin",
  component: () => import("@skeleton/admin"),
  beforeEnter: (to, from, next) => {
    store.commit("ui/routing", true);
    next();
  },
  meta: {
    requiresAuth: true,
    agentOnly: true
  },
  children: [
    {
      path: "",
      name: "admin",
      redirect: () => {
        return store.getters["user/isOnlyAgent"]() ? "tasks/my" : "dashboard";
      },
      props: true,
      components: {
        default: () => import("@views/admin")
      },
      children: [
        {
          path: "dashboard",
          components: {
            content: () => import("@views/admin/dashboard")
          },
          meta: {
            title: "Dashboard",
            adminOnly: true,
            grid: {
              subMenu: false
            }
          }
        },
        {
          path: "sites",
          redirect: () => {
            return store.getters["user/isOnlyAgent"]()
              ? "sites/my"
              : "sites/all";
          }
        },
        {
          path: "sites/:siteFilter",
          components: {
            subMenu: () => import("@views/admin/sites")
          },
          beforeEnter: ({ params }, from, next) => {
            const validFilters = ["my", "all"];
            if (!validFilters.includes(params.siteFilter))
              return next({ path: `/admin/sites` });
            return next();
          },
          meta: {
            title: "Sites"
          }
        },
        {
          path: "sites/:siteFilter/:siteId",
          components: {
            subMenu: () => import("@views/admin/sites"),
            content: () => import("@views/admin/sites/site"),
            aside: () => import("@views/admin/sites/site/_aside")
          },
          beforeEnter: ({ params }, from, next) => {
            const validFilters = ["my", "all", "subscribed"];
            if (!validFilters.includes(params.siteFilter))
              return next({ path: `/sites/magic/${params.siteId}` });
            return next();
          },
          meta: {
            title: {
              param: "siteId",
              getter: "sites/site",
              format: site => `${site.fqdn}`
            }
          }
        },
        {
          path: "tasks",
          redirect: "tasks/my"
        },
        {
          path: "tasks/:taskFilter",
          components: {
            subMenu: () => import("@views/admin/tasks"),
            noContent: () => import("@views/admin/tasks/_noContent")
          },
          beforeEnter: ({ params }, from, next) => {
            if (params.taskFilter !== "my")
              if (store.getters["user/isOnlyAgent"]())
                return next({ path: `/admin/tasks/my` });
            return next();
          },
          meta: {
            title: "Tasks"
          }
        },
        {
          path: "tasks/:taskFilter/:taskId",
          components: {
            subMenu: () => import("@views/admin/tasks"),
            content: () => import("@views/admin/tasks/task"),
            aside: () => import("@views/admin/tasks/task/_aside")
          },
          beforeEnter: ({ params }, from, next) => {
            if (params.taskFilter !== "my")
              if (store.getters["user/isOnlyAgent"]())
                return next({ path: `/admin/tasks/my/${params.taskId}` });
            return next();
          },
          meta: {
            title: {
              param: "taskId",
              getter: "tasks/task",
              format: task => `Task ${task.reference} | ${task.fqdn}`
            }
          }
        },
        {
          path: "teams",
          redirect: "teams/maintenance"
        },
        {
          path: "teams/:teamFilter",
          components: {
            subMenu: () => import("@views/admin/teams"),
            noContent: () => import("@views/admin/tasks/_noContent")
          },
          meta: {
            title: "Teams"
          }
        },
        {
          path: "teams/:teamFilter/:taskId",
          components: {
            subMenu: () => import("@views/admin/teams"),
            content: () => import("@views/admin/tasks/task"),
            aside: () => import("@views/admin/tasks/task/_aside")
          },
          meta: {
            title: {
              param: "taskId",
              getter: "tasks/task",
              format: task => `Task ${task.reference} | ${task.fqdn}`
            }
          }
        },
        {
          path: "users",
          components: {
            subMenu: () => import("@views/admin/users")
          },
          meta: {
            title: "Users",
            adminOnly: true
          }
        },
        {
          path: "users/:userId",
          name: "adminUser",
          components: {
            subMenu: () => import("@views/admin/users"),
            content: () => import("@views/admin/users/user"),
            aside: () => import("@views/admin/users/user/_aside")
          },
          meta: {
            title: {
              param: "userId",
              getter: "user/user",
              format: user => `${user.email}`
            },
            omitSubMenu() {
              return !store.getters["user/isAdmin"]();
            }
          }
        },
        {
          path: "invoices",
          components: {
            subMenu: () => import("@views/admin/invoices")
          },
          meta: {
            title: "Invoices",
            adminOnly: true
          }
        },
        {
          path: "invoices/:invoiceId",
          components: {
            subMenu: () => import("@views/admin/invoices"),
            content: () => import("@views/admin/invoices/invoice"),
            aside: () => import("@views/admin/invoices/invoice/_aside")
          },
          meta: {
            title: {
              param: "invoiceId",
              getter: "invoices/invoice",
              format: invoice => `Invoice #${invoice.invoiceNum}`
            },
            adminOnly: true
          }
        },
        {
          path: "orders",
          components: {
            subMenu: () => import("@views/admin/orders")
          },
          meta: {
            title: "Orders",
            adminOnly: true
          }
        },
        {
          path: "orders/:orderId",
          components: {
            subMenu: () => import("@views/admin/orders"),
            content: () => import("@views/admin/orders/order"),
            aside: () => import("@views/admin/orders/order/_aside")
          },
          meta: {
            title: {
              param: "orderId",
              getter: "orders/order",
              format: order => `Order #${order.orderNum}`
            },
            adminOnly: true
          }
        },
        {
          path: "contracts",
          components: {
            subMenu: () => import("@views/admin/contracts")
          },
          meta: {
            title: "Contracts",
            adminOnly: true
          }
        },
        {
          path: "contracts/:contractId",
          name: "adminContract",
          components: {
            subMenu: () => import("@views/admin/contracts"),
            content: () => import("@views/admin/contracts/contract"),
            aside: () => import("@views/admin/contracts/contract/_aside")
          },
          meta: {
            title: {
              param: "contractId",
              getter: "billing/contract",
              format: contract => `${contract.site.fqdn}`
            },
            adminOnly: true
          }
        },
        {
          path: "reports",
          components: {
            content: () => import("@views/admin/reports")
          },
          meta: {
            title: "Reports",
            grid: {
              subMenu: false
            }
          }
        },
        {
          path: "logs",
          components: {
            content: () => import("@views/admin/logs")
          },
          meta: {
            title: "Logs",
            grid: {
              subMenu: false
            }
          }
        },
        {
          path: "vault",
          components: {
            content: () => import("@views/admin/vault")
          },
          meta: {
            title: "FixedVault™",
            grid: {
              subMenu: false
            }
          }
        },
        {
          path: "agents",
          components: {
            subMenu: () => import("@views/admin/agents")
          },
          meta: {
            title: "Agents",
            adminOnly: true
          }
        },
        {
          path: "agents/:userId",
          components: {
            subMenu: () => import("@views/admin/agents"),
            content: () => import("@views/admin/agents/agent")
          },
          meta: {
            title: {
              param: "userId",
              getter: "user/user",
              format: user => `${user.email}`
            }
          }
        },
        {
          path: "settings/products",
          components: {
            content: () => import("@views/admin/products")
          },
          meta: {
            title: "Products"
          }
        },
        {
          path: "settings/promotions",
          components: {
            content: () => import("@views/admin/promotions")
          },
          meta: {
            title: "Promotions",
            grid: {
              subMenu: false
            }
          }
        },
        {
          path: "settings/products/:productId",
          components: {
            content: () => import("@views/admin/products/product")
          },
          meta: {
            title: "Product"
          }
        },
        {
          path: "settings/promotions/:promotionId",
          components: {
            content: () => import("@views/admin/promotions/promotion")
          },
          meta: {
            title: "Promotion",
            grid: {
              subMenu: false
            }
          }
        },
        {
          path: "*",
          component: () => import("@views/error"),
          props: { code: 404 }
        }
      ]
    }
  ]
};
