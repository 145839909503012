import Vue from "vue";
import { $firestore } from "@/firebase";
import _ from "lodash";
import { doc } from "firebase/firestore";

const defaultState = () => {
  return {
    observed: {}
  };
};

const state = defaultState();

const getters = {
  invoice: state => invoiceId => {
    return state.observed[invoiceId];
  }
};

const actions = {
  observeInvoice: ({ dispatch, commit }, { invoiceId }) => {
    return new Promise((resolve, reject) => {
      const invoiceRef = doc($firestore, "invoices", invoiceId);
      dispatch(
        "observe",
        {
          key: `invoices/${invoiceId}`,
          ref: invoiceRef,
          onSnapshot: invoice => {
            if (!invoice.exists()) {
              dispatch("unobserveInvoice", { invoiceId });
              return reject();
            }
            commit("setInvoice", {
              invoiceId,
              invoiceData: invoice.data() || {}
            });
            return resolve();
          },
          onError: error => {
            dispatch("unobserveInvoice", { invoiceId });
            return reject(error);
          }
        },
        { root: true }
      )
        .then(resolve)
        .catch(reject);
    });
  },
  unobserveInvoice: ({ commit, dispatch }, { invoiceId }) => {
    return dispatch(`unobserve`, `invoices/${invoiceId}`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit(`unsetInvoice`, { invoiceId });
      }
    });
  },
  reset: async ({ dispatch, commit }) => {
    try {
      await dispatch(
        "unobserveAll",
        { containing: "invoices" },
        { root: true }
      );
      commit("resetState");
    } catch (error) {
      console.error(error);
    }
  }
};

const mutations = {
  setInvoice: (state, { invoiceId, invoiceData }) => {
    invoiceData = _.isEmpty(invoiceData)
      ? invoiceData
      : _.merge({}, invoiceData, { _id: invoiceId });
    Vue.set(state.observed, invoiceId, invoiceData);
  },
  unsetInvoice: (state, { invoiceId }) => {
    if (state.observed[invoiceId]) {
      Vue.delete(state.observed, invoiceId);
    }
  },
  resetState: state => {
    Object.assign(state, defaultState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
