var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vue-pincode-input"},_vm._l((_vm.chars),function(char,index){return _c('div',{key:char.key,staticClass:"vue-pincode",class:{
      split: _vm.splitAtPosition != -1 && index === _vm.splitAtPosition,
      focused: _vm.focusedIndex === index
    },on:{"click":function($event){return _vm.setFocusedCharIndex(index)}}},[_c(_vm.disabled ? 'span' : 'input',{ref:"pins",refInFor:true,tag:"component",staticClass:"pin-char-input",attrs:{"value":_vm.$_.get(char.value, `[0]`, _vm.emptyChar),"type":"text","pattern":"\\d*","inputmode":"numeric"},on:{"input":function($event){return _vm.onInput({ event: $event, index, value: $event.data })},"focus":function($event){return _vm.setFocusedCharIndex(index)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.onDelete(index, $event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return _vm.setFocusedCharIndex(index - 1)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;return _vm.setFocusedCharIndex(index + 1)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;_vm.onInput({
          index,
          next: false,
          value:
            char.value === _vm.emptyChar
              ? _vm.minChar
              : String.fromCharCode(char.value.charCodeAt(0) + 1)
        })},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;_vm.onInput({
          index,
          next: false,
          value:
            char.value === _vm.emptyChar
              ? _vm.maxChar
              : String.fromCharCode(char.value.charCodeAt(0) - 1)
        })}],"paste":function($event){$event.preventDefault();return _vm.onPaste.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.disabled ? _vm.$_.trim(char.value) : null)+" ")])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }