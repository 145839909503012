<template>
  <router-view :class="{ whitelabeled: isWhitelabeled }" @mounted="loaded" />
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      refreshApp: false,
      refreshModal: null
    };
  },
  computed: {
    appVersion() {
      return this.$store.getters["ui/appVersion"];
    },
    isWhitelabeled() {
      return this.$store.getters["ui/isWhitelabeled"]();
    },
    title() {
      if (typeof this.$route.meta.title !== "object") {
        return this.$route.meta.title || "";
      } else {
        const value = this.$store.getters[this.$route.meta.title.getter](
          this.$route.params[this.$route.meta.title.param]
        );
        return this.$_.isEmpty(value)
          ? "..."
          : this.$route.meta.title.format(value);
      }
    },
    user() {
      return this.$store.getters["user/user"]();
    },
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    reseller() {
      return this.$store.getters["reseller/reseller"](this.user.resellerId);
    }
  },
  watch: {
    "reseller.primaryColor": {
      handler(newVal) {
        if (this.user.isResellerUser)
          document.documentElement.style.setProperty(
            "--primary-color",
            `${newVal || "#1C2D3B"}`
          );
      },
      immediate: true
    },
    "user.requirePasswordChange": {
      handler(requirePasswordChange) {
        if (requirePasswordChange && this.user.isResellerUser)
          this.openChangePasswordModal();
      },
      immediate: true
    },
    "user.isDisabled"(newVal) {
      if (newVal) {
        this.$toast.open({
          message: "This account has been disabled",
          duration: 6000,
          type: "is-danger"
        });
        this.$store.dispatch("auth/logout");
      }
    },
    title() {
      document.title = (
        this.title + `${this.isWhitelabeled ? "" : " | fixed.net"}`
      ).replace(/^(\s*\|\s*)/, "");
    },
    appVersion(newVal, oldVal) {
      if (oldVal !== undefined) {
        this.refreshApp = true;
      }
    },
    refreshApp(val) {
      if (val === true) this.openRefreshModal();
    }
  },
  methods: {
    loaded() {
      let appLoading = document.getElementById("app-loading");
      if (appLoading) appLoading.parentNode.removeChild(appLoading);
    },
    openRefreshModal() {
      const vm = this;
      if (!this.refreshModal) {
        this.refreshModal = this.$modal.open({
          canCancel: [],
          component: () =>
            import(/* webpackChunkName: "core" */ "@skeleton/refreshAppModal"),
          width: 420,
          events: {
            close() {
              vm.refreshModal = null;
              vm.refreshApp = false;
            }
          }
        });
      }
    },
    openChangePasswordModal() {
      const modal = this.$modal.open({
        parent: this,
        canCancel: [],
        component: () => import("@shared/reseller/_changePasswordModal"),
        width: 400,
        props: {
          userId: this.userId
        },
        events: {
          changed() {
            modal.close();
          }
        }
      });
    }
  }
};
</script>

<style src="./assets/sass/bulma/custom-build.scss" lang="scss"></style>
