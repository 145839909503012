import { Snackbar } from "@src/components/snackbar";
import { ModalProgrammatic } from "buefy/dist/components/modal";
import Bus from "@src/services/bus";
import _ from "lodash";
import { $firestore } from "@/firebase";
import { doc } from "firebase/firestore";

const state = {
  tenant: {},
  routing: false,
  menu: false,
  profileModal: false,
  adminMenu: false,
  adminAside: false,
  window: {
    width: 0,
    height: 0
  }
};

const getters = {
  isMobile: state => () => {
    return state.window.width <= 768;
  },
  isTablet: state => () => {
    return state.window.width >= 769 && state.window.width < 1024;
  },
  isTouch: state => {
    return state.window.width < 1024;
  },
  isDesktop: state => () => {
    return state.window.width >= 1024 && state.window.width < 1216;
  },
  isWidescreen: state => () => {
    return state.window.width >= 1216 && state.window.width < 1472;
  },
  isFullHd: state => () => {
    return state.window.width >= 1472;
  },
  appVersion: state => {
    return _.get(state, "tenant.dateDeployed.seconds", null);
  },
  isWhitelabeled: state => () => {
    return _.get(
      state,
      "tenant.isWhitelabeled",
      !window.location.hostname.match(/^(.+)?(\.fixed\.net|localhost)$/i)
    );
  }
};

const actions = {
  observeTenant: ({ dispatch, commit, state }) => {
    return new Promise((resolve, reject) => {
      const tenantKey = `tenants/${window.location.hostname}`;
      const tenantRef = doc($firestore, "tenants", window.location.hostname);
      return dispatch(
        "observe",
        {
          key: tenantKey,
          ref: tenantRef,
          onSnapshot: tenant => {
            if (tenant.exists() === false) return reject("Tenant not found.");
            commit("setTenant", tenant.data());
            if (_.get(state.tenant, "loadChat", false)) dispatch("chat/load");
            return resolve();
          },
          onError: error => {
            console.error(error.message); // Hit lower level issue (eg. DB connection timeout)
            return resolve(); // Continue to boot app anyway
          }
        },
        { root: true }
      );
    });
  },
  showInvalidTenantModal() {
    ModalProgrammatic.open({
      component: () => import("@shared/tenant/invalidTenantModal"),
      canCancel: false,
      width: 480
    });
  },
  showBrowserNotification: (
    { dispatch },
    { message, onclick = null, duration = 5000, indefinite = false }
  ) => {
    if (!("Notification" in window))
      return dispatch("showLocalNotification", { message, onclick });
    if (Notification.permission !== "granted") Notification.requestPermission();
    if (Notification.permission !== "granted") {
      dispatch("showLocalNotification", {
        message,
        onclick,
        duration,
        indefinite
      });
    } else {
      const notification = new Notification("Fixed", {
        body: message,
        icon: "/assets/images/favicons/32x32.png",
        vibrate: [200, 100, 200],
        requireInteraction: true
      });
      notification.onclick = () => {
        if (_.isFunction(onclick)) onclick();
      };

      setTimeout(() => {
        notification.close();
      }, duration);
    }
  },
  showLocalNotification: (
    // eslint-disable-next-line no-empty-pattern
    {},
    { message, onclick, duration = 5000 }
  ) => {
    return new Promise(resolve => {
      const snackbar = Snackbar.open({
        duration,
        message,
        type: "is-primary",
        position: "is-bottom",
        //actionText: "View",
        queue: false,
        actions: [
          {
            text: "View",
            click: () => {
              if (_.isFunction(onclick)) onclick();
              snackbar.close();
              return resolve();
            }
          },
          {
            text: "Dismiss",
            cssClass: {
              "is-warning": true
            },
            click: () => {
              snackbar.close();
              return resolve();
            }
          }
        ]
      });
    });
  },
  openNotifications: ({ commit, state }) => {
    if (state.window.width <= 768) {
      commit("profileModal", true);
    } else {
      Bus.$emit("notifications/toggle", true);
    }
  },
  parseLocalPrefs: () => {
    try {
      return JSON.parse(localStorage.getItem("fxd_prefs")) || {};
    } catch {
      return {};
    }
  },
  getLocalPref: async ({ dispatch }, { key, fallback = null }) => {
    if (!_.isString(key)) throw Error("Invalid key, string expected");
    const prefs = await dispatch("parseLocalPrefs");
    return _.get(prefs, key, fallback);
  },
  setLocalPref: async ({ dispatch }, payload) => {
    if (!_.isObject(payload)) throw Error("Invalid payload, JSON expected");
    const prefs = await dispatch("parseLocalPrefs");
    _.merge(prefs, payload);
    localStorage.setItem("fxd_prefs", JSON.stringify(prefs));
  }
};

const mutations = {
  setTenant: (state, payload) => {
    state.tenant = payload;
  },
  routing: (state, payload) => {
    state.routing = payload;
  },
  menu: (state, payload) => {
    state.menu = typeof payload === "boolean" ? payload : !state.menu;
  },
  profileModal: (state, payload) => {
    state.profileModal =
      typeof payload === "boolean" ? payload : !state.profileModal;
  },
  adminMenu: (state, payload) => {
    state.adminMenu = typeof payload === "boolean" ? payload : !state.adminMenu;
  },
  adminAside: (state, payload) => {
    state.adminAside =
      typeof payload === "boolean" ? payload : !state.adminAside;
  },
  window: (state, { width, height }) => {
    state.window = { width: width, height: height };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    chat: require("./chat").default
  }
};
