<template>
  <div :style="{ margin: 'auto', maxWidth: maxWidth }">
    <picture :title="title" :class="classes" class="screenshot">
      <p
        v-if="refreshable"
        class="recapture has-cursor-pointer is-flex has-items-aligned-center"
        @click="$emit('refresh')"
      >
        <span>Take new screenshot</span>
      </p>

      <source :srcset="screenshotUrl" />

      <span
        :class="`screenshot-fallback ${loading ? 'screenshot-loading' : ''}`"
      />

      <img
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
      />
    </picture>
  </div>
</template>

<script>
import { ref as storageRef, getDownloadURL } from "firebase/storage";
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: ""
    },
    filename: {
      required: true,
      validator: prop => typeof prop === "string" || prop === undefined
    },
    classes: {
      type: String,
      required: false,
      default: ""
    },
    maxWidth: {
      type: String,
      required: false,
      default: "none"
    },
    refreshable: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      loading: true
    };
  },

  computed: {
    screenshotUrl() {
      return this.$store.getters.cached({ key: this.filename });
    }
  },

  watch: {
    filename() {
      if (this.filename && !this.screenshotUrl) return this.getScreenshotUrl();
      if (!this.filename) return (this.loading = false);
    }
  },

  created() {
    if (this.filename && !this.screenshotUrl) return this.getScreenshotUrl();
    if (!this.filename) return (this.loading = false);
  },

  methods: {
    reset() {
      this.getScreenshotUrl();
    },
    getScreenshotUrl() {
      const screenshotRef = storageRef(this.$storage(), this.filename);
      this.loading = true;
      getDownloadURL(screenshotRef)
        .then(downloadUrl => {
          this.$store.commit("cache", {
            key: this.filename,
            value: downloadUrl
          });
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";

picture.screenshot {
  display: block;
  position: relative;
  border-radius: 0.3em;
  overflow: hidden;
  width: 100%;
  padding-top: 62.5%; /* 8:5 aspect Ratio */

  .recapture {
    position: absolute;
    background-color: rgba($black, 0.75);
    color: $light;
    bottom: -3em;
    height: 3em;
    width: 100%;
    text-decoration: underline;
    z-index: 2;
    transition: bottom 0.25s ease-in-out;
    align-items: center;
    justify-content: center;
  }

  &:hover .recapture {
    bottom: 0em;
    transition: bottom 0.25s ease-in-out;
  }

  & > img {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}

span.screenshot-fallback {
  position: absolute;
  width: 80px;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
  background: url(/assets/images/interface/camera.svg) center no-repeat;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

span.screenshot-loading:after {
  background: url(/assets/images/interface/camera-spinner.svg) center no-repeat;
  animation: loading 1s linear infinite;
}
</style>
