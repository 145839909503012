<template>
  <b-field label="Color">
    <b-field>
      <button
        v-if="resetable"
        class="button is-medium"
        value="Reset color"
        placeholder="reset"
        @click.prevent="resetColor"
      >
        <b-icon icon="undo" />
      </button>

      <b-input
        v-model="colorCopy"
        :disabled="disabled"
        type="color"
        expanded
        @input="onChange"
      />
    </b-field>
  </b-field>
</template>

<script>
export default {
  name: "ColorPicker",
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    defaultColor: {
      type: String,
      default: "#00C6CE"
    },
    resetable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      colorCopy: this.value || this.defaultColor
    };
  },
  watch: {
    value(newVal) {
      this.colorCopy = newVal || this.defaultColor;
    }
  },
  methods: {
    onChange() {
      if (this.colorCopy !== this.defaultColor) {
        this.$emit("input", this.colorCopy);
      }
    },
    resetColor() {
      this.colorCopy = this.defaultColor;
      this.$emit("input", null);
    }
  }
};
</script>
