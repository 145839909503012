export default {
  install: Vue => {
    Vue.directive("focus", {
      inserted(el) {
        if (el.tagName.toLowerCase() !== "input") {
          el = el.querySelector("input");
        }
        if (el.getAttribute("tabindex")) {
          el.setAttribute("tabindex", -1);
        }
        el.focus();
      }
    });

    Vue.directive("visible", (el, binding) => {
      let fadeIn = 0;
      let fadeOut = 0;
      if (binding.arg.length) {
        let argArray = binding.arg.split(",");
        fadeIn = parseInt(argArray[0]);
        if (argArray.length === 2) {
          fadeOut = parseInt(argArray[1]);
        }
      }
      if (binding.value) {
        el.style.transition = "opacity " + fadeOut + "ms ease";
        el.style.opacity = "1";
        el.style.visibility = "visible";
      } else {
        el.style.transition =
          "visibility 0s " + fadeIn + "ms, opacity " + fadeIn + "ms ease";
        el.style.opacity = "0";
        el.style.visibility = "hidden";
      }
    });

    Vue.directive("click-outside", {
      bind: function(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
          // here I check that click was outside the el and his childrens
          if (!(el === event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: function(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      }
    });
  }
};
