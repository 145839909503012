<template>
  <component :is="tag">
    <slot />
    <template v-for="(i, index) in urlifiedMessages">
      <a v-if="i.type === type.URL" :key="index" :href="i.href" target="_blank">
        <u>{{ i.value }}</u>
      </a>
      <template v-else>{{ i.value }}</template>
    </template>
  </component>
</template>

<script>
const urlRegex = /((?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$]))/im;

export default {
  name: "Urlify",
  props: {
    text: {
      required: true,
      type: String
    },
    tag: {
      type: String,
      default: "p"
    }
  },
  data() {
    return {
      type: {
        TEXT: 0,
        URL: 1
      }
    };
  },
  computed: {
    urlifiedMessages() {
      return this.urlify();
    }
  },
  methods: {
    getLink(url) {
      return url.match(/^https?:/) ? url : "//" + url;
    },
    urlify() {
      let data = [{ type: this.type.TEXT, value: this.text }];
      let current = this.text;
      let match = null;
      do {
        match = urlRegex.exec(current);
        if (!this.$_.isEmpty(match)) {
          data.pop();
          const value = match[1] || "";
          const index = current.indexOf(value);
          data.push({
            type: this.type.TEXT,
            value: current.substr(0, index)
          });
          data.push({
            type: this.type.URL,
            value: value,
            href: this.getLink(value)
          });
          data.push({
            type: this.type.TEXT,
            value: current.substr(index + value.length)
          });
          current = current.substr(index + value.length);
        }
      } while (match);
      return data;
    }
  }
};
</script>
