import _ from "lodash";

export function getFileWithNewName(file, newName) {
  const blob = file.slice(0, -1, file.type);
  const newFile = new File([blob], newName, { type: file.type });
  return newFile;
}

export function isImage(contentType) {
  return _.startsWith(contentType, "image");
}

export function humanFileSize(size) {
  if (size === 0) return "0 kB";

  var i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["bytes", "kB", "MB", "GB", "TB"][i]
  );
}

export function downloadBlob(blob, filename) {
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function downloadLink(url, filename) {
  var link = document.createElement("a");
  if (link.download !== undefined) {
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
