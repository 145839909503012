<script>
import { Line, mixins } from "vue-chartjs";

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    options: {
      type: Object,

      default: () => {
        return {
          responsive: true,

          maintainAspectRatio: false
        };
      }
    },

    height: {
      type: Number,

      default: 320
    }
  },
  watch: {
    chartData() {
      this.$data._chart.update();
    },
    options() {
      this.renderChart(this.chartData, this.options);
      this.fixWidth();
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
    this.fixWidth();
  },
  methods: {
    fixWidth() {
      if (!this.$_.get(this.options, "maintainAspectRatio", true)) {
        const el = this.$el.querySelector("canvas");
        el.style.width = "100%";
      }
    }
  }
};
</script>
