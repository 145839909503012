import App from /* webpackChunkName: "core" */ "./App.vue";

// External

import Vue from "vue";
import Buefy from "buefy";
import VueChartJs from "vue-chartjs";
import VueClipboard from "vue-clipboard2";
import VueTextareaAutosize from "vue-textarea-autosize";

// Internal

import router from "@router";
import store from "@store";
import components from "@components";
import utils from "./services/utils";
import directives from "./directives";

// VueGtm – https://github.com/mib200/vue-gtm
import VueGtm from "vue-gtm";

// MomentJS – https://www.npmjs.com/package/moment
import moment from "moment";

// Lodash – https://www.npmjs.com/package/lodash
import _ from "lodash";

// Validator - https://www.npmjs.com/package/validator
import validator from "validator";

// Event Bus
import Bus from "@src/services/bus";

import { $auth, $firestore } from "@/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

Vue.config.productionTip = false;

Vue.use(Buefy, {
  defaultIconPack: "fas",
  defaultNoticeQueue: false,
  defaultToastDuration: 2000,
  defaultToastPosition: "is-bottom",
  defaultModalCanCancel: ["escape", "outside"]
});
Vue.use(VueTextareaAutosize);
Vue.use(VueChartJs);
Vue.use(VueClipboard);

Object.defineProperty(Vue.prototype, "$projectId", {
  value: process.env.VUE_APP_FIREBASE_PROJECT_ID
});

Vue.use(components);
Vue.use(utils);
Vue.use(directives);
Vue.use(VueGtm, {
  id: "GTM-K5ZFW2B",
  enabled: true,
  debug: false,
  vueRouter: router
});
Object.defineProperty(Vue.prototype, "$moment", { value: moment });
Object.defineProperty(Vue.prototype, "$_", { value: _ });
Object.defineProperty(Vue.prototype, "$v", { value: validator });
Object.defineProperty(Vue.prototype, "$bus", { value: Bus });

store
  .dispatch("ui/observeTenant")
  .then(() => {
    onAuthStateChanged($auth, async user => {
      if (user) {
        user.getIdTokenResult().then(token => {
          store.dispatch("auth/init", {
            currentUser: user,
            claims: token.claims
          });
          store
            .dispatch("user/observeUser", {
              userId: user.uid,
              checkExistance: false
            })
            .then(userDoc => {
              if (token.claims.isResellerUser) {
                store
                  .dispatch("reseller/observeReseller", {
                    resellerId: userDoc.data().resellerId
                  })
                  .finally(() => {
                    if (document.getElementById("app")) initializeApp();
                  });
              }

              if (userDoc.exists() && userDoc.data().isReseller) {
                store.dispatch("reseller/observeReseller", {
                  resellerId: userDoc.id
                });
              }

              store.dispatch("sites/observeUserSites", { userId: user.uid });
              store.dispatch("user/observeNotifications", {
                userId: user.uid
              });
              store.dispatch("user/observeAlerts", { userId: user.uid });
            })
            .catch(error => {
              console.error(error);
            })
            .finally(() => {
              if (!token.claims.isResellerUser)
                if (document.getElementById("app")) initializeApp();
            });
        });
      } else {
        if (document.getElementById("app")) initializeApp();
      }
    });
  })
  .catch(message => {
    console.error(message);
    store.dispatch("ui/showInvalidTenantModal");
  });

const initializeApp = () => {
  window.$rootVue = new Vue({
    router,
    el: "#app",
    store,
    computed: {
      user() {
        return this.$store.getters["user/user"]();
      },
      userIsAuthenticated() {
        return this.$store.getters["auth/isAuthenticated"]();
      }
    },
    watch: {
      userIsAuthenticated() {
        if (!this.userIsAuthenticated) {
          return router.push({ name: "login" });
        }
      }
    },
    mounted() {
      window.onerror = (msg, url, lineNo, columnNo, error) => {
        try {
          return addDoc(collection($firestore, "errors"), {
            dateCreated: serverTimestamp(),
            msg,
            url,
            lineNo,
            columnNo,
            stack: _.get(error, "stack"),
            location: _.get(location, "href"),
            useragent: _.get(window, "navigator.userAgent"),
            userId: this.$store.getters["auth/userId"]()
          });
        } catch (error) {
          console.error(error.message);
        }
      };
    },
    created() {
      const vm = this;
      vm.windowSize();
      window.addEventListener("resize", this.$_.debounce(vm.windowSize, 250));
      this.$bus.$on("scrollToTop", () => {
        const contentPane = document.getElementById("content-pane");
        if (contentPane) contentPane.scrollTop = 0;
      });
      this.$bus.$on("copyToClipboard", value => this.copyToClipboard(value));
    },
    methods: {
      windowSize() {
        var width = Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        );
        var height = Math.max(
          document.documentElement.clientHeight,
          window.innerHeight || 0
        );
        store.commit("ui/window", { width: width, height: height });
      },
      copyToClipboard(value) {
        this.$copyText(value).then(
          () => {
            this.$toast.open({
              message: "Copied to clipboard",
              position: "is-bottom",
              queue: false
            });
          },
          () => {
            this.$toast.open({
              message: "Error copying to clipboard",
              position: "is-bottom",
              type: "is-danger",
              queue: false
            });
          }
        );
      }
    },
    render: h => h(App)
  });
};
