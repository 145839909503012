import Vue from "vue";
import { $firestore, $functions } from "@/firebase";
import _ from "lodash";
import { ConfirmProgrammatic } from "@components/confirm";
import {
  doc,
  collection,
  where,
  query,
  getDoc,
  updateDoc,
  serverTimestamp
} from "firebase/firestore";

const getters = {
  site: state => siteId => {
    return state.observed[siteId] || null;
  },
  "site/userRole": (state, getters, rootState, rootGetters) => siteId => {
    const site = state.observed[siteId];
    if (!site) return;
    if (site.owners && site.owners.includes(rootGetters["auth/userId"]()))
      return "owner";
    if (site.users && site.users.includes(rootGetters["auth/userId"]()))
      return "delegate";
    if (rootGetters["user/isAdmin"]()) return "admin";
    if (rootGetters["user/isAgent"]()) return "agent";
    return "unknown";
  },
  "site/hasTaskCredits": (state, getters) => siteId => {
    const site = getters["site"](siteId);
    if (!site) return false;
    const quotaDefault = { planTaskCredits: 0, paidTaskCredits: 0 };
    const siteQuota = _.merge({}, quotaDefault, site.quota);
    return siteQuota.paidTaskCredits + siteQuota.planTaskCredits;
  }
};

const actions = {
  observeUserSites: ({ commit, dispatch }, { userId }) => {
    return new Promise((resolve, reject) => {
      let sitesRef = query(
        collection($firestore, "sites"),
        where(`users`, `array-contains`, userId)
      );

      dispatch(
        "observe",
        {
          key: `users/${userId}/sites`,
          ref: sitesRef,
          onSnapshot: snapshot => {
            commit("observingUserSites", true);
            // React to server changes
            if (!snapshot.metadata.hasPendingWrites) {
              _.each(snapshot.docChanges(), change => {
                const site = change.doc;
                if (change.type === "removed") {
                  commit("unsetSite", {
                    siteId: site.id
                  });
                } else {
                  commit("setSite", {
                    siteId: site.id,
                    siteData: site.data()
                  });
                }
              });
            }
            resolve();
          },
          onError: error => {
            reject(error);
          }
        },
        { root: true }
      );
    });
  },
  observeSite: ({ commit, dispatch }, { siteId }) => {
    return new Promise((resolve, reject) => {
      const siteRef = doc($firestore, "sites", siteId);
      dispatch(
        "observe",
        {
          key: `sites/${siteId}`,
          ref: siteRef,
          onSnapshot: site => {
            if (site.metadata.fromCache) return resolve();
            if (!site.exists()) {
              dispatch("unobserveSite", { siteId });
              return reject();
            }
            commit("setSite", { siteId, siteData: site.data() });
            resolve();
          }
        },
        { root: true }
      )
        .then(resolve)
        .catch(error => {
          dispatch("unobserveSite", { siteId });
          reject(error);
        });
    });
  },
  unobserveSite: ({ commit, dispatch, getters }, { siteId }) => {
    return dispatch(`unobserve`, `sites/${siteId}`, { root: true }).then(
      remove => {
        if (remove) {
          const siteRole = getters["site/userRole"](siteId);
          if (["admin", "agent", "unknown"].includes(siteRole)) {
            commit("unsetSite", { siteId: siteId });
          }
        }
      }
    );
  },
  // eslint-disable-next-line no-empty-pattern
  setHostingInfo: ({}, { siteId }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-site-setHostingInfo")({ siteId })
        .then(result => resolve(result.data))
        .catch(error => reject(error.message));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  resetcPanelPassword: ({}, { siteId, hostingId }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-site-resetcPanelPassword")({ siteId, hostingId })
        .then(result => resolve(result.data))
        .catch(error => reject(error.message));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  callExternalAPI: ({}, { url }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-site-callExternalAPI")({
          url
        })
        .then(result => resolve(result.data))
        .catch(error => reject(error.message));
    });
  },
  getSite: (context, { siteId }) => {
    return new Promise((resolve, reject) => {
      const siteRef = doc($firestore, "sites", siteId);
      getDoc(siteRef)
        .then(site => {
          if (!site.exists()) return reject();
          resolve(site);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addSite: (context, { fqdn, url, software, userId }) => {
    return new Promise((resolve, reject) => {
      const createSite = $functions().httpsCallable("onCall-site-add");
      createSite({ fqdn, url, software, userId })
        .then(result => {
          return resolve(result.data);
        })
        .catch(error => reject(error));
    });
  },
  updateSite: (context, { siteId, payload = {} }) => {
    return updateDoc(
      doc($firestore, "sites", siteId),
      _.merge({ dateUpdated: serverTimestamp() }, payload)
    );
  },
  deleteSite: ({ state }, { siteId }) => {
    return new Promise((resolve, reject) => {
      const confirm = ConfirmProgrammatic.open({
        props: {
          autoClose: false,
          action: `Delete site`,
          message: `Are you sure you want to delete ${state.observed[siteId].fqdn}? This will close all open tasks, cancel any linked subscriptions (if applicable) and
          deny access for all site users. After 7 days, all data relating to this site will be permanently erased.`
        },
        canCancel: [],
        events: {
          confirmed: () => {
            $functions()
              .httpsCallable("onCall-site-delete")({ siteId })
              .then(result => resolve(result.data))
              .catch(error => reject(error))
              .finally(() => confirm.close());
          }
        }
      });
    });
  },
  restoreSite: (context, { siteId }) => {
    return new Promise((resolve, reject) => {
      $functions()
        .httpsCallable("onCall-site-restore")({ siteId })
        .then(result => resolve(result.data))
        .catch(error => reject(error));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  loginToWordpress: ({}, { siteId }) => {
    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-site-loginToWordpress")({ siteId })
        .then(result => resolve(result.data))
        .catch(error => reject(error.message));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  updateWordpressPlugin: async ({ getters, dispatch }, { siteId, slug }) => {
    // Mark plugin as processing
    const site = getters["site"](siteId);
    const plugins = _.get(site, `wordpress.plugins`, {});
    const plugin = plugins[slug];
    if (plugin) {
      Vue.set(plugin, "isProcessing", true);
      await dispatch(
        "sites/updateSite",
        {
          siteId,
          payload: {
            "wordpress.plugins": plugins
          }
        },
        { root: true }
      );
    }

    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-site-updateWordpressPlugin")({ siteId, slug })
        .then(result => resolve(result.data))
        .catch(error => reject(error.message));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  updateWordpressTheme: async ({ getters, dispatch }, { siteId, slug }) => {
    // Mark theme as processing
    const site = getters["site"](siteId);
    const themes = _.get(site, `wordpress.themes`, {});
    const theme = themes[slug];
    if (theme) {
      Vue.set(theme, "isProcessing", true);
      await dispatch(
        "sites/updateSite",
        {
          siteId,
          payload: {
            "wordpress.themes": themes
          }
        },
        { root: true }
      );
    }

    return new Promise((resolve, reject) => {
      return $functions()
        .httpsCallable("onCall-site-updateWordpressTheme")({ siteId, slug })
        .then(result => resolve(result.data))
        .catch(error => reject(error.message));
    });
  }
};

const mutations = {
  observingUserSites: (state, payload) => {
    Vue.set(state, "observingUserSites", payload);
  },
  setSite: (state, { siteId, siteData }) => {
    Vue.set(
      state.observed,
      siteId,
      _.merge(
        {
          _id: siteId
        },
        siteData
      )
    );
  },
  unsetSite: (state, { siteId }) => {
    if (state.observed[siteId]) {
      Vue.delete(state.observed, siteId);
    }
  }
};

export { getters, actions, mutations };
