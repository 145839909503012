<template>
  <div v-if="isAvailable" class="file-selector">
    <input
      id="file-input"
      ref="fileUpload"
      type="file"
      name="name"
      class="is-hidden"
      multiple
      @change="handleFileSelect"
    />

    <slot name="list" :files="files">
      <file-list-vertical
        v-if="type === 'vertical'"
        :files="files"
        @remove="removeFile"
      />
    </slot>
  </div>
</template>

<script>
import FileListVertical from "./FileListVertical";
import { humanFileSize, isImage } from "@src/services/fileutil";
import { getIcon, MIME_ICON } from "@src/services/mimeType";

export default {
  name: "FileSelector",
  components: {
    FileListVertical
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    maxFileSize: {
      type: Number,
      required: false,
      default: 16 * 1024 * 1024 // 16MB
    },

    maxFiles: {
      type: Number,
      required: false,
      default: 10
    },
    type: {
      type: String,
      required: false,
      default: "vertical"
    }
  },
  provide() {
    return {
      maxFileSize: this.maxFiles,
      humanFileSize: humanFileSize,
      isFileValid: this.isFileValid,
      thumbnail: this.thumbnail,
      isImage: isImage
    };
  },
  data() {
    return {
      files: this.value || []
    };
  },
  computed: {
    isAvailable() {
      // Check for the various File API support.
      return Boolean(window.File) && Boolean(window.FileReader);
    }
  },
  watch: {
    value(value) {
      if (!this.$_.isEqual(value, this.files)) {
        this.files = value;
      }
    }
  },
  methods: {
    isContentTypeAllowed(contentType) {
      if (this.$_.isEmpty(contentType)) return false;
      return (
        contentType.startsWith("image/") ||
        contentType.startsWith("text/") ||
        contentType.startsWith("application/vnd.ms") || // all office files
        contentType.startsWith(
          "application/vnd.openxmlformats-officedocument"
        ) || // all office files
        contentType === "application/pdf" ||
        contentType === "application/zip" ||
        contentType === "video/quicktime" ||
        contentType === "application/msword" //.doc .dot
      );
    },
    isFileValid(file) {
      if (file.size > this.maxFileSize)
        return {
          valid: false,
          message: `File size too big`
        };
      if (!this.isContentTypeAllowed(file.type))
        return {
          valid: false,
          message: `File type not supported`
        };

      return { valid: true, message: "" };
    },
    thumbnail(file) {
      if (this.$_.startsWith(file.file.type, "image")) {
        return file.base64 ? file.base64 : MIME_ICON["default"];
      }
      return getIcon(file.file.type);
    },
    openFileDialog() {
      if (this.maxFiles <= this.files.length) {
        return this.showError(
          `You can't add more than ${this.maxFiles} files at once`
        );
      }
      this.$refs.fileUpload.click();
    },
    removeFile({ index }) {
      this.files.splice(index, 1);
    },
    clear() {
      this.files = [];
      this.$emit("input", this.files);
    },
    showError(message) {
      this.$toast.open({
        message: message ? message : "Error uploading file",
        type: "is-danger"
      });
    },
    addFile(file) {
      if (this.files.length < this.maxFiles) {
        // Validate
        const validationResult = this.isFileValid(file);

        let attachment = {
          file: file,
          valid: validationResult,
          loading: isImage(file.type),
          base64: ""
        };

        this.files.push(attachment);
        this.$emit("input", this.files);

        var reader = new FileReader();
        reader.onload = (theAttachment => {
          return e => {
            theAttachment.base64 = e.target.result;
            theAttachment.loading = false;
          };
        })(attachment, file);

        // If it's image and valid file read it as base64
        if (attachment.valid.valid && isImage(file.type))
          reader.readAsDataURL(file);
      } else {
        this.showError(
          `You can't attach more than ${this.maxFiles} files at once`
        );
      }
    },
    handleFileSelect(evt) {
      var files = evt.target.files; // FileList object
      // files is a FileList of File objects. List some properties.
      for (var i = 0, f; (f = files[i]); i++) {
        this.addFile(f);
      }
    }
  }
};
</script>
