<script>
import { Bar, mixins } from "vue-chartjs";

export default {
  extends: Bar,

  mixins: [mixins.reactiveProp],

  props: {
    chartOptions: {
      type: Object,

      default: () => {
        return {
          responsive: true,

          maintainAspectRatio: false
        };
      }
    },

    height: {
      type: Number,

      default: 320
    }
  },

  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  }
};
</script>
