import Vue from "vue";
import _ from "lodash";
import { collection, where, query } from "firebase/firestore";
import { $firestore } from "@/firebase";

const getters = {
  siteRoles: state => siteId => {
    return state.roles.observed[siteId] || null;
  }
};

const actions = {
  observeRoles: ({ commit, dispatch }, { siteId }) => {
    const sitesRef = query(
      collection($firestore, "roles"),
      where(`siteId`, `==`, siteId)
    );
    return dispatch(
      "observe",
      {
        key: `sites/${siteId}/roles`,
        ref: sitesRef,
        onSnapshot: snapshot => {
          // React to server changes
          if (!snapshot.metadata.hasPendingWrites) {
            _.each(snapshot.docChanges(), change => {
              const role = change.doc;
              if (change.type === "removed") {
                commit("unsetRole", {
                  siteId,
                  roleId: role.id
                });
              } else {
                commit("setRoles", {
                  siteId,
                  roles: {
                    [role.id]: _.merge({ _id: role.id }, role.data())
                  }
                });
              }
            });
          }
        }
      },
      { root: true }
    );
  },
  unobserveRoles: ({ commit, dispatch }, { siteId }) => {
    return dispatch(`unobserve`, `sites/${siteId}/roles`, {
      root: true
    }).then(unsubscribed => {
      if (unsubscribed) {
        commit("unsetRoles", { siteId });
      }
    });
  }
};

const mutations = {
  setRoles: (state, { siteId, roles }) => {
    Vue.set(
      state.roles.observed,
      siteId,
      _.merge({}, state.roles.observed[siteId], roles)
    );
  },
  unsetRole: (state, { siteId, roleId }) => {
    if (state.roles.observed[siteId][roleId]) {
      Vue.delete(state.roles.observed[siteId], roleId);
    }
  },
  unsetRoles: (state, { siteId }) => {
    if (state.roles.observed[siteId]) {
      Vue.delete(state.roles.observed, siteId);
    }
  }
};

export { getters, actions, mutations };
